import { createSelector } from 'reselect';
import * as jwt from 'jsonwebtoken';
import { RootReducerState } from '../../store';
import { CognitoUser } from './schemas';

export const getLoginPending = (state: RootReducerState): boolean => state.auth.pending;
export const getUsername = (state: RootReducerState): string | undefined => state.auth.username;
export const getUserEmail = (state: RootReducerState): string | undefined => state.auth.email;
export const getUserFlag = (state: RootReducerState): string | undefined => state.auth.flags;
export const getUserId = (state: RootReducerState): number => state.auth.userId;
export const getToken = (state: RootReducerState): string => state.auth.token || '';
export const getLoginError = (state: RootReducerState): string | undefined => state.auth.errorMessage;
export const getRegistrationStep = (state: RootReducerState): number => state.auth.registration_step;
export const getEmailVerified = (state: RootReducerState): boolean => state.auth.emailVerified;
export const getAccessToken = (state: RootReducerState): string | undefined => state.auth.accessToken;
export const getAccessTokenExpiration = (state: RootReducerState): number | undefined => state.auth.tokenExpiration;
export const getQrCodeUrl = (state: RootReducerState): string | undefined => state.auth.qrCodeUrl;
export const getQrCodeSecret = (state: RootReducerState): string | undefined => state.auth.qrSecret;
export const getMFARequired = (state: RootReducerState): boolean => state.auth.MFARequired;
export const getCognitoUser = (state: RootReducerState): CognitoUser | undefined => state.auth.cognitoUser;
export const getUserFirstName = (state: RootReducerState): string => state.auth.firstName;
export const getUserLastName = (state: RootReducerState): string => state.auth.lastName;
export const getPasswordResetRequired = (state: RootReducerState): boolean => state.auth.passwordReset;

// TODO: put this in an environment variable
const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2VycAPfWzNNAYCSqNnRc
C0FDZ18m8xoLrN9ggJaDfb6+tEybNQXxxPVc6kDHbVJEo2X5hKBgEp6h46fL+G5r
vKsN50SL813zCovC6WSEWRKCVUvImHH6ImYPf9azRBe9TLUvWtpcLovKfYmf81q6
TWqla5/1mcajWIoLZ8aE4TpIl5JtSXmrVBfg09j8Bqplt+YmoK6T04Cbd6vbLmXI
1kXkJV/pjELZ2k5Xs2tKGT/688ekx+AQQRMCX5UfD5xudUy+hATriHN0g1+cC6Er
dtnlYVaRUfqGO3BMMz1Yc+p0nOaM3lwwgnqvkPr0fIbYocH+laCjiLJ91onBQm6t
EwIDAQAB
-----END PUBLIC KEY-----`;

const getDecodedToken = createSelector(
  [getToken],
  (token: string): { exp: number } | null => {
    try {
      const decoded = jwt.verify(token, PUBLIC_KEY);
      return decoded as { exp: number };
    } catch (err) {
      return null;
    }
  },
);

function isValidToken(state: RootReducerState) {
  const decoded = getDecodedToken(state);
  if (!decoded) {
    const accessToken = getAccessToken(state);
    if (accessToken) {
      return true;
    }
    return false;
  }

  const tokenExpDate = new Date(decoded.exp * 1000);
  const todayDate = new Date();
  return todayDate.getTime() < tokenExpDate.getTime();
}


export const getIsUserLoggedIn = (state: RootReducerState): boolean => isValidToken(state) && getUserId(state) > 0;

export const getIsUserLoggedOut = (state: RootReducerState): boolean => !getIsUserLoggedIn(state);

export const getIsUserLoggedInAsAdmin = (state: RootReducerState): boolean => isValidToken(state) && getUserId(state) > 0 && getUserFlag(state) === 'admin';

export const getIsUserRegistrationComplete = (state: RootReducerState): boolean => state.auth.registration_step !== 0;

export const getIsUserEmailVerified = (state: RootReducerState): boolean => getIsUserLoggedIn(state) && !state.auth.emailVerified;

export const getIsUserIsRegistered = (state: RootReducerState): boolean => getUserId(state) > 0 && !getEmailVerified(state);
