import * as types from './types';

const defaultState: types.HeightReducer = {
  heightOauthUrl: '',
  oauthState: '',
  heightOauthStatus: false,
  oauthStatusResponse: false,
};

export default function heightReducer(state = defaultState, action: types.HeightAction): types.HeightReducer {
  switch (action.type) {
    case types.GET_HEIGHT_OAUTH_URL_SUCCESS:
      return {
        ...state,
        heightOauthUrl: action.payload.url,
        oauthState: action.payload.state,
      };
    case types.GET_HEIGHT_OAUTH_FAILURE:
      return {
        ...state,
        heightOauthUrl: '',
      };
    case types.GET_HEIGHT_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: action.payload.oauthStatusResponse,
        heightOauthStatus: action.payload.oauthStatusResponse,
      };
    case types.RESET_HEIGHT_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: false,
      };
    case types.RESET_HEIGHT_OAUTH:
      return {
        ...defaultState,
        heightOauthStatus: state.heightOauthStatus,
      };
    case types.HEIGHT_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
