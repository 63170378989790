import { StatusMessage } from '../common/types';
import { Summary } from './schemas';

export type { Summary } from './schemas';

export const CREATE_SUMMARY_REQUEST = 'space/CREATE_SUMMARY_REQUEST';
export const CREATE_SUMMARY_SUCCESS = 'space/CREATE_SUMMARY_SUCCESS';
export const CREATE_SUMMARY_FAILURE = 'space/CREATE_SUMMARY_FAILURE';

export interface CreateSummaryRequest {
    body: string;
}

export interface SummaryReducerState {
    summaries: Summary[],
    summaryPending: boolean
}
export interface CreateSummaryRequestAction {
    type: typeof CREATE_SUMMARY_REQUEST;
}
export interface CreateSummaryFailureAction {
    type: typeof CREATE_SUMMARY_FAILURE;
    payload: StatusMessage;
}

export interface CreateSummarySuccessAction {
    type: typeof CREATE_SUMMARY_SUCCESS;
    payload: {
        summary: Summary;
    };
}

export type SummaryAction = CreateSummaryRequestAction | CreateSummaryFailureAction | CreateSummarySuccessAction;
