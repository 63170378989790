import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const OauthSuccessPage = () => {

  const location = useLocation();
  const navigate = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const app: string | null = queryParams.get('app');

  const validateAppName = async () => {
    if (app !== 'Slack' && app !== 'Notion' && app !== 'Jira' && app !== 'Height' && app !== 'GitHub') {
      navigate.push('/');
    }
  };

  useEffect(() => {
    validateAppName();
  }, []);

  return (
    <div className="preauth-page-container">
      <div className="steps-container" />
      <div className="signup-step-container">
        Success!
        {' '}
        {app}
        {' '}
        was connected.
        {' '}
        <div>Close this window to continue</div>
      </div>
    </div>
  );
};

export default OauthSuccessPage;
