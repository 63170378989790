/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import Editor from 'rich-markdown-editor';
import { UserOutlined } from '@ant-design/icons';
import { Card } from 'antd';

import maestroIcon from '../../icons/maestro-mark-80x80.png';


interface QAMessageProp {
    message: {
      userChat: string;
      response?: string;
    };
    isLoadingResponse?: boolean;
  }

const QAMessage = forwardRef<HTMLDivElement, QAMessageProp>(({ message, isLoadingResponse }, ref) => (
  <Card ref={ref} className="message-card">
    {' '}
    <div className="chat-message">
      <div className="user-chat">
        <div className="label">
          <UserOutlined style={{ fontSize: '26px' }} />
          {' '}
          <strong>You</strong>
        </div>
        <Editor defaultValue={message.userChat} readOnly />
      </div>
      {isLoadingResponse ? (
        <div className="loading-placeholder" />
      ) : (
        message.response && (
        <div className="response-chat">
          <div className="label">
            <img src={maestroIcon} alt="maestro ai logo" className="maestro-logo" />
            <strong>Maestro</strong>
          </div>
          <Editor defaultValue={message.response} readOnly />
        </div>
        ))}
    </div>
  </Card>
));



export default QAMessage;
