/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';

import './TopicsTipsCard.scss';
import { BriefingTopic } from '../../../redux/modules/briefings/schemas';
import mapTopicToEmojiTopic from '../../../utils/topicEmojis';

type TopicsTipsCardProps = {
  selectedTopics: BriefingTopic[];
};

const TopicsTipsCard: React.FC<TopicsTipsCardProps> = ({
  selectedTopics,
}) => {

  const cardRef = useRef<HTMLDivElement>(null); // Specify the type for the card ref
  const contentRef = useRef<HTMLDivElement>(null); // Specify the type for the content ref

  useEffect(() => {
    const card = cardRef.current;
    const content = contentRef.current;

    if (card && content) {
      const isOverflowing = content.offsetHeight > card.offsetHeight;
      if (isOverflowing) {
        content.classList.add('is-overflowing');
      } else {
        content.classList.remove('is-overflowing');
      }
    }
  }, [selectedTopics]);

  return (
    <div className="recommendations-card" ref={cardRef}>
      <div className="scrolling-content" ref={contentRef}>
        <h3>Your Daily Briefing</h3>
        { /*selectedTopics.length === 0 && (
          <div>
            <p className="topics-placeholder">Select the topics you want to get briefed on every day.</p>
          </div>
        )*/}
        <div className="bars" />
        <div className="bars" />
        <div className="bars" />

        {selectedTopics.map((topic, index) => (
          <div key={topic.name + index} className="sample-briefing-section">
            <h2>
              {mapTopicToEmojiTopic(topic.name)}
            </h2>
            <div className="bars" />
            <div className="bars" />
            <div className="bars" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default TopicsTipsCard;
