/* eslint-disable import/prefer-default-export */
import * as t from 'io-ts';

const validSourceNames = t.union([
  t.literal('Slack'),
  t.literal('GitHub'),
  t.literal('Notion'),
  t.literal('Jira'),
  t.literal('Height'),
  t.literal('Zoom'),
  t.literal('Google'),
  t.literal('Other'),
  t.literal('Meet'),
  t.literal('Linear'),
  t.literal('Drive'),
]);


export const vConnectedSource = t.type({
  name: validSourceNames,
  meta: t.string,
  category: t.string,
  isConnected: t.boolean,
  isComingSoon: t.boolean,
});
export type ConnectedSource = t.TypeOf<typeof vConnectedSource>;

export const vConnectedSources = t.array(vConnectedSource);
export type ConnectedSources = ConnectedSource[];
