/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { Row, Col, Select } from 'antd';
import Editor from 'rich-markdown-editor';
import ReportDetailPage from '../ReportDetailPage/ReportDetailPage';
import { Briefing } from '../../redux/modules/briefings/schemas';
import AdminContextView from './AdminContextView';



interface AdminBriefingsProps {
  selectedBriefing: Briefing | null
}

const AdminBriefings: React.FC<AdminBriefingsProps> = ({ selectedBriefing }) => {
  const [leftSelection, setLeftSelection] = useState(null);
  const [rightSelection, setRightSelection] = useState(null);


  const options = useMemo(() => {
    const baseOptions = [
      { label: 'Report Detail Page', value: '0' },
    ];

    let extendedOptions = [...baseOptions];

    if (selectedBriefing?.tableResponses) {
      const tableOptions = selectedBriefing.tableResponses.map((table, index) => ({
        label: `Table ${table.source}`,
        value: `t${index}`,
      }));
      extendedOptions = extendedOptions.concat(tableOptions);
    }

    if (selectedBriefing?.briefingContext) {
      const contextOptions = selectedBriefing.briefingContext.map((context, index) => ({
        label: `Context ${context.source}`,
        value: `c${index}`,
      }));
      extendedOptions = extendedOptions.concat(contextOptions);
    }

    return extendedOptions;
  }, [selectedBriefing]);

  function renderContent(selection: string) {
    if (!selectedBriefing) return null;

    if (selection === '0') {
      return <ReportDetailPage briefing={selectedBriefing} />;
    }

    const prefix = selection.charAt(0);
    const index = parseInt(selection.substring(1), 10);

    if (prefix === 't' && index < selectedBriefing.tableResponses.length) {
      const tableResponse = selectedBriefing.tableResponses[index];
      return <Editor value={tableResponse.table} readOnly />;
    } if (prefix === 'c' && selectedBriefing.briefingContext && index < selectedBriefing.briefingContext.length) {
      const context = selectedBriefing.briefingContext[index];
      return <AdminContextView context={context} />;
    }
    return 'No content for selection';
  }

  return (
    <Row>
      <Col span={12}>
        <Select
          style={{ width: 200 }}
          placeholder="Select an option"
          options={options}
          onChange={setLeftSelection}
        />
        <div>
          {leftSelection && renderContent(leftSelection)}
        </div>
      </Col>
      <Col span={12}>
        <Select
          style={{ width: 200 }}
          placeholder="Select an option"
          options={options}
          onChange={setRightSelection}
        />
        <div>
          {rightSelection && renderContent(rightSelection)}
        </div>
      </Col>
    </Row>
  );
};


export default AdminBriefings;
