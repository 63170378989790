/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Tag,
  Button,
  Dropdown,
} from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSun, faCalendarWeek, faFileUser,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faPlus, faCircleDashed, faCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faClock,
} from '@fortawesome/pro-regular-svg-icons';

import mapTopicToEmojiTopic from '../../utils/topicEmojis';
import FeedbackComponent from '../FeedbackComponent/FeedbackComponent';
import BriefingDetails from '../BriefingDetails/BriefingDetails';

import './ReportDetailPage.scss';
import '../DemoPages.scss';
import '../DemoPagesLayout.scss';
import {
  Briefing, BriefingItem, BriefingItemLink, BriefingItemTag,
} from '../../redux/modules/briefings/schemas';
import { markAsRead, updatePriorityTag } from '../../redux/modules/briefings/actions';
import { getPriorityTags } from '../../redux/modules/briefings/selectors';
import { sourceLogos } from '../../utils/logoSources';
import { getConnectedSources } from '../../redux/modules/appIntegrations/selectors';
import { ConnectedSource } from '../../redux/modules/appIntegrations/schemas';
import BriefingMentions from '../Mentions/BriefingAtMentions/BriefingMentions';
import MentionDetails from '../Mentions/MentionDetails/MentionDetails';
import { Mention } from '../../redux/modules/mentions/schemas';
import { getMentions } from '../../redux/modules/mentions/selectors';


type TagOrSource = BriefingItemTag | string;

const ReportDetailPage: React.FC<{ briefing: Briefing }> = ({ briefing }) => {
  const dispatch = useDispatch();

  const priorityTags = useSelector(getPriorityTags);
  const sources = useSelector(getConnectedSources);
  const mentions = useSelector(getMentions);


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<string | React.SetStateAction<null>>(null);
  const [activeItemType, setActiveItemType] = useState<string | React.SetStateAction<null>>(null);
  const [selectedTagsAndSources, setSelectedTagsAndSources] = useState<Array<string>>([]);

  const {
    briefingId,
    briefingItems,
    briefingTitle: title,
    briefingSummary: summary,
    briefingDateTime: date,
    briefingType: type,
    timeSaved,
    end_unix,
  } = briefing;


  const parsedDate = new Date(date);

  const getIcon = () => {
    switch (type) {
      case 'DailyBriefing':
        return <FontAwesomeIcon icon={faSun} className="item-icon orange" />;
      case 'WeekInReview':
        return <FontAwesomeIcon icon={faCalendarWeek} className="item-icon red" />;
      case 'MonthlyTeamRetro':
        return <FontAwesomeIcon icon={faFileUser} className="item-icon blue" />;
      default:
        return <FontAwesomeIcon icon={faSun} className="item-icon orange" />;
    }
  };

  useEffect(() => {
    if (briefing.isUnread) {
      dispatch(markAsRead(briefing.briefingId));
    }
  }, []);

  const isPriorityTag = (name: string): boolean => priorityTags.some((tag: BriefingItemTag) => tag.tag_name === name);

  const handleMenuClick = (tag: BriefingItemTag) => {
    dispatch(updatePriorityTag(tag));
  };

  const generateMenu = (tag: BriefingItemTag) => ({
    onClick: () => handleMenuClick(tag),
    items: [
      {
        label: isPriorityTag(tag.tag_name) ? 'Remove from Priority Tags' : 'Add to Priority Tags',
        key: '1',
        icon: <FontAwesomeIcon className="tag-dropdown-icon" icon={isPriorityTag(tag.tag_name) ? faCircleDashed : faCircle} />,
      },
    ],
  });


  const getHeaderTitle = () => {
    // Function to add ordinal suffix to day
    const getOrdinalSuffix = (day: number) => {
      if (day > 3 && day < 21) return 'th'; // handles 4th to 20th
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    // Using Intl.DateTimeFormat to get month name and day
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(parsedDate);
    const day = parsedDate.getDate();
    const year = parsedDate.getFullYear();

    // Assembling the formatted date string
    const formattedDate = `${monthName} ${day}${getOrdinalSuffix(day)}, ${year}`;

    let headerTypeTitle = '';
    switch (type) {
      case 'DailyBriefing':
        headerTypeTitle = 'Daily Briefing';
        break;
      case 'WeekInReview':
        headerTypeTitle = 'Weekly Review';
        break;
      case 'MonthlyTeamRetro':
        headerTypeTitle = 'Monthly Team Retro';
        break;
      default:
        return 'Daily Briefing';
    }

    return `${headerTypeTitle}: ${formattedDate}`;
  };

  const toggleSidebar = () => {
    if (isSidebarOpen) setActiveItem(null);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const selectItem = (itemId: string | React.SetStateAction<null>, itemType: string) => {
    setActiveItem(itemId);
    setActiveItemType(itemType);
    if (!isSidebarOpen) setIsSidebarOpen(true);
  };

  const toggleTagAndSourceSelection = (itemName: string) => {
    setSelectedTagsAndSources((prevItems) => {
      if (prevItems.includes(itemName)) {
        return prevItems.filter((item) => item !== itemName);
      }
      return [...prevItems, itemName];
    });
  };

  // Function to check if a briefing item should be shown based on selected tags and sources
  const shouldShowItem = (item: BriefingItem) => {
    if (selectedTagsAndSources.length === 0) return true;
    // Show all items if no selection
    const linkedSources = item.links.map((link) => link.source);
    return item.tags.some((tag) => selectedTagsAndSources.includes(tag.tag_name)) || linkedSources.some((source) => selectedTagsAndSources.includes(source));
  };

  const allTags: TagOrSource[] = briefingItems.reduce((acc, item) => {
    item.tags.forEach((tag) => {
      if (!acc.some((t) => typeof t === 'object' && 'tag_name' in t && t.tag_name === tag.tag_name)) acc.push(tag);
    });
    return acc;
  }, [] as TagOrSource[]);

  // Sort function to prioritize important tags
  const sortedTags = allTags.sort((a, b) => {
    // Determine if 'a' is important
    const aIsImportant = typeof a === 'string' ?
      isPriorityTag(a)
      : isPriorityTag(a.tag_name);

    // Determine if 'b' is important
    const bIsImportant = typeof b === 'string' ?
      isPriorityTag(b)
      : isPriorityTag(b.tag_name);

    // If 'a' is important and 'b' is not, 'a' should come first
    if (aIsImportant && !bIsImportant) {
      return -1;
    }

    // If 'b' is important and 'a' is not, 'b' should come first
    if (bIsImportant && !aIsImportant) {
      return 1;
    }

    // If both are important or not important, keep their original order
    return 0;
  });

  // eslint-disable-next-line consistent-return
  const findMentionById = (): Mention | undefined => {
    if (activeItem) {
      return mentions.find((mention) => mention.id === activeItem);
    }
  };

  const filterMentionsForBriefing = () => {
    const briefingTime = end_unix;

    if (typeof briefingTime === 'undefined') {
      console.error('briefingTime is undefined');
      return [];
    }

    const twentyFourHoursInSeconds = 24 * 60 * 60;
    const startTime = briefingTime - twentyFourHoursInSeconds;

    return mentions.filter((mention) => {
      const mentionTime = mention.timestamp;
      return mentionTime && mentionTime >= startTime && mentionTime <= briefingTime && mention.status !== 'responded';
    });
  };

  const briefingMentions = filterMentionsForBriefing();

  const allSources: TagOrSource[] = briefingItems.reduce((acc, item) => {
    const linkedSources = item.links.map((link) => link.source);
    linkedSources.forEach((source) => {
      if (!acc.includes(source)) acc.push(source);
    });
    return acc;
  }, [] as TagOrSource[]);

  const allTagsAndSources = allSources.concat(sortedTags);

  const groupedByTopic = briefingItems.reduce<Record<string, BriefingItem[]>>((acc, item) => {
    const { topic } = item;
    if (!acc[topic]) {
      acc[topic] = [];
    }
    acc[topic].push({
      ...item,
      links: item.links.map((link) => ({
        ...link,
        source: link.source as 'Slack' | 'GitHub' | 'Other' | 'Notion' | 'Jira' | 'Height',
      })),
    });
    return acc;
  }, {});

  return (
    <div className="grid-container">
      <header className="grid-header">
        <div className="header-content-wrapper">
          <h1>
            {getIcon()}
            {getHeaderTitle()}
          </h1>
          <div className="tags">
            {allTagsAndSources.map((tagOrSource, index) => (
              typeof tagOrSource === 'string' ? (
                <Button key={index} className={`tag ${selectedTagsAndSources.includes(tagOrSource) ? 'selected' : ''}`} onClick={() => toggleTagAndSourceSelection(tagOrSource)}>
                  {sourceLogos[tagOrSource as keyof typeof sourceLogos]}
                </Button>
              ) : (
                <Dropdown.Button style={{ width: 'auto' }} menu={generateMenu(tagOrSource)} key={index} placement="bottomRight" arrow className={`dropdown-tag ${selectedTagsAndSources.includes(tagOrSource.tag_name) ? 'selected' : ''}`} onClick={() => toggleTagAndSourceSelection(tagOrSource.tag_name)}>
                  { isPriorityTag(tagOrSource.tag_name) && <span className="important-tag" />}
                  {tagOrSource.tag_name}
                </Dropdown.Button>
              )
            ))}
          </div>
          <div className="actions">
            <Button type="text" ghost icon={<FontAwesomeIcon icon={faPlus} />} />
            <div className="sources">
              {
                  sources.map((source: ConnectedSource) => {
                    if (!source.isComingSoon && source.isConnected) {
                      return sourceLogos[source.name];
                    }
                    return null;
                  })
                }
            </div>
          </div>
        </div>
      </header>
      <main className="grid-main">
        <div className="briefing-container">
          <FeedbackComponent identifier={{ briefingId }} reaction={null} />
          <div className="briefing">
            <h1>{title}</h1>
            <p>{summary}</p>
            {briefingMentions && briefingMentions.length > 0 && (
            <BriefingMentions mentions={briefingMentions} selectItem={selectItem} />
            )}
            {Object.entries(groupedByTopic).map(([topic, items], index) => (
              <div key={index}>
                {items.filter(shouldShowItem).length > 0 && (<h2>{mapTopicToEmojiTopic(topic)}</h2>)}
                {items.filter(shouldShowItem).map((item) => (
                  <div key={item.id} className={`briefing-item ${activeItem === item.id ? 'active' : ''}`} onClick={() => selectItem(item.id, 'briefing')}>
                    <div className="briefing-item-header">{item.title}</div>
                    <p>{item.summary}</p>
                    <div className="item-tag-list">
                      {
                          // First, ensure unique sources and map over them
                          [...new Set(item.links.map((link) => link.source))].map((source, sourceIndex) => (
                            <Tag key={sourceIndex} className="tag">
                              {sourceLogos[source as keyof typeof sourceLogos]}
                            </Tag>
                          ))
                        }
                      {
                          // Sort the tags so important tags come first
                          item.tags.slice().sort((a, b) => (isPriorityTag(b.tag_name) ? 1 : 0) - (isPriorityTag(a.tag_name) ? 1 : 0)).map((tag, tagIndex) => (
                            <Tag key={tagIndex} className="tag">
                              {isPriorityTag(tag.tag_name) && <span className="important-tag" />}
                              {tag.tag_name}
                            </Tag>
                          ))
                        }
                    </div>
                  </div>
                ))}
              </div>
            ))}
            {timeSaved && (
            <div className="briefing-time-saved">
              <FontAwesomeIcon icon={faClock} />
              {timeSaved}
            </div>
            )}
          </div>
        </div>
        <aside className={`grid-sidebar ${isSidebarOpen ? '' : 'collapsed'}`}>
          {activeItemType === 'briefing' && (
          <BriefingDetails
            title={briefingItems.find((item) => item.id === activeItem)?.title || 'Default Title'}
            body={briefingItems.find((item) => item.id === activeItem)?.body || ''}
            briefingId={briefingId}
            briefingItemId={briefingItems.find((item) => item.id === activeItem)?.id || ''}
            tags={briefingItems.find((item) => item.id === activeItem)?.tags || []}
            links={briefingItems.find((item) => item.id === activeItem)?.links as BriefingItemLink[] || []}
            toggleSidebar={toggleSidebar}
          />
          )}
          {activeItemType === 'mention' && (
          <MentionDetails mention={findMentionById()} toggleSidebar={toggleSidebar} />
          )}
        </aside>
      </main>
    </div>
  );

};

export default ReportDetailPage;
