/* eslint-disable import/prefer-default-export */
import { get } from '../../common/rest';
import { AppThunk } from '../../store';

import * as types from './types';
import * as schemas from './schemas';



export const fetchConnectedSources = (): AppThunk => async (dispatch) => {
  dispatch({
    type: types.GET_CONNECTED_SOURCES,
  });

  try {
    const connectedSources = await get<undefined, schemas.ConnectedSources>(schemas.vConnectedSources, '/api/v1.0/app_integrations/status');
    dispatch({
      type: types.GET_CONNECTED_SOURCES_SUCCESS,
      payload: connectedSources,
    });
  } catch (err: any) {
    console.error('Failed to retrieve connected sources from backend', err);
    dispatch({
      type: types.GET_CONNECTED_SOURCES_FAILURE,
    });
  }
};



export const triggerRefresh = (): AppThunk => async (dispatch) => {
  dispatch({
    type: types.TRIGGER_OAUTH_REFRESH,
  });
};
