import * as types from './types';

const defaultState: types.NotionReducer = {
  notionOauthUrl: '',
  oauthState: '',
  notionOauthStatus: false,
  oauthStatusResponse: false,
  notionPages: [],
};


export default function notionReducer(state = defaultState, action: types.NotionAction): types.NotionReducer {
  switch (action.type) {
    case types.GET_NOTION_OAUTH_URL_SUCCESS:
      return {
        ...state,
        notionOauthUrl: action.payload.url,
        oauthState: action.payload.state,
      };
    case types.GET_NOTION_OAUTH_FAILURE:
      return {
        ...state,
        notionOauthUrl: '',
      };
    case types.GET_NOTION_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: action.payload.oauthStatusResponse,
        notionOauthStatus: action.payload.notionOauthStatus,
      };
    case types.GET_NOTION_PAGES:
      return {
        ...state,
        notionPages: action.payload.notionPages,
      };
    case types.RESET_NOTION_OAUTH:
      return {
        ...defaultState,
        notionOauthStatus: state.notionOauthStatus,
      };
    case types.LOGOUT_NOTION:
      return defaultState;
    default:
      return state;
  }
}
