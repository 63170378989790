import { StatusMessage } from '../common/types';

// models

export interface Integration {
  id: number;
  userId: number;
  googleToken?: string;
  googleRefreshToken?: string;
  sourceId: string;
  sourceEmail: string;
  sourceType: string;
}

// actions

export const INTEGRATION_LIST_REQUEST = 'integration/INTEGRATION_LIST_REQUEST';
export const INTEGRATION_LIST_SUCCESS = 'integration/INTEGRATION_LIST_SUCCESS';
export const INTEGRATION_LIST_FAILURE = 'integration/INTEGRATION_LIST_FAILURE';
export const INTEGRATION_GOOGLE_CHECK_SUCCESS = 'auth/GOOGLE_CHECK_SUCCESS';
export const INTEGRATION_GOOGLE_CHECK_FAILURE = 'auth/GOOGLE_CHECK_FAILURE';
export const INTEGRATION_INDEX_REQUEST = 'integration/INTEGRATION_INDEX_REQUEST';
export const INTEGRATION_INDEX_SUCCESS = 'integration/INTEGRATION_INDEX_SUCCESS';
export const INTEGRATION_INDEX_FAILURE = 'integration/INTEGRATION_INDEX_FAILURE';

export interface IntegrationListRequestAction {
  type: typeof INTEGRATION_LIST_REQUEST;
}
export interface IntegrationListSuccessAction {
  type: typeof INTEGRATION_LIST_SUCCESS;
  payload: {
    integrationList: Integration[];
  };
}
export interface IntegrationListFailureAction {
  type: typeof INTEGRATION_LIST_FAILURE;
  payload: StatusMessage;
}
export interface IntegrationGoogleCheckSuccessAction {
  type: typeof INTEGRATION_GOOGLE_CHECK_SUCCESS;
  payload: {
    hasAuth: boolean;
  };
}
export interface IntegrationGoogleCheckFailureAction {
  type: typeof INTEGRATION_GOOGLE_CHECK_FAILURE;
  payload: StatusMessage;
}
export interface IntegrationIndexRequestAction {
  type: typeof INTEGRATION_INDEX_REQUEST;
}
export interface IntegrationIndexSuccessAction {
  type: typeof INTEGRATION_INDEX_SUCCESS;
}
export interface IntegrationIndexFailureAction {
  type: typeof INTEGRATION_INDEX_FAILURE;
}
export type IntegrationAction = IntegrationListRequestAction | IntegrationListSuccessAction | IntegrationListFailureAction |
 IntegrationGoogleCheckSuccessAction| IntegrationGoogleCheckFailureAction | IntegrationIndexRequestAction |
 IntegrationIndexSuccessAction | IntegrationIndexFailureAction;

// reducers

export interface IntegrationReducerState{
  googleAuthExists: boolean;
  listPending: boolean;
  integrationList: Integration[];
  indexPending: boolean;
}
