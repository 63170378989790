import * as types from './types';

// import { Workspace } from './schemas';

const defaultState: types.WorkspaceReducer = {
  workspaces: [],
  slackOauthUrl: '',
  appId: '',
  teamId: '',
  oauthState: '',
  slackOauthStatus: false,
  oauthStatusResponse: false,
  slackChannels: [],
  activityLoading: false,
  slackInstallId: null,
};

export default function workspaceReducer(state = defaultState, action: types.WorkspaceAction): types.WorkspaceReducer {
  switch (action.type) {
    case types.GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        workspaces: action.payload.workspaces,
      };
    case types.GET_WORKSPACE_FAILURE:
      return {
        ...state,
        workspaces: [],
      };
    case types.GET_SLACK_OAUTH_URL_SUCCESS:
      return {
        ...state,
        slackOauthUrl: action.payload.url,
        oauthState: action.payload.state,
      };
    case types.GET_SLACK_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: action.payload.slackOauthStatus,
        slackOauthStatus: action.payload.slackOauthStatus,
      };
    case types.RESET_SLACK_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: false,
      };
    case types.GET_SLACK_OAUTH_FAILURE:
      return {
        ...state,
        slackOauthUrl: '',
      };
    case types.GET_SLACK_DATA_SUCCESS:
      return {
        ...state,
        appId: action.payload.appId,
        teamId: action.payload.teamId,
      };
    case types.GET_SLACK_CHANNELS:
      return {
        ...state,
        slackChannels: action.payload.slackChannels,
      };
    case types.GET_SLACK_DATA_FAILURE:
      return {
        ...state,
        appId: '',
        teamId: '',
      };
    case types.GET_ACTIVITY:
      return {
        ...state,
        activityLoading: true,
      };

    case types.GET_ACTIVITY_ERROR:
      return {
        ...state,
        activityLoading: false,
      };
    case types.GET_ACTIVITY_NO_CHANGE:
      return {
        ...state,
        activityLoading: false,
      };
    case types.SET_SLACK_INSTALL_ID:
      return {
        ...state,
        slackInstallId: action.payload.slackInstallId,
      };
    case types.RESET_SLACK_OAUTH:
      return {
        ...defaultState,
        slackOauthStatus: state.slackOauthStatus,
        slackChannels: state.slackChannels,
      };
    case types.LOGOUT_WORKSPACE:
      return defaultState;
    default:
      return state;
  }
}
