/* eslint-disable camelcase */
/* eslint-disable react/jsx-fragments */
import React, {
  useEffect, useState, ChangeEvent, useCallback,
} from 'react';
import {
  Select, Input, DatePicker, Card, Collapse,
} from 'antd';
import Editor from 'rich-markdown-editor';
import dayjs from 'dayjs';
import { Intent } from '@blueprintjs/core';
import { post } from '../../redux/common/rest';
import { Submitted, vSubmitted } from '../../redux/modules/common/schemas';
import { default_doc } from './default_prompts';
import AppToaster from '../../toaster';

import './PromptSandbox.scss';

const { Panel } = Collapse;

interface IPrompt {
  multi_source_briefing: string;
  multi_source_prompt: string;
  task_daily_briefing: string;
  task_prompt: string;
  slack_daily_briefing: string;
  slack_prompt: string;
  activity_type: 'Daily_Briefing';
  source: string;
  user_id: number;
  timestamp: string;
  report_id: string;
  prompts_name: string;
  task_context?: {
    task_update: string;
    list_name: string;
    task_description: string;
  }[];
  slack_context?: {
    channel_id: string;
    messages: string[];
  }[];
  slack_message? : {
    message: string;
    timestamp: string;
    user: string;
    client_msg_id: string;
    external_links: string;
    channel_name: string;
  }
}

const { Option } = Select;

const PromptSandbox: React.FC = () => {
  const [prompts, setPrompts] = useState<IPrompt[]>([]);
  const [promptName, setPromptName] = useState('');
  const [selectedPrompt, setSelectedPrompt] = useState<IPrompt | undefined>();
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [selectedSource, setSelectedSource] = useState<string>('');
  const [editorKey, setEditorKey] = useState(0);
  const [multiSourcePromptContent, setMultiSourcePromptContent] = useState<string>('');
  const [taskPromptContent, setTaskPromptContent] = useState<string>('');
  const [slackPromptContent, setSlackPromptContent] = useState<string>('');

  useEffect(() => {
    const fetchPrompts = async () => {
      const response = await fetch('/api/v1.0/admin/prompts');
      const data = await response.json();
      data.push(default_doc);
      console.log(data);
      setPrompts(data);
    };
    fetchPrompts();
  }, []);

  useEffect(() => {
    if (selectedPrompt) {
      setSelectedTimestamp(dayjs(selectedPrompt.timestamp));
      setMultiSourcePromptContent(selectedPrompt.multi_source_prompt);
      setTaskPromptContent(selectedPrompt.task_prompt);
      setSlackPromptContent(selectedPrompt.slack_prompt);
      setPromptName(selectedPrompt.prompts_name);
      setEditorKey((prevKey) => prevKey + 1);
      setSelectedSource(selectedPrompt.source);
      if (selectedPrompt.slack_message) {
        console.log('Slack Messages');
        console.log(selectedPrompt.slack_message);
      }
    }
  }, [selectedPrompt]);

  const handleChange = (value: string) => {
    const prompt = prompts.find((prompt_) => prompt_.report_id === value);
    setSelectedPrompt(prompt);
    setEditorKey((prevKey) => prevKey + 1);
  };


  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPromptName(event.target.value);
  };

  const handleSourceChange = (value: string) => {
    setSelectedSource(value);
    if (selectedPrompt) {
      setSelectedPrompt({ ...selectedPrompt, source: value });
    }
  };

  const handleTimestampChange = (value) => {
    const formattedDate = dayjs(value).format('YYYY-MM-DD HH:mm');
    setSelectedTimestamp(dayjs(formattedDate));
  };

  const renderEditorWithTitle = useCallback((fieldValue: string | undefined, title: string) => {
    const handleOnChange = (value: string) => {
      if (title === 'Multi-Source Prompt') {
        setMultiSourcePromptContent(value);
      } else if (title === 'Task Prompt') {
        setTaskPromptContent(value);
      } else if (title === 'Slack Prompt') {
        setSlackPromptContent(value);
      }
    };

    return (
      <div style={{ marginBottom: '20px' }}>
        <h3>{title}</h3>
        <Editor
          defaultValue={fieldValue || ''}
          readOnly={false}
          key={`${editorKey}-${title}`}
          className={title === 'Multi-Source Prompt' || title === 'Task Prompt' || title === 'Slack Prompt' ? 'editor-prompt' : ''}
          onChange={title === 'Multi-Source Prompt' || title === 'Task Prompt' || title === 'Slack Prompt' ? (getValue) => handleOnChange(getValue()) : undefined}
        />
      </div>
    );
  }, [editorKey, multiSourcePromptContent, taskPromptContent, slackPromptContent, selectedPrompt]);

  const handleSubmit = useCallback(() => {
    const submitData = {
      start: dayjs(selectedTimestamp).format('YYYY-MM-DD HH:mm'),
      source: selectedSource,
      custom_multi_source_prompt: multiSourcePromptContent,
      custom_task_prompt: taskPromptContent,
      custom_slack_prompt: slackPromptContent,
      prompts_name: promptName,
    };

    post<{prompts_name: string, start: string, source: string, custom_multi_source_prompt: string, custom_task_prompt: string, custom_slack_prompt: string }, Submitted>(vSubmitted, '/api/v1.0/admin/dailyBriefingPrompt', submitData).then(() => {
      AppToaster.show({ message: 'Submitted Prompts, check back in a few minutes', intent: Intent.SUCCESS, timeout: 5000 });
    });

  }, [promptName, multiSourcePromptContent, taskPromptContent, slackPromptContent, selectedTimestamp, selectedSource]);


  const renderTaskContext = (taskContext) => (
    taskContext.map((context) => (
      <>
        <h1>{context.list_name}</h1>
        <div style={{ marginBottom: '20px' }}>
          Task Name:
          {context.task_name}
        </div>
        <div style={{ marginBottom: '20px' }}>
          Description:
          {context.task_description}
        </div>
        <div style={{ marginBottom: '20px' }}>
          Update:
          {context.task_update}
        </div>
      </>
    ))
  );

  const renderSlackContext = (slackContext) => {
    console.log(slackContext);
    return slackContext.map((context) => (
      <>
        <h1>{context.channel_id}</h1>
        <ul>
          {context.messages.map((message, messageIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={messageIndex}>{message}</li>
          ))}
        </ul>
      </>
    ));
  };


  return (
    <div>
      <Select
        showSearch
        style={{ width: 300, marginBottom: '20px' }}
        placeholder="Select a prompt"
        optionFilterProp="children"
        onChange={handleChange}
        value={selectedPrompt?.report_id}
      >
        {prompts.map((prompt) => (
          <Option key={prompt.report_id} value={prompt.report_id}>{prompt.prompts_name}</Option>
        ))}
      </Select>

      <Input
        style={{ marginBottom: '20px' }}
        placeholder="Prompt Name"
        value={promptName}
        onChange={handleNameChange}
      />

      <Select
        placeholder="Select source"
        value={selectedPrompt?.source || ''}
        style={{ width: 300 }}
        onChange={handleSourceChange}
      >
        <Option value="Runway">Runway</Option>
        <Option value="Primaryio">Primaryio</Option>
      </Select>

      <DatePicker
        style={{ width: 300 }}
        showTime
        placeholder="Select timestamp"
        value={selectedTimestamp}
        onChange={handleTimestampChange}
      />
      <button type="button" onClick={handleSubmit}>Submit</button>
      {selectedPrompt && (
        <>
          <Card>
            {renderEditorWithTitle(selectedPrompt.multi_source_prompt, 'Multi-Source Prompt')}
            {renderEditorWithTitle(selectedPrompt.multi_source_briefing, 'Multi-Source Briefing')}
          </Card>
          {renderEditorWithTitle(selectedPrompt.task_prompt, 'Task Prompt')}
          <Collapse>
            {selectedPrompt?.task_context && (
            <Panel header="Task Context" key="1">
              {renderTaskContext(selectedPrompt.task_context)}
            </Panel>
            )}
          </Collapse>
          {renderEditorWithTitle(selectedPrompt.task_daily_briefing, 'Task Daily Briefing')}
          {renderEditorWithTitle(selectedPrompt.slack_prompt, 'Slack Prompt')}
          <Collapse>
            {selectedPrompt?.task_context && (
            <Panel header="Slack Context" key="1">
              {renderSlackContext(selectedPrompt.slack_context)}
            </Panel>
            )}
          </Collapse>
          {renderEditorWithTitle(selectedPrompt.slack_daily_briefing, 'Slack Daily Briefing')}
        </>
      )}

    </div>
  );
};
export default PromptSandbox;
