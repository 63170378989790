/* eslint-disable import/prefer-default-export */
import { Intent } from '@blueprintjs/core';
import { Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import { post, put } from '../../common/rest';
import { AppThunk } from '../../store';
import mp from '../../../mixpanel';
import AppToaster from '../../../toaster';

import { CreateAccountRequest, CreateAccountResponse, vCreateAccountResponse } from './schemas';

import * as types from './types';
import {
  REGISTRATION_FAILURE, REGISTRATION_PASSWORD_RESET_REQUIRED, REGISTRATION_REQUEST, REGISTRATION_SUCCESS,
} from '../auth/types';
import { submitLogin } from '../auth/actions';
import { AuthRequest, UserAuthDetails, vUserAuthDetails } from '../auth/schemas';


export const setUserTopics = (topics: number[]): AppThunk => async (dispatch) => {
  dispatch({
    type: types.SETUP_SET_USER_TOPICS,
    payload: topics,
  });

  try {
    await put<{ topicIds: number[]}, null>(null, '/api/v1.0/briefings/updateTopics', { topicIds: topics });
    dispatch({
      type: types.SETUP_SET_USER_TOPICS_SUCCESS,
    });

  } catch (error) {
    console.error('Failed to update report status in backend', error);
    dispatch({
      type: types.SETUP_SET_USER_TOPICS_FAILURE,
    });

  }
};

export const setUserProfile = (company: string, role: string): AppThunk => async (dispatch) => {
  dispatch({
    type: types.SETUP_SET_USER_ROLE,
  });

  try {
    await put<{ company: string, role: string}, null>(null, '/api/v1.0/briefings/updateUserProfile', { company, role });
    dispatch({
      type: types.SETUP_SET_USER_ROLE_SUCCESS,
    });

  } catch (error) {
    console.error('Failed to update report status in backend', error);
    dispatch({
      type: types.SETUP_SET_USER_ROLE_FAILURE,
    });

  }
};



export const updateRegistrationStep = (step: number): AppThunk => async (dispatch) => {
  dispatch({
    type: types.SETUP_UPDATE_SETUP_STEP,
  });

  try {
    await put<{ step: number}, null>(null, '/api/v1.0/briefings/updateRegistrationStep', { step });

    dispatch({
      type: types.SETUP_UPDATE_SETUP_STEP_SUCCESS,
    });

  } catch (error) {
    console.error('Failed to update report status in backend', error);
    dispatch({
      type: types.SETUP_UPDATE_SETUP_STEP_FAILURE,
    });

  }
};


//eslint-disable-next-line import/prefer-default-export
export const register = (email: string, password: string, firstName: string, lastName: string, migrate?: boolean): AppThunk => async (dispatch) => {
  dispatch({
    type: REGISTRATION_REQUEST,
    payload: {
      email,
      firstName,
      lastName,
    },
  });

  try {

    const userAuthDetails = await post<AuthRequest, UserAuthDetails>(vUserAuthDetails, '/api/v1.0/auth/verify', {
      email,
    });

    if (userAuthDetails.userId !== 0 && !migrate) {
      // User exists, throw an error
      throw new Error('User already exists');
    }


    const cognitoUser = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      },
      autoSignIn: { enabled: true },
    });
    const username = cognitoUser.userSub;


    const res = await post<CreateAccountRequest, CreateAccountResponse>(vCreateAccountResponse, '/api/v1.0/users', {
      username,
      password,
      email,
      firstName,
      lastName,
    });

    const { id } = res;

    dispatch({
      type: REGISTRATION_SUCCESS,
      payload: {
        username: email,
        email,
        userId: id,
      },
    });
    mp.identify(id);
    mp.track('Registered Frontend', {});

    if (migrate) {
      await put<{email: string, migrate: boolean}, null>(null, '/api/v1.0/auth/validateEmail', {
        email,
        migrate,
      });

      dispatch(submitLogin(email, password));
    } else {
      AppToaster.show({ message: `A validation code sent to ${email}! Check your inbox to complete your sign-up.`, intent: Intent.SUCCESS, timeout: 5000 });
      dispatch(push('/verifyEmail'));
    }

  } catch (error: any) {
    console.error(error);
    dispatch({
      type: REGISTRATION_FAILURE,
      payload: {
        code: error.status,
        status: error.response?.data?.detail,
      },
    });

    if (error.code === 'InvalidPasswordException') {
      if (migrate) {
        dispatch({
          type: REGISTRATION_PASSWORD_RESET_REQUIRED,
        });
      }
    } else {
      console.error('Error signing up', error.name);
      const message = error.message || 'err.response?.data?.detail';
      AppToaster.show({ message, intent: Intent.DANGER });
    }
  }
};
