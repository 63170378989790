/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */


import React, { useState } from 'react';
import './SelectReportType.scss';

interface SelectReportTypeProps {
  onSelectionChange: (selectedType: string) => void;
}

const SelectReportType: React.FC<SelectReportTypeProps> = ({ onSelectionChange }) => {
  const [selectedReportType, setSelectedReportType] = useState<string>('');

  const handleReportTypeClick = (reportType: string) => {
    setSelectedReportType(reportType);
    onSelectionChange(reportType);
  };

  // Function to determine if a report type is active
  const isActive = (reportType: string) => selectedReportType === reportType;

  return (
    <div className="create-report-form-container">
      <h1 className="create-report-form-title">Choose a Focus Area</h1>
      <p className="create-report-form-description">
        Select a report template to get started. Maestro processes activity from linked sources into executive reports that surface different insights. You can always refine it later.
      </p>
      <div className="report-type-gallery">
        {/* Add a conditional class to each report type based on the selectedReportType */}
        <div className={`report-type ${isActive('Daily Briefing') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Daily Briefing')}>
          <h1>
            <span className="item-icon">🌅</span>
            <span className="item-title">Daily Briefing</span>
          </h1>
          <div className="report-type-description">Everything you need to start your day, pulled from places your team works.</div>
        </div>

        <div className={`report-type ${isActive('Weekly Status Report') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Weekly Status Report')}>
          <h1>
            <span className="item-icon">✅</span>
            <span className="item-title">Weekly Status Report</span>
          </h1>
          <div className="report-type-description">What got done this week, what didn’t, and what the team is doing next.</div>
        </div>

        <div className={`report-type ${isActive('Bug Progress Report') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Bug Progress Report')}>
          <h1>
            <span className="item-icon">🪲</span>
            <span className="item-title">Bug Progress Report</span>
          </h1>
          <div className="report-type-description">A status report of work your team did to resolve open bugs this week.</div>
        </div>

        <div className={`report-type ${isActive('Release Notes') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Release Notes')}>
          <h1>
            <span className="item-icon">🚀</span>
            <span className="item-title">Release Notes</span>
          </h1>
          <div className="report-type-description">A summary of all the amazing things the team shipped this week.</div>
        </div>

        <div className={`report-type ${isActive('Engineering Efficiency') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Engineering Efficiency')}>
          <h1>
            <span className="item-icon">🏗️</span>
            <span className="item-title">Engineering Efficiency</span>
          </h1>
          <div className="report-type-description">A report on how the team is performing and how it might perform better.</div>
        </div>

        <div className={`report-type ${isActive('Custom Report') ? 'active' : ''}`} onClick={() => handleReportTypeClick('Custom')}>
          <h1>
            <span className="item-icon">📝</span>
            <span className="item-title">Custom Report</span>
          </h1>
          <div className="report-type-description">Design a custom prompt to process your data across all your apps.</div>
        </div>
      </div>
    </div>
  );
};

export default SelectReportType;
