/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faInbox,
  faCalendarStar,
} from '@fortawesome/pro-duotone-svg-icons';

import { Badge } from 'antd';



import './ConnectedSidebar.scss';
import { getBriefings } from '../../redux/modules/briefings/selectors';
import { getBriefings as fetchBreifings } from '../../redux/modules/briefings/actions';
import { Briefings } from '../../redux/modules/briefings/schemas';

// Define the structure of a single message in a threa
const ConnectedSidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const briefings = useSelector(getBriefings);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    const countUnreadBriefings = (items: Briefings) => items.filter((briefing) => briefing.isUnread).length;

    setUnreadCount(countUnreadBriefings(briefings));
  }, [briefings]);


  return (
    <div className="connected-sidebar">
      <Link onClick={() => { dispatch(fetchBreifings()); }} to="/" className={classNames(['sidebar-link', 'sidebar-orange', 'sidebar-sep', location.pathname.toLowerCase() === '/' ? 'active' : ''])}>
        <FontAwesomeIcon icon={faCalendarStar} />
        <span>Today</span>
        <Badge className="sidebar-badge" count={0} />
      </Link>
      { briefings.length > 0 && (
      <Link to="/archive" onClick={() => { dispatch(fetchBreifings()); }} className={classNames(['sidebar-link', 'sidebar-blue', location.pathname.toLowerCase() === '/archive' ? 'active' : ''])}>
        <FontAwesomeIcon icon={faInbox} />
        <span>Archive</span>
        {unreadCount > 0 ? <Badge className="sidebar-badge" count={unreadCount} color="#3273dc" /> : <Badge className="sidebar-badge" count={unreadCount} /> }
      </Link>
      )}
    </div>
  );
};

export default ConnectedSidebar;
