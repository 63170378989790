import * as t from 'io-ts';

export const vSlackTeam = t.interface({
  team_id: t.string,
  team_name: t.string,
  app_id: t.string,
});

export type SlackTeam = t.TypeOf<typeof vSlackTeam>;

export const vSlackTeams = t.readonlyArray(vSlackTeam);
export type SlackTeams = t.TypeOf<typeof vSlackTeams>;


export const vSlackMessage = t.interface({
  team_id: t.string,
  team_name: t.string,
  app_id: t.string,
  blocks: t.string,
});

export type SlackMessage = t.TypeOf<typeof vSlackMessage>;


export const vSlackMessageResponse = t.interface({
  success: t.boolean,
  timestamp: t.string,
  message: t.string,
  team_name: t.string,
});

export type SlackMessageResponse = t.TypeOf<typeof vSlackMessageResponse>;

