/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { get } from '../../redux/common/rest';
import { Users, vUsers, UserModel } from '../../redux/modules/auth/schemas';
import {
  BriefingRef, vBriefingRefs, BriefingRefs,
  vBriefing,
  Briefing,
} from '../../redux/modules/briefings/schemas';

interface AdminSelectorProps {
  setSelectedBriefing: React.Dispatch<React.SetStateAction<Briefing | null>>;
}

const AdminBriefingSelector: React.FC<AdminSelectorProps> = ({ setSelectedBriefing }) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [userBriefings, setUserBriefings] = useState<BriefingRef[]>([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      const userResponse = await get<undefined, Users>(vUsers, '/api/v1.0/admin/users');
      if (userResponse) {
        setUsers(userResponse);
      }
    };
    fetchAllUsers();
  }, []);

  const handleUserSelect = async (userId: number) => {
    const userBriefingsResponse = await get<undefined, BriefingRefs>(vBriefingRefs, `/api/v1.0/admin/userBriefings/${userId}`);
    if (userBriefingsResponse) {
      setUserBriefings(userBriefingsResponse);
    }
  };

  const handleBriefingSelect = async (briefingId: string) => {
    const selectedBriefing = await get<undefined, Briefing>(vBriefing, `/api/v1.0/admin/briefing/${briefingId}`);
    if (selectedBriefing) setSelectedBriefing(selectedBriefing);
  };

  return (
    <div>
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a user"
        onSelect={handleUserSelect}
        filterOption={(input, option) => {
          if (option) {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
        options={users.map((user) => ({
          label: user.email,
          value: user.id,
        }))}
      />
      <Select
        showSearch
        style={{ width: 200 }}
        placeholder="Select a briefing"
        onSelect={handleBriefingSelect}
        filterOption={(input, option) => {
          if (option) {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }
          return false;
        }}
        options={userBriefings.map((briefing) => ({
          label: dayjs(briefing.briefingDateTime).format('MMMM DD, YYYY'),
          value: briefing.briefingId,
        }))}
      />
    </div>
  );
};


export default AdminBriefingSelector;
