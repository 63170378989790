import * as types from './types';

const defaultState: types.IntegrationReducerState = {
  googleAuthExists: false,
  listPending: false,
  integrationList: [],
  indexPending: false,
};

export default function integrationReducer(state = defaultState, action: types.IntegrationAction): types.IntegrationReducerState {
  switch (action.type) {
    case types.INTEGRATION_GOOGLE_CHECK_FAILURE:
      return {
        ...state,
        googleAuthExists: false,
      };
    case types.INTEGRATION_GOOGLE_CHECK_SUCCESS:
      return {
        ...state,
        googleAuthExists: action.payload.hasAuth,
      };
    case types.INTEGRATION_LIST_REQUEST:
      return {
        ...state,
        listPending: true,
        integrationList: [],
      };
    case types.INTEGRATION_LIST_FAILURE:
      return {
        ...state,
        listPending: false,
        integrationList: [],
      };
    case types.INTEGRATION_LIST_SUCCESS:
      return {
        ...state,
        listPending: false,
        integrationList: action.payload.integrationList,
      };
    case types.INTEGRATION_INDEX_REQUEST:
      return {
        ...state,
        indexPending: true,
      };
    case types.INTEGRATION_INDEX_SUCCESS:
      return {
        ...state,
        indexPending: false,
      };
    case types.INTEGRATION_INDEX_FAILURE:
      //temporarily do the same thing in success and failure, just complete the indexing operation
      return {
        ...state,
        indexPending: false,
      };

    default:
      return state;
  }
}
