import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Select, Input, Button,
} from 'antd';

import { actions as adminActions, selectors as adminSelectors } from '../../redux/modules/admin';

import './Admin.scss';
import { getUserFlag } from '../../redux/modules/auth/selectors';


const { Option } = Select;

enum ButtonStatus {
  CONFIRM = 'CONFIRM',
  SEND = 'SEND',
  DISABLE = 'DISABLE',
}

const Admin = () => {
  const [teamIndex, setTeamIndex] = useState(-1);
  const [messageText, setMessageText] = useState('');
  const [buttonStatus, setButtonStatus] = useState(ButtonStatus.CONFIRM);

  const slackTeams = useSelector(adminSelectors.getSlackTeams);
  const logs = useSelector(adminSelectors.getLogs);
  const dispatch = useDispatch();
  const history = useHistory();
  const userFlag = useSelector(getUserFlag);

  useEffect(() => {
    if (!userFlag || !userFlag.includes('admin')) {
      history.push('/');
    }
  }, []);

  const handleTeamChange = (value: number) => {
    setTeamIndex(value);
    //If a message has been submitted, submit button is disabled.  Changing a team resets the submit button
    if (buttonStatus === ButtonStatus.DISABLE) {
      setButtonStatus(ButtonStatus.CONFIRM);
    }
  };

  const handleInputChange = (e: any) => {
    setMessageText(e.target.value);
  };

  const handleSend = () => {
    if (buttonStatus !== ButtonStatus.SEND) {
      setButtonStatus(ButtonStatus.SEND);
    } else if (messageText.trim() !== '') {
      setButtonStatus(ButtonStatus.DISABLE);
      const cleanedMessage = messageText.replace(/"\s+|\s+"/g, '"');
      const slackTeam = slackTeams[teamIndex];
      const slackMessage = {
        team_id: slackTeam.team_id,
        team_name: slackTeam.team_name,
        app_id: slackTeam.app_id,
        blocks: cleanedMessage,
      };

      dispatch(adminActions.sendMessage(slackMessage));
    }
  };

  let buttonText = '';

  switch (buttonStatus) {
    case ButtonStatus.CONFIRM:
      buttonText = 'Send';
      break;
    case ButtonStatus.SEND:
      buttonText = 'Are you sure?';
      break;
    case ButtonStatus.DISABLE:
      buttonText = 'Message Sent';
      break;
    default:
      buttonText = '';
      break;
  }

  useEffect(() => {
    dispatch(adminActions.getTeams());
  }, []);

  return (
    <div>
      <Select
        style={{ width: 200, marginBottom: 16 }}
        placeholder="Select a team"
        onChange={handleTeamChange}
      >
        {
          slackTeams.map((slackTeam, index) => (
            <Option value={index} key={slackTeam.team_id}>{slackTeam.team_name}</Option>
          ))
        }
      </Select>

      <Input.TextArea
        rows={8}
        placeholder=' Paste Block VALUE here - i.e.
        [
          {
            "type": "section",
            "text": {
              "type": "plain_text",
              "text": "This is a plain text section block.",
              "emoji": true
            }
          }
        ]'
        value={messageText}
        onChange={handleInputChange}
      />

      <Button
        type="primary"
        onClick={() => { handleSend(); }}
        disabled={buttonStatus === ButtonStatus.DISABLE || teamIndex === -1 || messageText.trim() === ''}
        style={{ marginTop: 16 }}
      >
        {buttonText}
      </Button>
      <div className="admin-log-container">
        {logs.map((log) => {
          const success = log.success ? 'success' : 'error';
          return (
            <div key={log.timestamp}>
              <span className={`admin-log-${success}`}>
                {success}
                {' '}
              </span>
              Team:
              {' '}
              {log.team_name}
              {' '}
              TS:
              {' '}
              {log.timestamp}
              {' '}
              MSG:
              {' '}
              {log.message}
            </div>
          );
        })}
      </div>
    </div>
  );

};

export default Admin;
