import * as types from './types';

const defaultState: types.AppIntegrationsReducer = {
  sources: [],
  refresh: false,
};

export default function appIntegrations(state = defaultState, action: types.AppIntegrationsAction): types.AppIntegrationsReducer {
  switch (action.type) {
    case types.GET_CONNECTED_SOURCES_SUCCESS: {
      const updatedSources = action.payload.map((newSource) => {
        const existingSource = state.sources.find((source) => source.name === newSource.name);

        if (existingSource && existingSource.isConnected !== newSource.isConnected) {
          return newSource;
        }
        return existingSource || newSource;
      });

      const arraysAreDifferent = updatedSources.length !== state.sources.length ||
        updatedSources.some((source, index) => source.isConnected !== state.sources[index]?.isConnected);
      if (arraysAreDifferent) {
        return {
          ...state,
          sources: updatedSources,
        };
      }

      return state;
    }

    case types.TRIGGER_OAUTH_REFRESH:
      return {
        ...state,
        refresh: true,
      };
    default:
      return state;
  }
}
