/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck, // Icon for connected categories
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faCircle, // Default icon for categories not connected and not coming soon
  faCircleDashed, // Icon for categories that are coming soon
} from '@fortawesome/pro-regular-svg-icons';

import './SourceTipsCard.scss';
import { ConnectedSource } from '../../../redux/modules/appIntegrations/schemas';

const mapSourceToCategory = (source: string) => {
  if (source.toLowerCase() === 'slack') {
    return 'chat';
  }
  if (source.toLowerCase() === 'jira' || source.toLowerCase() === 'github' || source.toLowerCase() === 'height' || source.toLowerCase() === 'github issues') {
    return 'ticket';
  }
  if (source.toLowerCase() === 'zoom') {
    return 'meeting';
  }
  if (source.toLowerCase() === 'notion') {
    return 'document';
  }
  return '';
};

type SourceTipsCardProps = {
  connectedSources: ConnectedSource[];
};

const SourceTipsCard: React.FC<SourceTipsCardProps> = ({
  connectedSources,
}) => {
  const allCategories = ['chat', 'ticket', 'meeting', 'document'];

  const isConnected = (category: string) => connectedSources.some((source) => mapSourceToCategory(source.name) === category && source.isConnected);

  const isComingSoon = (category: string) => category === 'meeting' || category === 'document';

  const getIcon = (category: string) => {
    if (isConnected(category)) {
      return faCircleCheck;
    }
    if (isComingSoon(category)) {
      return faCircleDashed;
    }
    return faCircle;
  };

  const getClassName = (category: string) => {
    if (isConnected(category)) {
      return 'connected';
    }
    if (isComingSoon(category)) {
      return 'coming-soon';
    }
    return 'not-connected';
  };

  return (
    <div className="recommendations-card">
      <h3>Recommended Setup</h3>
      <p className="reccomendations-desc">Connect multiple types of tools for the best results.</p>
      <div className="check-list">
        {allCategories.map((category) => (
          <div key={category} className={`check-list-item ${getClassName(category)}`}>
            <FontAwesomeIcon icon={getIcon(category)} />
            <span>{`${category.charAt(0).toUpperCase() + category.slice(1)}s`}</span>
            {isComingSoon(category) && <span>(Coming Soon)</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SourceTipsCard;
