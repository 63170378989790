import React from 'react';
import { useSelector } from 'react-redux';

import { getConnectedSources } from '../../redux/modules/appIntegrations/selectors';

import SourceTipsCard from '../TipsCards/SourceTipsCard/SourceTipsCard';
import ConnectSourceButton from './ConnectSourceButton';
import SourceList from './SourceList';

const SettingsSources: React.FC = () => {
  const sources = useSelector(getConnectedSources);

  return (
    <div className="settings-section">
      <h2>Sources</h2>
      <div className="settings-2-column">
        <div>
          <p>Connect Maestro to the apps where you work to get daily insights across your workstreams.</p>
          <div className="settings-source-list">
            {sources.filter((source) => source.isConnected).map((source) => (
              <SourceList key={source.name} source={source} />
            ))}
          </div>
          <div className="add-sources">
            <h3>Add Sources</h3>
            <div className="button-grid">
              {sources.filter((source) => !source.isConnected).map((source) => (
                <ConnectSourceButton key={source.name} source={source} />
              ))}
            </div>
          </div>
        </div>
        <div>
          <SourceTipsCard connectedSources={sources.filter((source) => source.isConnected)} />
        </div>
      </div>
    </div>
  );
};

export default SettingsSources;
