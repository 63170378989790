import React from 'react';

import RegisterForm from './RegisterForm/RegisterForm';
import './SignUp.scss';

const SignUp = () => (
  <div className="preauth-page-container">
    <div className="preuath-form-container">
      <RegisterForm />
    </div>
  </div>
);
export default SignUp;
