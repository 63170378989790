/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGear,
} from '@fortawesome/pro-solid-svg-icons';


import './Settings.scss';
import { BriefingItemTag } from '../../redux/modules/briefings/schemas';
import { getPriorityTags } from '../../redux/modules/briefings/selectors';
import TopicSelector from './TopicSelector';
import SettingsSources from './SettingsSources';
import AuthenticatorSetup from '../AuthenticatorSetup/AuthenticatorSetup';

const Settings: React.FC = () => {
  const priorityTags = useSelector(getPriorityTags);

  return (
    <div className="grid-container">
      <header className="grid-header">
        <div className="header-content-wrapper">
          <h1>
            <FontAwesomeIcon icon={faGear} className="yellow" />
            Settings
          </h1>
        </div>
      </header>
      <main className="grid-main">
        <div className="grid-main-wrapper">
          <SettingsSources />
          <div className="settings-section">
            <h2>Briefings</h2>
            <p>Shedule and configure different types of briefings (comming soon).</p>
            <div className="switch-list">
              <div className="switch-item">
                <Switch defaultChecked />
                <span className="switch-item-title">
                  Daily Briefing
                </span>
                <span className="switch-item-meta">Daily, 9am</span>
                {/* <Button type="link" className="no-padding-button-link">Edit Time</Button> */}
              </div>
              <div className="switch-item">
                <Switch defaultChecked={false} disabled />
                <span className="switch-item-title-disabled">
                  Weekly Review
                </span>
                <span className="switch-item-meta">Fridays, 9am</span>
              </div>
              <div className="switch-item">
                <Switch defaultChecked={false} disabled />
                <span className="switch-item-title-disabled">
                  Monthly Teams Retro
                </span>
                <span className="switch-item-meta">Last Weekday of Month, 9am</span>
              </div>
            </div>
          </div>
          <TopicSelector />
          <div className="settings-section">
            <h2>Priority Tags</h2>
            <p>Add Priority Tags to help critical information bubble up to your attention.</p>
            <div className="tag-grid">
              {[...priorityTags].map((tag: BriefingItemTag) => (
                <Button key={tag.id} className="tag">
                  <span className="important-tag" />
                  {tag.tag_name}
                </Button>
              ))}
            </div>
            {/* <Button type="primary" className="add-tag-button" icon={<FontAwesomeIcon icon={faPlus} />}>Add Tag</Button> */}
          </div>
          <div className="settings-section">
            <h2>Security</h2>
            <p>Additional Security Settings</p>
            <div className="tag-grid">
              <AuthenticatorSetup />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Settings;
