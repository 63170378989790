/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Form, Input, Typography, Button,
} from 'antd';
import PasswordFields from '../PasswordFields';

const { Paragraph } = Typography;

type PasswordResetConfirmationProps = {
  onSubmitConfirmation: (code: string, newPassword: string, email?: string) => void;
  isCodeProvided: boolean;
};

type ResetFormData = {
  code: string;
  newPassword: string;
  confirmPassword: string;
  email?: string;
};

const PasswordResetConfirmation: React.FC<PasswordResetConfirmationProps> = ({ onSubmitConfirmation, isCodeProvided }) => {
  const [passwordError, setPasswordError] = useState('');
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const handleSubmit = async (values: ResetFormData) => {
    const {
      code, newPassword, confirmPassword, email,
    } = values;
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    onSubmitConfirmation(code, newPassword, email);
  };

  return (
    <div className="reset-password-page-container">
      <div className="reset-password-card">
        <Form className="registration-form" layout="vertical" onFinish={handleSubmit}>
          <h1 className="password-reset-title">Enter a New Password</h1>
          {!isCodeProvided && (
            <Paragraph>
              Check your email to get your Password Reset Code
            </Paragraph>
          )}
          <Form.Item
            name="code"
            rules={[{ required: true, message: 'Please input the code!' }]}
          >
            <Input placeholder="Password Reset Code" className="password-form-input" />
          </Form.Item>
          {isCodeProvided && (
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input placeholder="Email Address" className="password-form-input" />
          </Form.Item>
          )}
          <PasswordFields setPasswordIsValid={setPasswordIsValid} />
          {passwordError && (
            <Paragraph type="danger">
              {passwordError}
            </Paragraph>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit" className="reset-password-button" disabled={!passwordIsValid}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetConfirmation;
