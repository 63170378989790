/* eslint-disable react/jsx-fragments */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Input } from 'antd';
import { DeleteFilled, QrcodeOutlined } from '@ant-design/icons';

import { disableMFA, generateQRCode, validateAuthenticatorSetup } from '../../redux/modules/auth/actions';
import { getQrCodeUrl, getQrCodeSecret, getMFARequired } from '../../redux/modules/auth/selectors';

import './AuthenticatorSetup.scss';

const AuthenticatorSetup: React.FC = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showQrCodeStep, setShowQrCodeStep] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const qrCodeUrl = useSelector(getQrCodeUrl);
  const secretKey = useSelector(getQrCodeSecret);
  const mfaRequired = useSelector(getMFARequired);

  useEffect(() => {
    dispatch(generateQRCode());
  }, [dispatch]);

  useEffect(() => {
    if (mfaRequired) {
      setIsModalVisible(false);
    }

  }, [mfaRequired]);

  const handleNext = () => {
    setShowQrCodeStep(false);
  };

  const handleVerify = async () => {
    dispatch(validateAuthenticatorSetup(verificationCode));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowQrCodeStep(true);
  };

  const showModal = async () => {
    setIsModalVisible(true);
  };

  const handleDelete = () => {
    dispatch(disableMFA());
  };

  return (
    <div>
      <div className="authenticator-buttons">
        <Button disabled={mfaRequired} type="primary" onClick={() => showModal()} icon={<QrcodeOutlined />}>
          Set up authenticator
        </Button>
        {mfaRequired && (
        <Button danger onClick={handleDelete} icon={<DeleteFilled />} />
        )}
      </div>
      <Modal
        title="Set up authenticator app"
        visible={isModalVisible}
        onOk={showQrCodeStep ? handleNext : handleVerify}
        onCancel={handleCancel}
        footer={[
          showQrCodeStep ? (
            <Button key="next" type="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button key="verify" type="primary" onClick={handleVerify}>
              Verify
            </Button>
          ),
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        {showQrCodeStep ? (
          <>
            <p>Scan a QR code in your preferred Authenticator App</p>
            <div style={{ textAlign: 'center' }}>
              {qrCodeUrl ? (
                <img src={qrCodeUrl} alt="QR Code" style={{ width: '200px', height: '200px' }} />
              ) : (
                <p>Loading QR code...</p>
              )}
            </div>
            <div style={{ textAlign: 'center', marginTop: '16px' }}>
              <p>Alternatively, enter this code manually into your authenticator app:</p>
              <p style={{ fontWeight: 'bold', fontFamily: 'monospace' }}>{secretKey}</p>
            </div>
          </>
        ) : (
          <>
            <p>Enter the 6-digit code you see in the app</p>
            <Input
              placeholder="Enter Code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              style={{ textAlign: 'center' }}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default AuthenticatorSetup;
