/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { schemas, types } from '.';
import { get, post } from '../../common/rest';
import { AppThunk } from '../../store';
import { SlackMessage } from './schemas';



export const getTeams = (): AppThunk => async (dispatch) => {
  // Add Error Handling
  const response = await get<undefined, schemas.SlackTeams>(schemas.vSlackTeams, '/api/v1.0/slack/get_teams');
  dispatch({
    type: types.ADMIN_GET_TEAMS,
    payload: {
      teams: response,
    },
  });
};


export const sendMessage = (slackMessage: SlackMessage): AppThunk => async (dispatch) => {
  try {
    const response = await post<schemas.SlackMessage, schemas.SlackMessageResponse>(schemas.vSlackMessageResponse, '/api/v1.0/slack/dm_users', slackMessage);

    const status = response.success ? 'SUCCESS' : 'ERROR';
    dispatch({
      type: types.ADMIN_MSG_LOG,
      payload: {
        log: response,
      },
    });
  } catch (err) {
    console.error('Unkown Error:', err);
  }

};


