/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Form, Input } from 'antd';
import PasswordChecklist from 'react-password-checklist';
// import PasswordRequirementsMessage from './PasswordRequirementMessage';

import './PasswordReset.scss';

interface PasswordFieldsProps {
  setPasswordIsValid: (isPasswordValid: boolean)=> void;
}

const PasswordFields: React.FC<PasswordFieldsProps> = ({ setPasswordIsValid }) => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  return (
    <div>
      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input.Password
          className="password-form-input"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[{ required: true, message: 'Please confirm your new password!' }]}
      >
        <Input.Password
          className="password-form-input"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </Form.Item>
      {password && (
      <PasswordChecklist
        className="password-checklist"
        rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
        minLength={8}
        value={password}
        valueAgain={confirmPassword}
        onChange={(isValid) => setPasswordIsValid(isValid)}
      />
      )}

    </div>
  );
};

export default PasswordFields;
