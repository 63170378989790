import * as types from './types';

const defaultState: types.SpaceReducerState = {
  currentSpaceId: '',
  spaces: [],
};

export default function spaceReducer(state = defaultState, action: types.SpaceAction): types.SpaceReducerState {
  switch (action.type) {
    case types.SET_CURRENT_SPACE:
      return {
        ...state,
        currentSpaceId: action.payload.spaceId,
      };
    case types.GET_USER_SPACES_SUCCESS:
      return {
        ...state,
        spaces: action.payload.spaces,
      };
    case types.GET_USER_SPACES_FAILURE:
      return {
        ...state,
        spaces: [],
      };
    case types.CREATE_SPACE_SUCCESS:
      return {
        ...state,
        spaces: [...state.spaces, action.payload.space],
      };
    case types.CREATE_SPACE_FAILURE:
      return state;
    case types.ADD_USER_SUCCESS: {
      //we reduce the old list because we want to preserve the old order
      const newSpaces = state.spaces.reduce((newSpaceList, space) => {
        if (space.id !== action.payload.space.id) {
          newSpaceList.push(space);
          return newSpaceList;
        }
        newSpaceList.push(action.payload.space);
        return newSpaceList;
      }, [] as types.Space[]);

      return {
        ...state,
        spaces: newSpaces,
      };
    }
    default:
      return state;
  }
}
