/* eslint-disable react/prop-types */
import React from 'react';
import Editor from 'rich-markdown-editor';
import dayjs from 'dayjs';

import defaultSlackIcon from '../../icons/default_slack_user_icon.png';
import './SlackMessage.scss';

type MentionDetailsProps = {
    name: string;
    timestamp: string;
    body: string;
    icon: string;
    main?: boolean;
  };


const SlackMessage: React.FC<MentionDetailsProps> = ({
  name, timestamp, body, icon, main = false,
}) => {
  const formattedTimestamp = dayjs(parseFloat(timestamp) * 1000).format('MMM D, h:mma');

  const displayIcon = icon && icon.trim() !== '' ? icon : defaultSlackIcon;

  return (
    <div className={`message-container ${main ? 'main-message' : ''}`}>
      <img
        src={displayIcon}
        alt="avatar"
        className="avatar"
      />
      <div className="message-content">
        <div className="message-header">
          <span className="user-name">{name}</span>
          {timestamp && <span className="timestamp">{formattedTimestamp}</span>}
        </div>
        <div className="message-body">
          {' '}
          <Editor
            value={body}
            readOnly
            className="editor"
          />
        </div>
      </div>
    </div>
  );
};
export default SlackMessage;
