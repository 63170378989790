/* eslint-disable camelcase */
import { Auth } from 'aws-amplify';
import { Intent } from '@blueprintjs/core';
import QRCode from 'qrcode';
import { push } from 'connected-react-router';
import * as types from './types';
import { get, post, put } from '../../common/rest';
import { AppDispatch, AppThunk } from '../../store';
import {
  AuthRequest,
  UserModel,
  vUserModel,
  UserAuthDetails,
  vUserAuthDetails,
  CognitoUser,
} from './schemas';

import AppToaster from '../../../toaster';
import { LOGOUT_NOTION } from '../notion/types';
import { LOGOUT_WORKSPACE } from '../workspace/types';
// import { REGISTRATION_LOGOUT } from '../registration/types';
import { JIRA_LOGOUT } from '../jira/types';
import { HEIGHT_LOGOUT } from '../height/types';
import { AUTOMATIONS_LOGOUT } from '../automations/types';
import { register } from '../registration/actions';


export const logout = (): AppThunk => (dispatch) => {
  dispatch({
    type: types.AUTH_LOGOUT,
  });
  dispatch({
    type: LOGOUT_NOTION,
  });
  dispatch({
    type: LOGOUT_WORKSPACE,
  });
  // dispatch({
  //   type: REGISTRATION_LOGOUT,
  // });
  dispatch({
    type: JIRA_LOGOUT,
  });
  dispatch({
    type: HEIGHT_LOGOUT,
  });
  dispatch({
    type: AUTOMATIONS_LOGOUT,
  });
  dispatch({
    type: LOGOUT_WORKSPACE,
  });

  Auth.signOut({ global: true });
  dispatch(push('/login'));
};

export const updateCognitoTokens = (signInUserSession: any): AppThunk => (dispatch) => {
  const idToken = signInUserSession.idToken.jwtToken;
  const refreshToken = signInUserSession.refreshToken.token;
  const accessToken = signInUserSession.accessToken.jwtToken;
  const tokenExpiration = signInUserSession.accessToken.payload.exp;
  dispatch({
    type: types.AUTH_UPDATE_TOKENS,
    payload: {
      idToken,
      refreshToken,
      accessToken,
      tokenExpiration,
    },
  });
};


export const getUserInfo = (id: number): AppThunk => (dispatch) => {
  dispatch({
    type: types.AUTH_GETUSER_REQUEST,
    payload: {
      id,
    },
  });

  get<undefined, UserModel>(vUserModel, `/api/v1.0/users/${id}`)
    .then((res) => {
      const {
        email, username, flags, registration_step,
      } = res;
      dispatch({
        type: types.AUTH_GETUSER_SUCCESS,
        payload: {
          id,
          email,
          username,
          flags,
          registration_step,
        },
      });

      if (registration_step && registration_step > 0) {
        dispatch(push('/steps'));
      }

    })
    .catch((err) => {
      console.error('Error during authentication:', err);
      dispatch({
        type: types.AUTH_GETUSER_FAILURE,
        payload: {
          code: err.status,
          status: err.response?.data?.detail,
        },
      });
    });
};

export const verifyUserAuthDetails = (email: string, password?: string): AppThunk<Promise<UserAuthDetails | null>> => async (dispatch) => {
  dispatch({
    type: types.AUTH_LOGIN_BACKEND_REQUEST,
  });

  try {
    const userAuthDetails = await post<AuthRequest, UserAuthDetails>(vUserAuthDetails, '/api/v1.0/auth/verify', {
      email,
      ...(password && { password }),
    });

    dispatch({
      type: types.AUTH_LOGIN_BACKEND_REQUEST_SUCCESS,
    });

    return userAuthDetails;
  } catch (error) {

    dispatch({
      type: types.AUTH_LOGIN_BACKEND_REQUEST_FAILURE,
    });
    console.error(error);
    return null;
  }
};

export const processLogin = (cognitoUser: any, email: string): AppThunk => async (dispatch) => {
  const emailVerified = cognitoUser.attributes?.email_verified;

  const userAuthDetails = await dispatch(verifyUserAuthDetails(email));
  const userId = userAuthDetails ? userAuthDetails.userId : null;

  if (userId) {
    dispatch({
      type: types.AUTH_LOGIN_SUCCESS,
      payload: {
        email,
        userId,
        emailVerified,
      },
    });
    dispatch(getUserInfo(userId));
  } else {
    console.error('Login failed: userId is null');
  }
};

const handleEmailNotVerified = async (email: string, dispatch: AppDispatch) => {
  try {
    const res = await dispatch(verifyUserAuthDetails(email));
    if (res) {
      const { userId } = res;
      dispatch({
        type: types.REGISTRATION_SUCCESS,
        payload: { email, userId },
      });
      dispatch(push('/verifyEmail'));
    }
  } catch (err: any) {
    AppToaster.show({ message: err.response.data.detail, intent: Intent.DANGER });
  }
};





const checkAndMigrateUserLegacyAuth = async (email: string, password: string, dispatch: AppDispatch) => {
  const userAuthDetails = await dispatch(verifyUserAuthDetails(email, password));

  if (userAuthDetails && userAuthDetails.userId) {
    const { firstName, lastName } = userAuthDetails;
    dispatch(register(email, password, firstName, lastName, true));
  } else {
    throw new Error('User authentication details not found');
  }
};

const handleLoginExceptions = async (error: any, email: string, password: string, dispatch: AppDispatch) => {
  if (error.code === 'NotAuthorizedException') {
    await checkAndMigrateUserLegacyAuth(email, password, dispatch);

  } else if (error.code === 'UserNotConfirmedException') {
    //  Covers cases for migrated users or users who registered but never completed email verification
    await handleEmailNotVerified(email, dispatch);

  } else {
    throw error;
  }
};

export const submitLogin = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch({
    type: types.AUTH_LOGIN_REQUEST,
  });

  try {
    const cognitoUser = await Auth.signIn({ username: email, password });

    if (cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
      dispatch({
        type: types.AUTH_MFA_REQUIRED,
        payload: {
          cognitoUser,
        },
      });
    }
  } catch (error:any) {
    try {
      await handleLoginExceptions(error, email, password, dispatch);
    } catch (err) {
      AppToaster.show({ message: error.message, intent: Intent.DANGER });
      dispatch({
        type: types.AUTH_LOGIN_FAILURE,
        payload: {
          code: error.status,
          status: error.response?.data?.detail,
        },
      });
    }
  }
};


export const verifyEmail = (email: string, verificationCode: string): AppThunk => async (dispatch) => {

  dispatch({
    type: types.AUTH_VERIFY_EMAIL_REQUEST,
  });

  try {

    const result = await Auth.confirmSignUp(email, verificationCode);

    await put<{email: string, migrate: boolean}, null>(null, '/api/v1.0/auth/validateEmail', {
      email,
      migrate: false,
    });

    if (result !== 'SUCCESS') {
      AppToaster.show({ message: 'Could not validate code, please try again', intent: Intent.WARNING });
    }
  } catch (error: any) {
    console.error('Failed to verify email', error);
    let message;
    if (error.code === 'CodeMismatchException') {
      message = 'Verification code does not match. Please try again.';
    } else {
      message = `${error}`;
    }

    AppToaster.show({ message, intent: Intent.DANGER });
    dispatch({
      type: types.AUTH_VERIFY_EMAIL_FAILURE,
    });
  }
};



export const resendEmailVerificationCode = (userEmail: string): AppThunk => async (dispatch) => {
  dispatch({
    type: types.AUTH_RESEND_VERIFICATION_CODE_REQUEST,
  });

  try {
    const response = await Auth.resendSignUp(userEmail);
    console.log('Resend Verification Code Response:', response);

    dispatch({
      type: types.AUTH_RESEND_VERIFICATION_CODE_SUCCESS,
    });

    AppToaster.show({ message: `Another validation code has been sent to ${userEmail}.`, intent: Intent.SUCCESS, timeout: 5000 });
  } catch (error: any) {
    console.error('Failed to resend verification code', error);

    dispatch({
      type: types.AUTH_RESEND_VERIFICATION_CODE_FAILURE,
      payload: error.message,
    });
    AppToaster.show({ message: 'Error sending another validation code, please try again later', intent: Intent.DANGER });
  }
};



export const generateQRCode = (): AppThunk => async (dispatch) => {

  dispatch({
    type: types.AUTH_GENERATE_QR_CODE,
  });

  try {
    const user = await Auth.currentAuthenticatedUser();
    const qrSecret = await Auth.setupTOTP(user);

    const issuer = 'MaestroAI';
    const { username } = user;
    const url = `otpauth://totp/${issuer}:${username}?secret=${qrSecret}&issuer=${issuer}`;

    const qrCodeUrl = await QRCode.toDataURL(url);

    dispatch({
      type: types.AUTH_GENERATE_QR_CODE_SUCCESS,
      payload: {
        qrCodeUrl,
        qrSecret,
      },
    });

  } catch (error: any) {
    console.error('Error generating QR code', error);
    dispatch({
      type: types.AUTH_GENERATE_QR_CODE_FAILURE,
      payload: error.message,
    });
  }
};


export const validateAuthenticatorSetup = (verificationCode: string): AppThunk => async (dispatch) => {

  try {
    dispatch({
      type: types.AUTH_VALIDATE_AUTHENTICATOR,
    });
    const user = await Auth.currentAuthenticatedUser();
    await Auth.verifyTotpToken(user, verificationCode);
    await Auth.setPreferredMFA(user, 'TOTP');

    dispatch({
      type: types.AUTH_VALIDATE_AUTHENTICATOR_SUCCESS,
    });

    AppToaster.show({ message: 'Authenticator confirmed', intent: Intent.SUCCESS, timeout: 5000 });

  } catch (error) {
    console.error('Error verifying code', error);
    AppToaster.show({ message: 'Error verifying code, please try again', intent: Intent.DANGER });
    dispatch({
      type: types.AUTH_VALIDATE_AUTHENTICATOR_FAILURE,
    });
  }
};


export const validateAuthenticatorCode = (code: string, cognitoUser: CognitoUser): AppThunk => async (dispatch) => {
  try {
    dispatch({
      type: types.AUTH_VALIDATE_MFA_CODE,
    });

    await Auth.confirmSignIn(cognitoUser, code, 'SOFTWARE_TOKEN_MFA');

  } catch (error) {
    console.error('Error verifying code', error);
    AppToaster.show({ message: 'Error verifying code, please try again', intent: Intent.DANGER });
    dispatch({
      type: types.AUTH_VALIDATE_MFA_CODE_FAILURE,
    });
  }
};

export const disableMFA = (): AppThunk => async (dispatch) => {
  try {
    dispatch({
      type: types.AUTH_DISABLE_MFA_REQUEST,
    });
    const user = await Auth.currentAuthenticatedUser();
    const res = await Auth.setPreferredMFA(user, 'NOMFA');
    if (res === 'SUCCESS') {
      AppToaster.show({ message: 'MFA Authenticator successfully removed', intent: Intent.SUCCESS });
      dispatch({
        type: types.AUTH_DISABLE_MFA_REQUEST_SUCCESS,
      });
    }
  } catch (error) {
    console.error('Error disabling MFA:', error);
    AppToaster.show({ message: 'Error disabling MFA, please try again later', intent: Intent.DANGER });
    dispatch({
      type: types.AUTH_DISABLE_MFA_REQUEST_FAILURE,
    });
  }
};

export const requestPasswordReset = (email: string): AppThunk => async (dispatch) => {
  try {
    dispatch({
      type: types.AUTH_REQUEST_PASSWORD_RESET,
    });
    await Auth.forgotPassword(email);
    dispatch({
      type: types.AUTH_REQUEST_PASSWORD_RESET_SUCCESS,
    });

    AppToaster.show({ message: `Password Reset Code sent to ${email}`, intent: Intent.SUCCESS });

  } catch (error) {
    console.error('Error initiating password reset:', error);
    dispatch({
      type: types.AUTH_REQUEST_PASSWORD_RESET_FAILURE,
    });
  }
};


export const submitNewPassword = (email: string, code: string, newPassword: string): AppThunk => async (dispatch) => {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);

    dispatch(submitLogin(email, newPassword));
  } catch (error: any) {
    console.log('Error resetting password:', error);
    const message = error.message || 'err.response?.data?.detail';
    AppToaster.show({ message, intent: Intent.DANGER });
  }
};



export const authEvent = (payload: any): AppThunk => async (dispatch) => {
  const { event } = payload;

  if (event === 'verify') {
    dispatch({
      type: types.AUTH_MFA_REQUIRED,
      payload: payload.data,
    });
  }

  // if (event === 'confirmSignUp') {
  //   // this event does not return cognito user data
  //   AppToaster.show({ message: 'Email confirmed!', intent: Intent.SUCCESS });
  // }
  if (event === 'autoSignIn' || event === 'signIn') {
    dispatch(updateCognitoTokens(payload.data.signInUserSession));
    dispatch({
      type: types.AUTH_VERIFY_EMAIL_SUCCESS,
    });
    const cognitoUser = payload.data;
    const { email } = cognitoUser.attributes;
    dispatch(processLogin(cognitoUser, email));

  } else if (event === 'autoSignIn_failure') {
    console.error('Sign in Error');
    AppToaster.show({ message: 'Auto sign-in unsuccessful. Please sign in to access your account', intent: Intent.DANGER });
    dispatch(push('/login'));
  }
};
