import axios from 'axios';
import { Auth } from 'aws-amplify';
import store from '../store';
import {
  getToken, getIsUserLoggedIn, getAccessTokenExpiration, getAccessToken,
} from '../modules/auth/selectors';
import { updateCognitoTokens } from '../modules/auth/actions';


const isTokenExpired = () => {
  const tokenExpiration = getAccessTokenExpiration(store.getState());
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime > tokenExpiration;
};

const authAxios = () => {
  const instance = axios.create();
  instance.interceptors.request.use(async (config) => {
    if (getIsUserLoggedIn(store.getState())) {
      let token = getAccessToken(store.getState());
      if (token) {
        if (isTokenExpired()) {
          const session = await Auth.currentSession();
          store.dispatch(updateCognitoTokens(session));
          token = session.accessToken.jwtToken;
        } else {
          token = getAccessToken(store.getState());
        }
      } else {
        token = getToken(store.getState());
      }
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export default authAxios();
