/* eslint-disable import/prefer-default-export */
import { get, put } from '../../common/rest';
import { AppThunk } from '../../store';

import * as schemas from './schemas';
import * as types from './types';


export const createBriefingSchedule = (timezone: string): AppThunk => async (dispatch) => {
  dispatch({
    type: types.CREATE_BRIEFING_SCHEDULE,
  });

  try {
    await put<{timezone: string}, null>(null, '/api/v1.0/cronjob/briefingSchedule', { timezone });
    dispatch({
      type: types.CREATE_BRIEFING_SCHEDULE_SUCCESS,
    });

  } catch (err: any) {
    console.error('Failed to update priority tags', err);
    dispatch({
      type: types.CREATE_BRIEFING_SCHEDULE_FAILURE,
    });
  }

};


export const updatePriorityTag = (priorityTag: schemas.BriefingItemTag): AppThunk => async (dispatch) => {
  dispatch({
    type: types.UPDATE_PRIORITY_TAGS,
    payload: {
      priorityTag,
    },
  });

  try {
    await put<{priorityTag: schemas.BriefingItemTag}, null>(null, '/api/v1.0/briefings/userSettings', { priorityTag });
    dispatch({
      type: types.UPDATE_PRIORITY_TAGS_SUCCESS,
    });

  } catch (err: any) {
    console.error('Failed to update priority tags', err);
    dispatch({
      type: types.UPDATE_PRIORITY_TAGS_FAILURE,
    });
  }
};

export const getUserSettings = (): AppThunk => async (dispatch) => {
  dispatch({
    type: types.GET_USER_SETTINGS,
  });

  try {
    const response = await get<undefined, schemas.UserSettings>(schemas.vUserSettings, '/api/v1.0/briefings/userSettings');
    dispatch({
      type: types.GET_USER_SETTINGS_SUCCESS,
      payload: {
        priorityTags: response.priorityTags,
        topics: response.topics,
        userTopics: response.userTopics,
      },
    });

  } catch (err: any) {
    console.error('Failed to retrieve user Settings', err);
    dispatch({
      type: types.GET_USER_SETTINGS_FAILURE,
    });
  }
};


export const getBriefings = (): AppThunk => async (dispatch) => {
  dispatch({
    type: types.GET_BRIEFINGS_LOADING,
    payload: {
      loading: true,
    },
  });

  try {
    const response = await get<undefined, schemas.Briefings>(schemas.vBriefings, '/api/v1.0/briefings/briefings');
    dispatch({
      type: types.GET_BRIEFINGS_SUCCESS,
      payload: {
        briefings: response,
        loading: false,
      },
    });

  } catch (err: any) {
    console.error('Failed to retrieve briefings', err);
    dispatch({
      type: types.GET_BRIEFINGS_FAILURE,
      payload: { loading: false },
    });
  }
};

export const updateUserTopicsAction = (topic: schemas.BriefingTopic): AppThunk => async (dispatch) => {
  dispatch({
    type: types.UPDATE_USER_TOPICS,
    payload: topic,
  });

  try {
    await put<{ topicIds: number[]}, null>(null, '/api/v1.0/briefings/updateTopics', { topicIds: [topic.id] });
    dispatch({
      type: types.UPDATE_USER_TOPICS_SUCCESS,
    });

  } catch (error) {
    console.error('Failed to update report status in backend', error);
    dispatch({
      type: types.UPDATE_USER_TOPICS_FAILURE,
      payload: false,
    });

  }
};


export const markAsRead = (briefingId: string): AppThunk => async (dispatch) => {
  dispatch({
    type: types.MARK_AS_READ,
    payload: briefingId,
  });

  try {
    await put<{ briefingId: string}, null>(null, '/api/v1.0/briefings/updateBriefing', { briefingId });
    dispatch({
      type: types.MARK_AS_READ_SUCCESS,
    });
  } catch (err: any) {
    console.error('Failed to update report status in backend', err);
    dispatch({
      type: types.MARK_AS_READ_FAILURE,
    });
  }
};
