import * as types from './types';

const defaultState: types.GithubReducer = {
  githubOauthUrl: '',
  oauthState: '',
  githubOauthStatus: false,
  oauthStatusResponse: false,
};

export default function githubReducer(state = defaultState, action: types.GithubAction): types.GithubReducer {
  switch (action.type) {
    case types.GET_GITHUB_OAUTH_URL_SUCCESS:
      return {
        ...state,
        githubOauthUrl: action.payload.url,
        oauthState: action.payload.state,
      };
    case types.GET_GITHUB_OAUTH_FAILURE:
      return {
        ...state,
        githubOauthUrl: '',
      };
    case types.GET_GITHUB_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: action.payload.oauthStatusResponse,
        githubOauthStatus: action.payload.oauthStatusResponse,
      };
    case types.RESET_GITHUB_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: false,
      };
    case types.RESET_GITHUB_OAUTH:
      return {
        ...defaultState,
        githubOauthStatus: state.githubOauthStatus,
      };
    case types.GITHUB_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
