export const ADMIN_MSG_LOG = 'admin/MSG_LOG';
export const ADMIN_GET_TEAMS = 'admin/GET_TEAMS';

export interface AdminSuccessMessage {
    type: typeof ADMIN_MSG_LOG
    payload: {
        log: {
            'success': boolean,
            'timestamp': string,
            'message': string,
            'team_name': string,
        }
    }
}
export interface AdminGetTeams {
    type: typeof ADMIN_GET_TEAMS
    payload: {
        teams: { 'team_id': string, 'team_name': string, 'app_id': string }[]
    }
}

export type AdminAction = AdminGetTeams | AdminSuccessMessage

export interface AdminReducerState {
    teams: { 'team_id': string, 'team_name': string, 'app_id': string }[];
    logs: {
        'success': boolean,
        'timestamp': string,
        'message': string,
        'team_name': string,
    }[]
}
