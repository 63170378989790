/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import TopicsTipsCard from '../../../TipsCards/TopicsTipsCard/TopicsTipsCard';
import { getBriefingTopics } from '../../../../redux/modules/briefings/selectors';
import { BriefingTopic } from '../../../../redux/modules/briefings/schemas';
import mapTopicToEmojiTopic from '../../../../utils/topicEmojis';
import { setUserTopics, updateRegistrationStep } from '../../../../redux/modules/registration/actions';

import './SignUpTopics.scss';


type SignUpStepProps = {
  next: () => void;
  prev: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  step: number
};

const SignUpTopics: React.FC<SignUpStepProps> = ({
  next, prev, step, isFirst = false, isLast = false,
}) => {
  const dispatch = useDispatch();
  const topics = useSelector(getBriefingTopics);

  const [selectedTopics, setSelectedTopics] = useState<BriefingTopic[]>([]);

  // Function to handle adding/removing topics from the selected list
  const toggleTopicSelection = (topic: BriefingTopic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((t) => t !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };

  const handleNextClick = () => {
    const topicIds = selectedTopics.map((t: BriefingTopic) => t.id);

    dispatch(setUserTopics(topicIds));
    dispatch(updateRegistrationStep(step + 2));
    next();
  };

  return (
    <div className="signup-step-layout">
      <div className="signup-step-content-wrapper">
        <div className="step-info">
          <h1>Follow Topics Across Your Org</h1>
          <p>
            We track everything going on in apps like Slack and Jira to create a daily briefing just for you, focused on these topics.
          </p>
        </div>
        <div className="step-content">
          <div className="signup-button-list">
            {topics.map((topic: BriefingTopic) => (
              <Button
                key={topic.name}
                size="large"
                className={`topic ${selectedTopics.includes(topic) ? 'selected' : ''}`}
                onClick={() => toggleTopicSelection(topic)}
              >
                {mapTopicToEmojiTopic(topic.name)}
              </Button>
            ))}
          </div>
          <p className={classNames('step-requirements', selectedTopics.length >= 3 ? 'step-requirements-met' : '')}>Select at least 3 topics.</p>
        </div>
        <div className="step-control-buttons">
          { !isFirst && <Button onClick={() => prev()}>Back</Button> }
          { !isLast && <Button type="primary" disabled={selectedTopics.length < 3} onClick={() => handleNextClick()}>Next</Button>}
          { isLast && <Button type="primary" onClick={() => handleNextClick()}>Finish</Button>}
        </div>
      </div>
      <TopicsTipsCard selectedTopics={selectedTopics} />
    </div>
  );
};

SignUpTopics.defaultProps = {
  isFirst: false,
  isLast: false,
};


export default SignUpTopics;
