/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import './AboutTipsCard.scss';

type TopicsTipsCardProps = {
  selectedRole: string;
};

const AboutTipsCard: React.FC<TopicsTipsCardProps> = ({
  selectedRole,
}) => {

  console.log('AboutTipsCard.tsx');

  const getTitle = (role: string) => {
    switch (role.toLocaleLowerCase()) {
      case 'ceo':
        return 'For CEOs';
      case 'cto':
        return 'For CTOs';
      case 'vp of engineering':
        return 'For VPs of Eng';
      case 'founder':
        return 'For Founders';
      case 'engineering manager':
        return 'For Eng Managers';
      case 'chief of staff':
        return 'For Chiefs of Staff';
      case 'product manager':
        return 'For PMs';
      case 'engineer':
        return 'For Engineers';
      default:
        return 'Optimized for You';
    }
  };

  const getDesc = (role: string) => {
    switch (role.toLocaleLowerCase()) {
      case 'ceo':
        return 'Prioritizing strategic insights and critical developments impacting the company\'s direction, progress, performance, and culture.';
      case 'cto':
        return 'Prioritizing evolving technological landscapes influencing the company\'s technical direction and innovation.';
      case 'vp of engineering':
        return 'Prioritizing the seamless integration of engineering practices, effective project delivery, and team dynamics to enhance technical execution and innovation.';
      case 'founder':
        return 'Prioritizing a holistic view of the company\'s trajectory, blending strategic, technical, and operational insights for growth.';
      case 'engineering manager':
        return 'Prioritizing team performance, project progress, and technical challenges to align daily operations with engineering goals.';
      case 'chief of staff':
        return 'Prioritizing cross-functional updates, strategic alignments, and decision-making insights for executive support and organizational coherence.';
      case 'product manager':
        return 'Prioritizing insights on team collaboration, project progress, and feature evolution to guide product strategy and team synergy.';
      case 'engineer':
        return 'Prioritizing technical updates, project contributions, and collaborative highlights to align individual efforts with team objectives.';
      default:
        return 'Offering a comprehensive overview of internal dynamics, project statuses, and key discussions for broad team engagement.';
    }
  };

  return (
    <div className="recommendations-card">
      <h3>{getTitle(selectedRole)}</h3>
      <p className="reccomendations-desc">{getDesc(selectedRole)}</p>
    </div>
  );
};
export default AboutTipsCard;
