import { RootReducerState } from '../../store';
import * as schemas from './schemas';



export const getBriefings = (state: RootReducerState): schemas.Briefings => state.briefings.briefings;
export const getPriorityTags = (state: RootReducerState): schemas.BriefingItemTags => state.briefings.priorityTags;
export const getBriefingTopics = (state: RootReducerState): schemas.BriefingTopics => state.briefings.topics;
export const getUserTopics = (state: RootReducerState): schemas.BriefingTopics => state.briefings.userTopics;
export const getBriefingById = (state: RootReducerState, briefingId: string) => state.briefings.briefings.find((briefing) => briefing.briefingId === briefingId);
export const getBriefingLoading = (state: RootReducerState): boolean => state.briefings.loading;
