/* eslint-disable no-restricted-globals */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket, faGear } from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { actions } from '../../redux/modules/auth';
import { AppDispatch } from '../../redux/store';

import './Header.scss';

type HeaderProps = ConnectedProps<typeof connector>;

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { onLogout } = props;

  return (
    <div className="sidebar-settings">
      <Link to="/settings" className={classNames(['sidebar-link', 'sidebar-grey', location.pathname.toLowerCase() === '/settings' ? 'active' : ''])}>
        <FontAwesomeIcon icon={faGear} />
        Settings
      </Link>
      <Button type="text" onClick={onLogout} className={classNames(['sidebar-link', 'sidebar-grey', location.pathname.toLowerCase() === '/todos' ? 'active' : ''])}>
        <FontAwesomeIcon icon={faRightFromBracket} />
        Logout
      </Button>
    </div>
  );
};


const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onLogout: () => dispatch(actions.logout()),
});

const connector = connect(null, mapDispatchToProps);

export default connector(Header);
