/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';

import logo from '../../icons/maestro-logo.png';
import ForgotPasswordReset from './ForgotPassword/ForgotPasswordReset';
import UpdatePassword from './UpdatePassword/UpdatePassword';

type PasswordResetProps = {
  mode?: 'updatePassword';
};

type RouteParams = {
  code?: string;
};

const PasswordReset: React.FC<PasswordResetProps> = ({ mode }) => {
  const { code: routeCode } = useParams<RouteParams>();
  const code = routeCode;

  return (
    <div className="preauth-page-container">
      <div className="preuath-form-container">
        <div className="registration-form-content-wrapper">
          <img src={logo} alt="maestro ai logo" className="maestro-logo" />
          {mode === 'updatePassword' ? <UpdatePassword /> : <ForgotPasswordReset isCodeProvided={!!code} />}
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
