/* eslint-disable camelcase */
import { StatusMessage } from '../common/types';
import { CognitoUser } from './schemas';

export type { UserModel as User } from './schemas';

// actions

export const AUTH_LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'auth/LOGIN_FAILURE';
export const AUTH_LOGOUT = 'auth/LOGOUT';

export const AUTH_GETUSER_REQUEST = 'auth/AUTH_GETUSER_REQUEST';
export const AUTH_GETUSER_SUCCESS = 'auth/AUTH_GETUSER_SUCCESS';
export const AUTH_GETUSER_FAILURE = 'auth/AUTH_GETUSER_FAILURE';

export const AUTH_VERIFY_EMAIL_REQUEST = 'auth/AUTH_VERIFY_EMAIL_REQUEST';
export const AUTH_VERIFY_EMAIL_SUCCESS = 'auth/AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_VERIFY_EMAIL_FAILURE = 'auth/AUTH_VERIFY_EMAIL_FAILURE';

export const UPDATE_EMAIL_VERIFIED_REQUEST = 'auth/UPDATE_EMAIL_VERIFIED_REQUEST';
export const UPDATE_EMAIL_VERIFIED_SUCCESS = 'auth/UPDATE_EMAIL_VERIFIED_SUCCESS';
export const UPDATE_EMAIL_VERIFIED_FAILURE = 'auth/UPDATE_EMAIL_VERIFIED_FAILURE';

export const AUTH_RESEND_VERIFICATION_CODE_REQUEST = 'auth/AUTH_RESEND_VERIFICATION_CODE_REQUEST';
export const AUTH_RESEND_VERIFICATION_CODE_SUCCESS = 'auth/AUTH_RESEND_VERIFICATION_CODE_SUCCESS';
export const AUTH_RESEND_VERIFICATION_CODE_FAILURE = 'auth/AUTH_RESEND_VERIFICATION_CODE_FAILURE';

export const REGISTRATION_REQUEST = 'registration/REQUEST';
export const REGISTRATION_SUCCESS = 'registration/SUCCESS';
export const REGISTRATION_FAILURE = 'registration/FAILURE';
export const REGISTRATION_PASSWORD_RESET_REQUIRED = 'registration/REGISTRATION_PASSWORD_RESET_REQUIRED';
export const REGISTRATION_PASSWORD_RESET_COMPLETE = 'registration/REGISTRATION_PASSWORD_RESET_COMPLETE';

export const AUTH_UPDATE_TOKENS = 'auth/AUTH_UPDATE_TOKENS';

export const AUTH_GENERATE_QR_CODE = 'auth/AUTH_GENERATE_QR_CODE';
export const AUTH_GENERATE_QR_CODE_SUCCESS = 'auth/AUTH_GENERATE_QR_CODE_SUCCESS';
export const AUTH_GENERATE_QR_CODE_FAILURE = 'auth/AUTH_GENERATE_QR_CODE_FAILURE';

export const AUTH_VALIDATE_AUTHENTICATOR = 'auth/AUTH_VALIDATE_AUTHENTICATOR';
export const AUTH_VALIDATE_AUTHENTICATOR_SUCCESS = 'auth/AUTH_VALIDATE_AUTHENTICATOR_SUCCESS';
export const AUTH_VALIDATE_AUTHENTICATOR_FAILURE = 'auth/AUTH_VALIDATE_AUTHENTICATOR_FAILURE';

export const AUTH_MFA_REQUIRED = 'auth/AUTH_MFA_REQUIRED';
export const AUTH_MARK_MFA_REQUIRED = 'auth/AUTH_MARK_MFA_REQUIRED';

export const AUTH_VALIDATE_MFA_CODE = 'auth/AUTH_VALIDATE_MFA_CODE';
export const AUTH_VALIDATE_MFA_CODE_SUCCESS = 'auth/AUTH_VALIDATE_MFA_CODE_SUCCESS';
export const AUTH_VALIDATE_MFA_CODE_FAILURE = 'auth/AUTH_VALIDATE_MFA_CODE_FAILURE';

export const AUTH_DISABLE_MFA_REQUEST = 'auth/AUTH_DISABLE_MFA_REQUEST';
export const AUTH_DISABLE_MFA_REQUEST_SUCCESS = 'auth/AUTH_DISABLE_MFA_REQUEST_SUCCESS';
export const AUTH_DISABLE_MFA_REQUEST_FAILURE = 'auth/AUTH_DISABLE_MFA_REQUEST_FAILURE';


export const AUTH_REQUEST_PASSWORD_RESET = 'auth/AUTH_REQUEST_PASSWORD_RESET';
export const AUTH_REQUEST_PASSWORD_RESET_SUCCESS = 'auth/AUTH_REQUEST_PASSWORD_RESET_SUCCESS';
export const AUTH_REQUEST_PASSWORD_RESET_FAILURE = 'auth/AUTH_REQUEST_PASSWORD_RESET_FAILURE';

export const AUTH_LOGIN_BACKEND_REQUEST = 'auth/AUTH_LOGIN_BACKEND_REQUEST';
export const AUTH_LOGIN_BACKEND_REQUEST_SUCCESS = 'auth/AUTH_LOGIN_BACKEND_REQUEST_SUCCESS';
export const AUTH_LOGIN_BACKEND_REQUEST_FAILURE = 'auth/AUTH_LOGIN_BACKEND_REQUEST_FAILURE';


export interface RegistrationPasswordResetRequired {
  type: typeof REGISTRATION_PASSWORD_RESET_REQUIRED
}

export interface RegistrationPasswordResetComplete {
  type: typeof REGISTRATION_PASSWORD_RESET_COMPLETE
}
export interface RegistrationRequestAction {
  type: typeof REGISTRATION_REQUEST
  payload: {
    firstName: string,
    lastName: string,
    email: string,
  }
}
export interface DisableMFAAction {
  type: typeof AUTH_DISABLE_MFA_REQUEST_SUCCESS
}
export interface SetMFARequiredAction {
  type: typeof AUTH_MFA_REQUIRED,
  payload: CognitoUser
}

export interface GenerateQRCodeSuccess {
  type: typeof AUTH_GENERATE_QR_CODE_SUCCESS,
  payload: {
    qrCodeUrl: string,
    qrSecret: string,
  }
}
export interface UpdateTokens {
  type: typeof AUTH_UPDATE_TOKENS;
  payload: {
      idToken: string,
      refreshToken: string,
      accessToken: string,
      tokenExpiration: number,
  }
}
export interface RegistrationSuccessAction {
    type: typeof REGISTRATION_SUCCESS;
    payload: {
        username: string,
        email: string,
        userId: number,
    }
}

export interface AuthLoginRequestAction {
  type: typeof AUTH_LOGIN_REQUEST;
}
export interface AuthLoginSuccessAction {
  type: typeof AUTH_LOGIN_SUCCESS;
  payload: {
    email: string;
    userId: number;
    emailVerified: boolean;
  };
}
export interface AuthLoginFailureAction {
  type: typeof AUTH_LOGIN_FAILURE;
  payload: StatusMessage;
}
export interface AuthLogoutAction {
  type: typeof AUTH_LOGOUT;
}

export interface GetUserRequestAction {
  type: typeof AUTH_GETUSER_REQUEST;
  payload: {
    id: number;
  }
}
export interface GetUserSuccessAction {
  type: typeof AUTH_GETUSER_SUCCESS;
  payload: {
    id: number;
    email: string;
    username: string;
    flags: string;
    registration_step: number;
  }
}
export interface GetUserFailureAction {
  type: typeof AUTH_GETUSER_FAILURE;
  payload: StatusMessage;
}

export interface VerifyEmailSuccessAction {
  type: typeof AUTH_VERIFY_EMAIL_SUCCESS;
}


export type AuthAction = AuthLoginRequestAction | AuthLoginSuccessAction | AuthLoginFailureAction | AuthLogoutAction | UpdateTokens |
  GetUserRequestAction | GetUserSuccessAction | GetUserFailureAction | VerifyEmailSuccessAction | RegistrationSuccessAction | GenerateQRCodeSuccess
  | SetMFARequiredAction | DisableMFAAction | RegistrationRequestAction | RegistrationPasswordResetRequired | RegistrationPasswordResetComplete

// reducers

export interface AuthReducerState {
  pending: boolean;
  username?: string;
  email?: string;
  flags?: string;
  userId: number;
  token?: string;
  accessToken?: string;
  tokenExpiration?: number;
  refreshToken?: string;
  idToken?: string;
  errorMessage?: string;
  googleAuthExists: boolean;
  registration_step: number;
  emailVerified: boolean;
  qrCodeUrl?: string;
  qrSecret?: string;
  MFARequired: boolean;
  cognitoUser?: CognitoUser;
  firstName: string;
  lastName: string;
  passwordReset: boolean;
}
