/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Tag,
  Button,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarStar,
  faClock,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faCalendarWeek,
  faSun,
  faFileUser,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';


import './TodayPage.scss';
import '../DemoPages.scss';
import { getBriefings, getPriorityTags } from '../../redux/modules/briefings/selectors';
import { Briefing, BriefingItem, BriefingItemTag } from '../../redux/modules/briefings/schemas';
import { markAsRead } from '../../redux/modules/briefings/actions';
import { sourceLogos } from '../../utils/logoSources';
import { getConnectedSources } from '../../redux/modules/appIntegrations/selectors';
import { ConnectedSource } from '../../redux/modules/appIntegrations/schemas';

export type BriefingType = 'DailyBriefing' | 'WeekInReview' | 'MonthlyTeamRetro';

const TodayPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const briefings = useSelector(getBriefings);
  const priorityTags = useSelector(getPriorityTags);
  const sources = useSelector(getConnectedSources);
  const getDayStr = (dateStr: string) => {
    const date = dayjs(dateStr);
    const today = dayjs();
    if (date.isSame(today, 'day')) {
      return 'Today';
    }
    if (date.isSame(today.subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    }
    return date.format('dddd');
  };

  const getGreeting = () => {
    const today = dayjs(); // Get the current date and time
    const hour = today.hour(); // Extract the hour from the current time

    // Determine the greeting based on the hour of the day
    if (hour < 12) {
      return 'Good Morning!';
    } if (hour >= 12 && hour < 18) {
      return 'Good Afternoon!';
    }
    return 'Good Evening!';
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.reload();
    }, 30 * 60 * 1000);
    return () => clearTimeout(timeout);
  }, []);



  const getLatestBriefings = () => {

    const dailyBriefings = briefings
      .filter((briefing) => briefing.briefingType === 'DailyBriefing')
      .sort((a, b) => b.briefingDateTime.localeCompare(a.briefingDateTime))
      .slice(0, 4);

    // const weekInReview = briefingsData
    //   .filter((briefing) => briefing.briefingType === 'WeekInReview')
    //   .sort((a, b) => b.briefingDateTime.localeCompare(a.briefingDateTime))[0];

    // const monthlyTeamsRetro = briefingsData
    //   .filter((briefing) => briefing.briefingType === 'MonthlyTeamRetro')
    //   .sort((a, b) => b.briefingDateTime.localeCompare(a.briefingDateTime))[0];

    return [...dailyBriefings];
  };

  const getTitle = (briefing: Briefing) => {
    switch (briefing.briefingType) {
      case 'DailyBriefing':
        return getDayStr(briefing.briefingDateTime);
      case 'WeekInReview':
        return 'Last Week';
      case 'MonthlyTeamRetro':
        return 'Last Month';
      default:
        return 'Unknown Briefing Type'; // Fallback for unrecognized types
    }
  };

  const getCardTypeBadge = (type: string) => {
    switch (type) {
      case 'DailyBriefing':
        return (
          <h4 className="card-type color-orange">
            <FontAwesomeIcon icon={faSun} />
            Daily Briefing
          </h4>
        );
      case 'WeekInReview':
        return (
          <h4 className="card-type color-red">
            <FontAwesomeIcon icon={faCalendarWeek} />
            Weekly Review
          </h4>
        );
      case 'MonthlyTeamRetro':
        return (
          <h4 className="card-type color-blue">
            <FontAwesomeIcon icon={faFileUser} />
            Teams Retro
          </h4>
        );
      default:
        return 'Unknown Briefing Type'; // Fallback for unrecognized types
    }
  };

  const isPriorityTag = (name: string): boolean => priorityTags.some((tag: BriefingItemTag) => tag.tag_name === name);

  const getRandomTags = (briefingItems: BriefingItem[], numberOfTags: number) => {
    const importantTagNames = new Set();
    const otherTagNames = new Set();
    const tagMap = new Map();

    // Collect all important tags and other tags separately, by name
    briefingItems.forEach((briefingItem: BriefingItem) => {
      briefingItem.tags.forEach((tag) => {
        tagMap.set(tag.tag_name, tag); // Map tag names to tag objects for later retrieval
        if (isPriorityTag(tag.tag_name)) {
          importantTagNames.add(tag.tag_name);
        } else {
          otherTagNames.add(tag.tag_name);
        }
      });
    });

    const selectedTagNames = new Set([...importantTagNames]); // Start with all important tag names

    // Fill the remaining slots with non-important tag names, if necessary
    while (selectedTagNames.size < numberOfTags && otherTagNames.size > 0) {
      const nonImportantTagsArray = Array.from(otherTagNames);
      const randomIndex = Math.floor(Math.random() * nonImportantTagsArray.length);
      selectedTagNames.add(nonImportantTagsArray[randomIndex]);
      otherTagNames.delete(nonImportantTagsArray[randomIndex]); // Remove the selected tag name to avoid repetition
    }

    // Convert the set of selected tag names back to tag objects
    return Array.from(selectedTagNames).map((tagName) => tagMap.get(tagName));
  };

  const latestBriefings = getLatestBriefings();
  const mostRecentBriefing = latestBriefings[0] || null; // Most recent briefing for the large card
  const recentBriefings = latestBriefings.slice(1) || null; // Exclude the most recent from the "recent briefings"

  const handleAddSourceClick = () => {
    console.log('Add Source Clicked');
    history.push('/settings');
  };

  const handleBriefingClick = (e: React.MouseEvent<HTMLDivElement>, briefing: Briefing) => {
    e.preventDefault();

    if (briefing.isUnread) {
      dispatch(markAsRead(briefing.briefingId));
    }

    history.push({
      pathname: `/briefing/${briefing.briefingId}`,
    });
  };

  const todaysBriefings = (
    <div className="latest-briefing">
      {latestBriefings.length > 0 && (
      <div
        className="briefing-link"
        onClick={(e) => handleBriefingClick(e, mostRecentBriefing)}
      >
        <div className="card card-large">
          <header>
            {getCardTypeBadge(mostRecentBriefing.briefingType)}
            <h1>{getTitle(mostRecentBriefing)}</h1>
            <p>{mostRecentBriefing.briefingTitle}</p>
            <div className="card-tag-list">
              {getRandomTags(mostRecentBriefing.briefingItems, 3).map((t: BriefingItemTag) => (
                <Tag key={t.id} className="tag">
                  { isPriorityTag(t.tag_name) && <span className="important-tag" />}
                  {t.tag_name}
                </Tag>
              ))}
            </div>
          </header>
          <footer>
            <p className="item-summary">{mostRecentBriefing.briefingSummary}</p>
            <div className="fade">
              Read the Full Briefing
            </div>
          </footer>
        </div>
      </div>
      )}
    </div>
  );

  const recentBriefingsSection = (
    <div className="recent">
      <h1>
        Recent Briefings
      </h1>
      <div className="cards">
        <div className="card-track">
          {
            recentBriefings.map((briefing: Briefing) => (
              <div
                key={briefing.briefingTitle}
                className="briefing-link"
                onClick={(e) => handleBriefingClick(e, briefing)}
              >
                <div className="card">
                  <header>
                    {getCardTypeBadge(briefing.briefingType)}
                    <h1>{getTitle(briefing)}</h1>
                    <p>{briefing.briefingTitle}</p>
                    <div className="tag-list-compact">
                      {getRandomTags(briefing.briefingItems, 1).map((t: BriefingItemTag) => (
                        <Tag key={t.id} className="tag">
                          { isPriorityTag(t.tag_name) && <span className="important-tag" />}
                          {t.tag_name}
                        </Tag>
                      ))}
                    </div>
                  </header>
                  <footer>
                    <p className="item-summary">{briefing.briefingSummary}</p>
                    <div className="fade">
                      Read the Full Briefing
                    </div>
                  </footer>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );

  const sourcesSection = (
    <div className="sources">
      <h3>Connected Sources</h3>
      <div className="sources-list">
        {
          sources.map((source: ConnectedSource) => {
            if (!source.isComingSoon && source.isConnected) {
              return sourceLogos[source.name];
            }
            return null;
          })
        }
        <Button onClick={() => handleAddSourceClick()} type="text" icon={<FontAwesomeIcon icon={faPlus} />} />
      </div>
    </div>
  );

  const welcomeMessage = 'Here\'s your latest briefing of everything happening in your organization.';
  const emptyMessage = 'We\'re processing the data you linked and generating insights based on your preferences.';

  const emptyCard = (
    <div className="latest-briefing">
      <div className="card card-large card-blank">
        <h1>Indexing Your Data</h1>
        <p>This might take a while. 😅 We&lsquo;ll email you when everything is done</p>
        <p>
          <FontAwesomeIcon icon={faClock} />
          Estimated Wait: 20 Minutes
        </p>
        <div className="bars bars-blink" />
        <div className="bars bars-blink" />
        <div className="bars bars-blink bars-sep" />
        <div className="bars bars-blink" />
        <div className="bars bars-blink" />
        <div className="bars bars-blink" />
      </div>
    </div>
  );

  return (
    <div className="today-page">
      <div className="today">
        <header>
          <h1>
            <FontAwesomeIcon className="orange" icon={faCalendarStar} />
            Today
          </h1>
        </header>
        <main>
          <div className="welcome">
            <div className="greeting">
              <h1>
                {getGreeting()}
              </h1>
              <p>
                {mostRecentBriefing ? welcomeMessage : emptyMessage}
              </p>
            </div>
            <div className="connections">
              {sourcesSection}
            </div>
          </div>
          {mostRecentBriefing ? todaysBriefings : emptyCard}
        </main>
      </div>
      {recentBriefings.length > 0 && recentBriefingsSection}
    </div>
  );
};

export default TodayPage;
