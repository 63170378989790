import { StatusMessage } from '../common/types';

export const GET_GITHUB_OAUTH_REQUEST = 'github/GET_GITHUB_OAUTH_REQUEST';
export const GET_GITHUB_OAUTH_URL_SUCCESS = 'github/GET_GITHUB_OAUTH_URL_SUCCESS';
export const GET_GITHUB_OAUTH_FAILURE = 'github/GET_GITHUB_OAUTH_FAILURE';
export const GET_GITHUB_OAUTH_SUCCESS = 'github/GET_GITHUB_OAUTH_SUCCESS';
export const RESET_GITHUB_OAUTH_SUCCESS = 'github/RESET_GITHUB_OAUTH_SUCCESS';
export const RESET_GITHUB_OAUTH = 'github/RESET_GITHUB_OAUTH';
export const GITHUB_LOGOUT = 'github/LOGOUT';

export interface GetGithubOauthUrlRequestAction {
    type: typeof GET_GITHUB_OAUTH_REQUEST;
  }

export interface GetGithubOauthUrlSuccessAction {
    type: typeof GET_GITHUB_OAUTH_URL_SUCCESS;
    payload: {
      url: string;
      state: string;
    };
  }

export interface GetGithubOauthUrlFailureAction {
    type: typeof GET_GITHUB_OAUTH_FAILURE;
    payload: StatusMessage;
  }

export interface GetGithubOauthSuccessAction {
    type: typeof GET_GITHUB_OAUTH_SUCCESS;
    payload: {
        oauthStatusResponse: boolean;
        githubOauthStatus: boolean;
    }
}

export interface ResetGithubOathSuccessAction {
  type: typeof RESET_GITHUB_OAUTH_SUCCESS;
}

export interface ResetGithubOauthState {
  type: typeof RESET_GITHUB_OAUTH;
}

export interface GithubLogout {
  type: typeof GITHUB_LOGOUT
}

export type GithubAction = ResetGithubOathSuccessAction | GithubLogout | ResetGithubOauthState | GetGithubOauthSuccessAction | GetGithubOauthUrlRequestAction | GetGithubOauthUrlSuccessAction | GetGithubOauthUrlFailureAction

export interface GithubReducer {
    githubOauthUrl: string;
    oauthState: string;
    githubOauthStatus: boolean;
    oauthStatusResponse: boolean;
}
