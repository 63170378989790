import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  // message,
  Steps,
} from 'antd';

import SignUpTopics from './Steps/SignUpTopics/SignUpTopics';
import SignUpSources from './Steps/SignUpSources/SignUpSources';
import SignUpAbout from './Steps/SignUpAbout/SignUpAbout';

import './SignUpSteps.scss';
import { getRegistrationStep } from '../../redux/modules/auth/selectors';

const SignUpSteps: React.FC = () => {
  const registrationStep = useSelector(getRegistrationStep);
  const [current, setCurrent] = useState(registrationStep - 1);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: 'Topics',
      content: <SignUpTopics isFirst next={next} prev={prev} step={current} />,
    },
    {
      title: 'Sources',
      content: <SignUpSources next={next} prev={prev} step={current} />,
    },
    {
      title: 'About You',
      content: <SignUpAbout isLast prev={prev} />,
    },
  ];


  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className="steps-page-container">
      <div className="steps-container">
        <Steps size="default" current={current} items={items} />
      </div>
      <div className="signup-step-container">
        {steps[current].content}
      </div>
    </div>
  );
};

export default SignUpSteps;
