/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Tag } from 'antd';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { sourceLogos } from '../../../utils/logoSources';
import { Mention, Mentions } from '../../../redux/modules/mentions/schemas';

import defaultSlackIcon from '../../../icons/default_slack_user_icon.png';
import './BriefingMentions.scss';

dayjs.extend(utc);
dayjs.extend(timezone);
interface BriefingMentionsProps {
  selectItem: (id: string, type: string)=> void,
  mentions: Mentions,
}

const BriefingMentions: React.FC<BriefingMentionsProps> = ({ selectItem, mentions }) => {
  const renderChannelName = (mentionItem: Mention) => {
    const { channel_type: channelType, channel_name: channelName } = mentionItem;
    switch (channelType) {
      case 'channel':
      case 'group':
        return `#${channelName}`;
      case 'im':
        return 'DM';
      case 'mpim':
        return 'Group DM';
      default:
        return `#${channelName}` || 'Unknown';
    }
  };

  return (
    <div>
      <h2>📣 Open Mentions</h2>
      {
        mentions.map((mention) => {
          const senderIcon = mention.message.sender_icon;
          const displayIcon = senderIcon && senderIcon.trim() !== '' ? senderIcon : defaultSlackIcon;
          return (
            <div className="briefing-mention" key={mention.id} onClick={() => selectItem(mention.id, 'mention')}>
              <div className="briefing-mention-message">
                <img
                  src={displayIcon}
                  alt="avatar"
                  className="mention-avatar"
                />
                <span className="mention-sender">{mention.message.sender_name}</span>
                <span className="mention-text">{mention.message.body}</span>
                <Tag className="tag">
                  <span>
                    {sourceLogos[mention.app_source]}
                  </span>
                  {' '}
                  <span>
                    {renderChannelName(mention)}
                  </span>
                </Tag>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default BriefingMentions;
