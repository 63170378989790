const topicEmojis: { [key: string]: string } = {
  Customers: '❤️ Customers',
  Product: '🚀 Product',
  Team: '🙌 Team',
  Marketing: '🤩 Marketing',
  Engineering: '🏗️ Engineering',
  Partnerships: '🤝 Partnerships',
  Sales: '📈 Sales',
  Operations: '📋 Operations',
  'R&D': '🧪 R&D',
  Competition: '🤺 Competition',
  Finance: '💵 Finance',
  Legal: '⚖️ Legal',
};

const mapTopicToEmojiTopic = (topic: string) => topicEmojis[topic] || topic;

export default mapTopicToEmojiTopic;

/// how do I use this object to map over the keys and values to render the emojis in the TopicsTipsCard component?
// e.g, for a topic 'Product', how do I find the emoji '🚀 Product'?

