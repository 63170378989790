import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button, Form, Input,
} from 'antd';
import { Link } from 'react-router-dom';

import { actions } from '../../redux/modules/auth';

import logo from '../../icons/maestro-logo.png';

import './LoginPage.scss';


const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(actions.submitLogin(email, password));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="preauth-page-container">
      <div className="preuath-form-container">
        <div className="registration-form-content-wrapper">
          <img src={logo} alt="maestro ai logo" className="maestro-logo" />
          <Form
            name="registration form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            className="registration-form"
          >
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: 'email', message: 'Not a valid email address', validateTrigger: 'onSubmit' },
                { required: true, message: 'Email address is required', validateTrigger: 'onSubmit' },
              ]}
            >
              <Input
                className="registration-form-input"
                placeholder="name@work-email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password "
              rules={[
                { required: true, message: 'Please enter a password!', validateTrigger: 'onSubmit' },
              ]}
            >
              <Input.Password
                className="registration-form-input registration-form-password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <div className="forgot-password-options">
              <div className="forgot-password">
                <Link to="/forgotPassword">Forgot password?</Link>
              </div>
              <div className="forgot-password-code">
                <Link to="/forgotPassword/code">Already have a code?</Link>
              </div>
            </div>
            <Form.Item shouldUpdate>
              <Button type="primary" htmlType="submit">
                Log In
              </Button>
            </Form.Item>
          </Form>
          <div className="login-options">
            <div className="registration-login">
              Don&lsquo;t have an account?
              {' '}
              <Link to="/signUp">
                <b>Sign Up</b>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
