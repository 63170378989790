/* eslint-disable import/prefer-default-export */
import { put } from '../../common/rest';
import { AppThunk } from '../../store';
import { ReactionType, Identifier } from './schemas';

import * as types from './types';


// export const submitUserReaction = (reaction: ReactionType, identifier: Identifier): AppThunk => async (dispatch) => {
//   dispatch({
//     type: types.FEEDBACK_SUBMIT_REACTION,
//   });

//   try {
//     await put<{ reaction: ReactionType, identifier: Identifier }, void>(null, '/api/v1.0/feedback/reaction', { reaction, identifier });
//     dispatch({
//       type: types.FEEDBACK_SUBMIT_REACTION_SUCCESS,
//     });

//   } catch (err: any) {
//     dispatch({
//       type: types.FEEDBACK_SUBMIT_REACTION_ERROR,
//     });
//   }
// };

export const submitUserFeedback = (reaction: ReactionType, feedback: string, identifier: Identifier): AppThunk => async (dispatch) => {
  dispatch({
    type: types.FEEDBACK_SUBMIT_FEEDBACK,
  });
  try {
    await put<{ reaction: ReactionType, feedback: string, identifier: Identifier }, void>(null, '/api/v1.0/feedback/feedback', { reaction, feedback, identifier });
    dispatch({
      type: types.FEEDBACK_SUBMIT_FEEDBACK_SUCCESS,
    });

  } catch (err: any) {
    dispatch({
      type: types.FEEDBACK_SUBMIT_FEEDBACK_ERROR,
    });
  }
};
