import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';

import logo from '../../../icons/maestro-logo.png';
import { getUserEmail } from '../../../redux/modules/auth/selectors';
import { verifyEmail, resendEmailVerificationCode, logout } from '../../../redux/modules/auth/actions';

interface FormValues {
  verificationCode: string;
  email: string;
}

const VerifyEmailForm = () => {
  const dispatch = useDispatch();
  const userEmail = useSelector(getUserEmail);

  const onFinish = (values: FormValues) => {
    const { verificationCode } = values;
    if (userEmail) {
      dispatch(verifyEmail(userEmail, verificationCode));
    }
  };

  const onResendCode = () => {
    if (userEmail) dispatch(resendEmailVerificationCode(userEmail));
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="preauth-page-container">
      <div className="preuath-form-container">
        <div className="registration-form-content-wrapper">
          <img src={logo} alt="maestro ai logo" className="maestro-logo" />
          <Form
            name="verification form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            className="verification-form"
          >
            <div className="preauth-input-container">
              <Form.Item
                className="verification-form-item"
                name="verificationCode"
                rules={[
                  { required: true, message: 'Verification code is required', validateTrigger: 'onSubmit' },
                ]}
              >
                <Input
                  className="password-form-input"
                  placeholder="Enter your verification code"
                />
              </Form.Item>
            </div>
            <Form.Item shouldUpdate>
              <Button
                type="primary"
                htmlType="submit"
              >
                Verify Email
              </Button>
            </Form.Item>
          </Form>
          <div className="secondary-action-container">
            <Button
              type="link"
              onClick={() => {
                onResendCode();
              }}
            >
              Resend Code
            </Button>
            <Button
              type="link"
              onClick={() => {
                onLogout();
              }}
            >
              Use a different email address
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailForm;
