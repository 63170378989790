/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from '../Header/Header';
import ConnectedSidebar from '../ConnectedSidebar/ConnectedSidebar';

import Settings from '../Settings/Settings';

import Logo from '../../icons/workspace-icon.png';
import { selectors } from '../../redux/modules/auth';

import './Main.scss';
import Admin from '../Admin/Admin';
import Chat from '../Chat/Chat';
import PromptSandbox from '../PromptSandbox/PromptSandbox';
import TodayPage from '../TodayPage/TodayPage';
import InboxView from '../InboxView/InboxView';
import ReportsPage from '../ReportsPage/ReportsPage';
import ReportDetailLoader from '../ReportDetailPage/ReportDetailLoader';
import AdminBriefingViewer from '../BriefingViewer/AdminBriefingViewer';


const Main: React.FC = () => {
  const userEmail = useSelector(selectors.getUserEmail);

  return (
    <div className="main-window">
      <div className="main-sidebar">
        <div className="main-workspace">
          <img className="logo" src={Logo} alt="Maestro AI" />
          <div>
            <h1>Maestro AI</h1>
            <p>{userEmail}</p>
          </div>
        </div>
        <ConnectedSidebar />
        <Header />
      </div>
      <div className="main-content">
        <Switch>
          <Route exact path="/today" component={TodayPage} />
          <Route path="/archive" component={InboxView} />
          <Route path="/settings" component={Settings} />
          <Route path="/reports" component={ReportsPage} />
          <Route path="/briefing/:briefingId" component={ReportDetailLoader} />
          <Route path="/mention/:mention" component={ReportDetailLoader} />
          <Route path="/chat" component={Chat} />
          <Route path="/promptSandbox" component={PromptSandbox} />
          <Route path="/admin" component={Admin} />
          <Route path="/briefingViewer" component={AdminBriefingViewer} />
          <Route path="/" component={TodayPage} />
        </Switch>
      </div>
    </div>
  );
};

export default Main;
