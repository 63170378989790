/* eslint-disable import/prefer-default-export */
import { get } from '../../common/rest';
import { AppThunk } from '../../store';
import * as types from './types';
import * as schemas from './schemas';

export const getMentions = (): AppThunk => async (dispatch) => {
  dispatch({
    type: types.GET_MENTIONS_LOADING,
    payload: {
      loading: true,
    },
  });

  try {
    const response = await get<undefined, schemas.Mentions>(schemas.vMentions, '/api/v1.0/mentions/mentions');
    dispatch({
      type: types.GET_MENTIONS_SUCCESS,
      payload: {
        mentions: response,
        loading: false,
      },
    });

  } catch (err: any) {
    console.error('Failed to retrieve mentions', err);
    dispatch({
      type: types.GET_MENTIONS_FAILURE,
      payload: { loading: false },
    });
  }
};
