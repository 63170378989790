import * as types from './types';

const defaultState: types.MentionsReducer = {
  mentions: [],
  loading: true,
};

export default function mentionsReducer(state = defaultState, action: types.MentionsAction): types.MentionsReducer {
  switch (action.type) {
    case types.GET_MENTIONS_SUCCESS:
      return {
        ...state,
        mentions: action.payload.mentions,
        loading: action.payload.loading,
      };

    case types.GET_MENTIONS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case types.GET_MENTIONS_FAILURE:
      return {
        ...state,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}
