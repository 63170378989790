import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getBriefings } from '../../redux/modules/briefings/actions';
import { getBriefingLoading, getBriefingById } from '../../redux/modules/briefings/selectors';
import ReportDetailPage from './ReportDetailPage';
import { RootReducerState } from '../../redux/store';

interface RouteParams {
    briefingId: string;
  }

const ReportDetailLoader: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { briefingId } = useParams<RouteParams>();
  const isLoading = useSelector(getBriefingLoading);
  const briefing = useSelector((state: RootReducerState) => getBriefingById(state, briefingId));

  useEffect(() => {
    if (!briefing) {
      dispatch(getBriefings()); // Adjust if your action needs to fetch a specific briefing
    }
  }, [briefingId, dispatch]);

  useEffect(() => {
    if (!isLoading && !briefing) {
      history.push('/today');
    }
  }, [isLoading, briefing, history]);

  if (isLoading || !briefing) {
    return <div>Loading...</div>;
  }


  return <ReportDetailPage briefing={briefing} />;
};

export default ReportDetailLoader;
