/* eslint-disable react/jsx-fragments */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Form, Input, Button, Typography,
} from 'antd';
// import LogoHeader from '../LogoHeader/LogoHeader';
// import AppToaster from '../../toaster';

const { Paragraph } = Typography;


type PasswordResetRequestProps = {
  onSubmitRequest: (email: string) => void
}

const PasswordResetRequest: React.FC<PasswordResetRequestProps> = ({ onSubmitRequest }) => {
  const [email, setEmail] = useState('');


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    onSubmitRequest(email);
  };

  return (
    <>
      <h1>Reset Your Password</h1>
      <Form className="forgot-password-container" onFinish={handleSubmit}>
        <Form.Item>
          <Input
            className="password-form-input"
            id="email"
            onChange={handleChange}
            value={email}
            placeholder="Enter your email"
          />
        </Form.Item>
        <Paragraph className="forgot-password-infotext">
          We’ll email you a Password Reset Code.
        </Paragraph>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="forgot-password-button">
            Send
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PasswordResetRequest;
