import { StatusMessage } from '../common/types';
import { Page, PageUser } from './schemas';

export const TAG_PAGE_PREFIX = 'TAG_';

export type { Page, PageUser } from './schemas';

export interface PageDetail {
    id: string;
    index: string;
    title: string;
    description: string;
    createdDate: string;
    creatorId: number;
    orderedBlocks: string[];
    pinnedBlocks: string[];
    liveQuery: string;
    tagmeta: string;
    tutorial: boolean;
    users: PageUser[];
    updatedDate: string;
}

export const OPEN_PAGE_REQUEST = 'page/OPEN_PAGE_REQUEST';
export const OPEN_PAGE_SUCCESS = 'page/OPEN_PAGE_SUCCESS';
export const OPEN_PAGE_FAILURE = 'page/OPEN_PAGE_FAILURE';

export const GET_USER_PAGES_REQUEST = 'page/GET_USER_PAGES_REQUEST';
export const GET_USER_PAGES_SUCCESS = 'page/GET_USER_PAGES_SUCCESS';
export const GET_USER_PAGES_FAILURE = 'page/GET_USER_PAGES_FAILURE';

export const GET_USER_FAVORITE_PAGES_REQUEST = 'space/GET_USER_FAVORITE_PAGES_REQUEST';
export const GET_USER_FAVORITE_PAGES_SUCCESS = 'space/GET_USER_FAVORITE_PAGES_SUCCESS';
export const GET_USER_FAVORITE_PAGES_FAILURE = 'space/GET_USER_FAVORITE_PAGES_FAILURE';

export const CREATE_PAGE_REQUEST = 'page/CREATE_PAGE_REQUEST';
export const CREATE_PAGE_SUCCESS = 'page/CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAILURE = 'page/CREATE_PAGE_FAILURE';

export const ADD_USER_REQUEST = 'page/ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'page/ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'page/ADD_USER_FAILURE';

export const REMOVE_USER_REQUEST = 'page/REMOVE_USER_REQUEST';
export const REMOVE_USER_SUCCESS = 'page/REMOVE_USER_SUCCESS';
export const REMOVE_USER_FAILURE = 'page/REMOVE_USER_FAILURE';

export const CREATE_PAGE_BLOCK = 'page/CREATE_PAGE_BLOCK';
export const REMOVE_PAGE_BLOCK = 'page/REMOVE_PAGE_BLOCK';

export const UPDATE_PAGE_REQUEST = 'page/UPDATE_PAGE_REQUEST';
export const UPDATE_PAGE_SUCCESS = 'page/UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_FAILURE = 'page/UPDATE_PAGE_FAILURE';

export const REMOVE_PAGE_TUTORIAL = 'page/REMOVE_PAGE_TUTORIAL';

export const UPDATE_PAGE_DATE = 'page/UPDATE_PAGE_DATE';

export const UPDATE_PIN_REQUEST = 'page/UPDATE_PIN_REQUEST';
export const UPDATE_PIN_SUCCESS = 'page/UPDATE_PIN_SUCCESS';
export const UPDATE_PIN_FAILURE = 'page/UPDATE_PIN_FAILURE';

export const DELETE_PAGE_REQUEST = 'page/DELETE_PAGE_REQUEST';
export const DELETE_PAGE_SUCCESS = 'page/DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILURE = 'page/DELETE_PAGE_FAILURE';

export const ADD_FAVORITE_PAGE_REQUEST = 'space/ADD_FAVORITE_PAGE_REQUEST';
export const ADD_FAVORITE_PAGE_SUCCESS = 'space/ADD_FAVORITE_PAGE_SUCCESS';
export const ADD_FAVORITE_PAGE_FAILURE = 'space/ADD_FAVORITE_PAGE_FAILURE';

export const DELETE_FAVORITE_PAGE_REQUEST = 'space/DELETE_FAVORITE_PAGE_REQUEST';
export const DELETE_FAVORITE_PAGE_SUCCESS = 'space/DELETE_FAVORITE_PAGE_SUCCESS';
export const DELETE_FAVORITE_PAGE_FAILURE = 'space/DELETE_FAVORITE_PAGE_FAILURE';

export const SET_PENDING_TAG_PAGE = 'page/SET_PENDING_TAG_PAGE';
export const RESET_PENDING_TAG_PAGE = 'page/RESET_PENDING_TAG_PAGE';

export interface AddUserRequest {
    email: string;
}

export interface CreatePageRequest {
    title: string;
    description: string;
    tagmeta?: string;

    //eslint-disable-next-line camelcase
    live_query: string;
    tutorial: boolean;
}

export interface UpdatePageRequest {
    title: string;
    description: string;

    //eslint-disable-next-line camelcase
    live_query: string;
}

export interface RemoveTutorialRequest {
    tutorial: boolean;
}

export interface UpdatePinRequest {
    //eslint-disable-next-line camelcase
    pinned_blocks: string;
}

export interface OpenPageRequest {
    type: typeof OPEN_PAGE_REQUEST;
    payload: {
        pageId: string;
    }
}

export interface OpenPageSuccessAction {
    type: typeof OPEN_PAGE_SUCCESS;
    payload: {
        pageId: string;
        pageDetail: PageDetail;
    }
}

export interface OpenPageFailureAction {
    type: typeof OPEN_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface DeletePageRequest {
    type: typeof DELETE_PAGE_REQUEST;
    payload: {
        pageId: string;
    }
}

export interface DeletePageSuccessAction {
    type: typeof DELETE_PAGE_SUCCESS;
    payload: {
        pageId: string;
    }
}

export interface DeletePageFailureAction {
    type: typeof DELETE_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface UpdatePageRequestAction {
    type: typeof UPDATE_PAGE_REQUEST;
    payload: {
        pageId: string;
    }
}

export interface UpdatePageSuccessAction {
    type: typeof UPDATE_PAGE_SUCCESS;
    payload: {
        pageId: string;
        title: string;
        description: string;
        query: string;
    }
}

export interface UpdatePageFailureAction {
    type: typeof UPDATE_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface RemovePageTutorialAction {
    type: typeof REMOVE_PAGE_TUTORIAL;
    payload: {
        pageId: string;
    }
}

export interface UpdatePageDateAction {
    type: typeof UPDATE_PAGE_DATE;
    payload: {
        pageId: string;
        date: string;
    }
}

export interface UpdatePinRequestAction {
    type: typeof UPDATE_PIN_REQUEST;
    payload: {
        pageId: string;
    }
}

export interface UpdatePinSuccessAction {
    type: typeof UPDATE_PIN_SUCCESS;
    payload: {
        pageId: string;
        pinnedBlocks: string[];
    }
}

export interface UpdatePinFailureAction {
    type: typeof UPDATE_PIN_FAILURE;
    payload: StatusMessage;
}

export interface GetUserPagesSuccessAction {
    type: typeof GET_USER_PAGES_SUCCESS;
    payload: {
        pages: Page[];
    }
}

export interface GetUserFavoritePagesSuccessAction {
    type: typeof GET_USER_FAVORITE_PAGES_SUCCESS;
    payload: {
        pages: Page[];
    }
}

export interface GetUserFavoritePagesFailureAction {
    type: typeof GET_USER_FAVORITE_PAGES_FAILURE;
    payload: StatusMessage;
}

export interface AddFavoritePageSuccessAction {
    type: typeof ADD_FAVORITE_PAGE_SUCCESS;
    payload: {
        page: Page;
    }
}

export interface DeleteFavoritePageSuccessAction {
    type: typeof DELETE_FAVORITE_PAGE_SUCCESS;
    payload: {
        page: Page;
    }
}

export interface AddFavoritePageFailureAction {
    type: typeof ADD_FAVORITE_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface DeleteFavoritePageFailureAction {
    type: typeof DELETE_FAVORITE_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface GetUserPagesFailureAction {
    type: typeof GET_USER_PAGES_FAILURE;
    payload: StatusMessage;
}

export interface CreatePageSuccessAction {
    type: typeof CREATE_PAGE_SUCCESS;
    payload: {
        page: Page;
    }
}

export interface CreatePageFailureAction {
    type: typeof CREATE_PAGE_FAILURE;
    payload: StatusMessage;
}

export interface AddUserSuccessAction {
    type: typeof ADD_USER_SUCCESS;
    payload: {
        page: Page;
    }
}

export interface AddUserFailureAction {
    type: typeof ADD_USER_FAILURE;
    payload: StatusMessage;
}

export interface RemoveUserSuccessAction {
    type: typeof REMOVE_USER_SUCCESS;
    payload: {
        page: Page;
        userId: number;
    }
}

export interface RemoveUserFailureAction {
    type: typeof REMOVE_USER_FAILURE;
    payload: StatusMessage;
}

export interface CreatePageBlockAction {
    type: typeof CREATE_PAGE_BLOCK;
    payload: {
        pageId: string;
        blockId: string;
    }
}

export interface RemovePageBlockAction {
    type: typeof REMOVE_PAGE_BLOCK;
    payload: {
        pageId: string;
        blockId: string;
    }
}

export interface SetPendingTagPageAction {
    type: typeof SET_PENDING_TAG_PAGE;
    payload: {
        tag: string;
    }
}

export interface ResetPendingTagPageAction {
    type: typeof RESET_PENDING_TAG_PAGE;
    payload: {
    }
}

export type PageAction = GetUserPagesFailureAction | GetUserPagesSuccessAction | CreatePageFailureAction |
    CreatePageSuccessAction | AddUserSuccessAction | AddUserFailureAction |
    OpenPageRequest | OpenPageSuccessAction | OpenPageFailureAction | CreatePageBlockAction |
    RemovePageBlockAction | DeletePageRequest | DeletePageSuccessAction | DeletePageFailureAction |
    UpdatePageRequestAction | UpdatePageSuccessAction | UpdatePageFailureAction |
    RemoveUserSuccessAction | RemoveUserFailureAction |
    UpdatePinRequestAction | UpdatePinSuccessAction | UpdatePinFailureAction |
    RemovePageTutorialAction | GetUserFavoritePagesSuccessAction | GetUserFavoritePagesFailureAction |
    AddFavoritePageSuccessAction | DeleteFavoritePageSuccessAction | AddFavoritePageFailureAction | DeleteFavoritePageFailureAction |
    RemovePageTutorialAction | SetPendingTagPageAction | ResetPendingTagPageAction |
    SetPendingTagPageAction | ResetPendingTagPageAction | UpdatePageDateAction;

export interface PageReducerState {
    pages: Page[],
    favoritePages: Page[],
    currentPageId: string;
    pending: boolean;
    pageDetailById: Map<string, PageDetail>;
    pendingTagPage: string;
}
