/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, Select, Input,
} from 'antd';

import './SignUpAbout.scss';
import { useDispatch } from 'react-redux';
import AboutTipsCard from '../../../TipsCards/AboutTipsCard/AboutTipsCard';
import { setUserProfile, updateRegistrationStep } from '../../../../redux/modules/registration/actions';
import { createBriefingSchedule, getUserSettings } from '../../../../redux/modules/briefings/actions';

type SignUpStepProps = {
  prev: () => void;
  isFirst?: boolean;
  isLast?: boolean;
};

const SignUpAbout: React.FC<SignUpStepProps> = ({
  prev, isFirst = false, isLast = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [role, setRole] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [otherRoleValue, setOtherRoleValue] = useState('');
  const [timezone, setTimezone] = useState<string>('');

  const onSelectChange = (value: string) => {
    setRole(value);

    if (value.toLowerCase() !== 'other') {
      setOtherRoleValue('');
    }
  };

  const onOtherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtherRoleValue(e.target.value);
  };

  const onCompanyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };

  const handleNextClick = () => {
    let userRole = role;
    if (role === 'Other') {
      userRole = otherRoleValue;
    }
    dispatch(setUserProfile(company, userRole));
    dispatch(updateRegistrationStep(0));
    dispatch(getUserSettings());
    dispatch(createBriefingSchedule(timezone));
    history.push('/');
  };

  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }, []);


  return (
    <div className="signup-step-layout">
      <div className="signup-step-content-wrapper">
        <div className="step-info">
          <h1>Tell Us a Little About You</h1>
          <p>We use information about your role and company to customize the information that gets bubbled up to your briefing.</p>
        </div>
        <div className="step-content">
          <label htmlFor="company-input">Company Name</label>
          <Input
            id="company-input"
            className="signup-input"
            size="large"
            value={company}
            onChange={onCompanyInputChange}
            placeholder="What's name of your organization?"
          />
          <label htmlFor="role-selector">Job Role</label>
          <Select
            id="role-selector"
            className="signup-selector"
            onChange={onSelectChange}
            value={role || undefined}
            size="large"
            placeholder="What's your job role?"
            options={[
              { value: 'CEO', label: 'CEO' },
              { value: 'CTO', label: 'CTO' },
              { value: 'VP of Engineering', label: 'VP of Engineering' },
              { value: 'Engineering Manager', label: 'Engineering Manager' },
              { value: 'Product Manager', label: 'Product Manager' },
              { value: 'Engineer', label: 'Engineer' },
              { value: 'Founder', label: 'Founder' },
              { value: 'Chief of Staff', label: 'Chief of Staff' },
              { value: 'Other', label: 'Other' },
            ]}
          />
          {role && role.toLowerCase() === 'other' && (
            <Input
              className="signup-input"
              size="large"
              value={otherRoleValue}
              onChange={onOtherInputChange}
              placeholder="Please specify your role..."
            />
          )}
        </div>
        <div className="step-control-buttons">
          { !isFirst && <Button onClick={() => prev()}>Back</Button> }
          { !isLast && <Button type="primary" onClick={() => handleNextClick()}>Next</Button>}
          { isLast && <Button type="primary" onClick={() => handleNextClick()}>Finish</Button>}
        </div>
      </div>
      <AboutTipsCard selectedRole={role} />
    </div>
  );
};

SignUpAbout.defaultProps = {
  isFirst: false,
  isLast: false,
};


export default SignUpAbout;
