/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';
import './SignUpSources.scss';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import SourceTipsCard from '../../../TipsCards/SourceTipsCard/SourceTipsCard';
import { updateRegistrationStep } from '../../../../redux/modules/registration/actions';
import { getConnectedSources } from '../../../../redux/modules/appIntegrations/selectors';
import { ConnectedSource } from '../../../../redux/modules/appIntegrations/schemas';
import ConnectSourceButton from '../../../Settings/ConnectSourceButton';



type SignUpStepProps = {
  next: () => void;
  prev: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  step: number;
};

const SignUpSources: React.FC<SignUpStepProps> = ({
  next, prev, step, isFirst = false, isLast = false,
}) => {
  const dispatch = useDispatch();
  const sources = useSelector(getConnectedSources);

  const connectedSources = sources.filter((source: ConnectedSource) => source.isConnected);

  const handleNextClick = () => {
    dispatch(updateRegistrationStep(step + 2));
    next();
  };

  return (
    <div className="signup-step-layout">
      <div className="signup-step-content-wrapper">
        <div className="step-info">
          <h1>Connect Maestro to Your Apps</h1>
          <p>We&lsquo;ll summarize all the important updates across your workstreams to help you stay on top of everything going on.</p>
        </div>
        <div className="step-content">
          <div className="signup-button-list">
            {sources.filter((source: ConnectedSource) => !source.isComingSoon).map((source: ConnectedSource) => (
              <ConnectSourceButton key={source.name} source={source} />
            ))}
          </div>
          <p className={classNames('step-requirements', connectedSources.length > 0 ? 'step-requirements-met' : '')}>Connect at least 1 source.</p>
        </div>
        <div className="step-control-buttons">
          { !isFirst && <Button onClick={() => prev()}>Back</Button> }
          { !isLast && <Button type="primary" disabled={connectedSources.length === 0} onClick={() => handleNextClick()}>Next</Button>}
          { isLast && <Button type="primary" onClick={() => handleNextClick()}>Finish</Button>}
        </div>
      </div>
      <SourceTipsCard connectedSources={connectedSources} />
    </div>
  );
};

SignUpSources.defaultProps = {
  isFirst: false,
  isLast: false,
};

export default SignUpSources;
