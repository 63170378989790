import {
  createStore, applyMiddleware, combineReducers, Action,
} from 'redux';
import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import logger from 'redux-logger';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, History } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

import auth from '../modules/auth/reducer';
import integration from '../modules/integration/reducer';
import registration from '../modules/registration/reducer';
import space from '../modules/space/reducer';
import page from '../modules/page/reducer';
import summary from '../modules/summary/reducer';
import workspace from '../modules/workspace/reducer';
import notion from '../modules/notion/reducer';
import jira from '../modules/jira/reducer';
import admin from '../modules/admin/reducer';
import height from '../modules/height/reducer';
import github from '../modules/github/reducer';
import appIntegrations from '../modules/appIntegrations/reducer';
import automations from '../modules/automations/reducer';
import briefings from '../modules/briefings/reducer';
import mentions from '../modules/mentions/reducer';

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  auth,
  integration,
  registration,
  space,
  page,
  summary,
  workspace,
  notion,
  jira,
  admin,
  height,
  github,
  appIntegrations,
  automations,
  briefings,
  mentions,
});

export const history = createBrowserHistory();
const reducers = createRootReducer(history);

export type RootReducerState = ReturnType<typeof reducers>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootReducerState,
  unknown,
  Action<string>
>

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['search', 'note', 'space', 'page', 'briefings', 'sources'], // search and note will not be persisted
};

const persistedReducer = persistReducer<RootReducerState>(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  applyMiddleware(routerMiddleware(history), thunk, logger),
);
export const persistor = persistStore(store);

// export type AppDispatch = typeof store.dispatch
export type AppDispatch = ThunkDispatch<RootReducerState, unknown, Action<string>>

export default store;
