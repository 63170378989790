import * as types from './types';

const defaultState: types.PageReducerState = {
  currentPageId: '',
  pages: [],
  favoritePages: [],
  pending: false,
  pageDetailById: new Map<string, types.PageDetail>(),
  pendingTagPage: '',
};

export default function spaceReducer(state = defaultState, action: types.PageAction): types.PageReducerState {
  switch (action.type) {
    case types.OPEN_PAGE_REQUEST:
      return {
        ...state,
        currentPageId: action.payload.pageId,
        pending: true,
      };
    case types.OPEN_PAGE_SUCCESS: {
      return {
        ...state,
        pending: false,
        pageDetailById: state.pageDetailById.set(action.payload.pageId, action.payload.pageDetail),
      };
    }
    case types.OPEN_PAGE_FAILURE:
      return {
        ...state,
        currentPageId: '',
        pending: false,
      };
    case types.UPDATE_PAGE_SUCCESS: {
      const newPages = state.pages.map((page) => {
        if (page.id !== parseInt(action.payload.pageId, 10)) {
          return page;
        }
        return {
          ...page,
          title: action.payload.title,
          description: action.payload.description,
          live_query: action.payload.query,
        };
      });

      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      if (pageDetail) {
        const newPageDetail = {
          ...pageDetail,
          title: action.payload.title,
          description: action.payload.description,
          liveQuery: action.payload.query,
        };

        const newPageDetailById = new Map(state.pageDetailById);
        newPageDetailById.set(action.payload.pageId, newPageDetail);

        return {
          ...state,
          pageDetailById: newPageDetailById,
          pages: newPages,
        };
      }

      return {
        ...state,
        pages: newPages,
      };
    }
    case types.UPDATE_PAGE_FAILURE:
      return {
        ...state,
      };
    case types.UPDATE_PAGE_DATE: {
      const newPages = state.pages.map((page) => {
        if (page.id !== parseInt(action.payload.pageId, 10)) {
          return page;
        }
        return {
          ...page,
          updated_date: action.payload.date,
        };
      });

      return {
        ...state,
        pages: newPages,
      };
    }
    case types.REMOVE_PAGE_TUTORIAL: {
      const newPages = state.pages.map((page) => {
        if (page.id !== parseInt(action.payload.pageId, 10)) {
          return page;
        }
        return {
          ...page,
          tutorial: false,
        };
      });

      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      if (pageDetail) {
        const newPageDetail = {
          ...pageDetail,
          tutorial: false,
        };

        const newPageDetailById = new Map(state.pageDetailById);
        newPageDetailById.set(action.payload.pageId, newPageDetail);

        return {
          ...state,
          pageDetailById: newPageDetailById,
          pages: newPages,
        };
      }

      return {
        ...state,
        pages: newPages,
      };
    }
    case types.UPDATE_PIN_SUCCESS: {
      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      if (pageDetail) {
        const newPageDetail = {
          ...pageDetail,
          pinnedBlocks: action.payload.pinnedBlocks,
        };

        const newPageDetailById = new Map(state.pageDetailById);
        newPageDetailById.set(action.payload.pageId, newPageDetail);

        return {
          ...state,
          pageDetailById: newPageDetailById,
        };
      }

      return {
        ...state,
      };
    }
    case types.DELETE_PAGE_REQUEST:
      return state;
    case types.DELETE_PAGE_SUCCESS: {
      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      const newPageDetailById = new Map(state.pageDetailById);
      if (pageDetail) {
        newPageDetailById.delete(action.payload.pageId);
      }

      const newPages = state.pages.filter((page) => page.id.toString(10) !== action.payload.pageId);

      return {
        ...state,
        pageDetailById: newPageDetailById,
        pages: newPages,
      };
    }
    case types.DELETE_PAGE_FAILURE:
      return state;
    case types.CREATE_PAGE_BLOCK: {
      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      if (!pageDetail) {
        return state;
      }

      const newOrderedBlocks = [action.payload.blockId, ...pageDetail.orderedBlocks];
      const newPageDetail = {
        ...pageDetail,
        orderedBlocks: newOrderedBlocks,
      };

      return {
        ...state,
        pageDetailById: state.pageDetailById.set(action.payload.pageId, newPageDetail),
      };
    }
    case types.REMOVE_PAGE_BLOCK: {
      const pageDetail = state.pageDetailById.get(action.payload.pageId);
      if (!pageDetail) {
        return state;
      }

      const newOrderedBlocks = pageDetail.orderedBlocks.filter((item) => item !== action.payload.blockId);
      const newPageDetail = {
        ...pageDetail,
        orderedBlocks: newOrderedBlocks,
      };

      return {
        ...state,
        pageDetailById: state.pageDetailById.set(action.payload.pageId, newPageDetail),
      };
    }
    case types.GET_USER_PAGES_SUCCESS:
      return {
        ...state,
        pages: action.payload.pages,
      };
    case types.GET_USER_PAGES_FAILURE:
      return {
        ...state,
        pages: [],
      };
    case types.GET_USER_FAVORITE_PAGES_SUCCESS:
      return {
        ...state,
        favoritePages: action.payload.pages,
      };
    case types.GET_USER_FAVORITE_PAGES_FAILURE:
      return {
        ...state,
        favoritePages: [],
      };
    case types.ADD_FAVORITE_PAGE_SUCCESS:
      return {
        ...state,
        favoritePages: [action.payload.page, ...state.favoritePages],
      };
    case types.ADD_FAVORITE_PAGE_FAILURE:
      return state;
    case types.DELETE_FAVORITE_PAGE_SUCCESS: {
      const newFavoritePages = state.favoritePages.filter((page) => page.id !== action.payload.page.id);
      return {
        ...state,
        favoritePages: newFavoritePages,
      };
    }
    case types.DELETE_FAVORITE_PAGE_FAILURE:
      return state;
    case types.CREATE_PAGE_SUCCESS:
      return {
        ...state,
        pages: [...state.pages, action.payload.page],
      };
    case types.SET_PENDING_TAG_PAGE:
      return {
        ...state,
        pendingTagPage: action.payload.tag,
      };
    case types.RESET_PENDING_TAG_PAGE: {
      return {
        ...state,
        pendingTagPage: '',
      };
    }
    case types.CREATE_PAGE_FAILURE:
      return state;
    case types.ADD_USER_SUCCESS: {
      //we reduce the old list because we want to preserve the old order
      const newPages = state.pages.reduce((newPageList, page) => {
        if (page.id !== action.payload.page.id) {
          newPageList.push(page);
          return newPageList;
        }
        newPageList.push(action.payload.page);
        return newPageList;
      }, [] as types.Page[]);

      const pageDetail = state.pageDetailById.get(action.payload.page.id.toString());
      if (!pageDetail) {
        return {
          ...state,
          pages: newPages,
        };
      }
      const newPageDetail = {
        ...pageDetail,
        users: action.payload.page.users,
      };

      return {
        ...state,
        pages: newPages,
        pageDetailById: state.pageDetailById.set(action.payload.page.id.toString(), newPageDetail),
      };
    }
    case types.REMOVE_USER_SUCCESS: {
      //we reduce the old list because we want to preserve the old order
      const newPages = state.pages.reduce((newPageList, page) => {
        if (page.id !== action.payload.page.id) {
          newPageList.push(page);
          return newPageList;
        }
        newPageList.push(action.payload.page);
        return newPageList;
      }, [] as types.Page[]);

      const pageDetail = state.pageDetailById.get(action.payload.page.id.toString());
      if (!pageDetail) {
        return {
          ...state,
          pages: newPages,
        };
      }

      const newPageDetail = {
        ...pageDetail,
        users: action.payload.page.users,
      };

      return {
        ...state,
        pages: newPages,
        pageDetailById: state.pageDetailById.set(action.payload.page.id.toString(), newPageDetail),
      };
    }
    default:
      return state;
  }
}
