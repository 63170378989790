/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { actions } from '../../../redux/modules/auth';


import PasswordResetRequest from './PasswordResetRequest';
import PasswordResetConfirmation from './PasswordResetConfirmation';


type ForgotPasswordResetProps = {
  isCodeProvided: boolean;
};

const ForgotPasswordReset: React.FC<ForgotPasswordResetProps> = ({ isCodeProvided }) => {
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<'request' | 'confirmation'>('request');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (isCodeProvided) {
      setCurrentStep('confirmation');
    }
  }, [isCodeProvided]);


  const onSubmitRequest = (email: string) => {
    setUserEmail(email);
    dispatch(actions.requestPasswordReset(email));
    setCurrentStep('confirmation');
  };

  const onSubmitConfirmation = (code: string, newPassword: string, email?: string | undefined) => {
    const emailToUse = email || userEmail;
    dispatch(actions.submitNewPassword(emailToUse, code, newPassword));
  };

  return (
    <div className="password-reset-content-wrapper">
      {currentStep === 'request' ? (
        <PasswordResetRequest onSubmitRequest={onSubmitRequest} />
      ) : (
        <PasswordResetConfirmation isCodeProvided={isCodeProvided} onSubmitConfirmation={onSubmitConfirmation} />
      )}
      <div className="login-options">
        <div className="registration-login">
          Remember your password?
          {' '}
          <Link to="/login">
            <b>Log In</b>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordReset;
