/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/pro-solid-svg-icons';
import { faThumbsUp as faThumbsUpThin, faThumbsDown as faThumbsDownThin } from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';

import { Identifier, ReactionType } from '../../redux/modules/feedback/schemas';
import {
  submitUserFeedback,
} from '../../redux/modules/feedback/actions';

import './FeedbackComponent.scss';

const { TextArea } = Input;

interface FeedbackComponentContextType {
  inputValue: string;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const FeedbackComponentContext = React.createContext<FeedbackComponentContextType>({
  inputValue: '',
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    console.log(e.target.value);
    // Placeholder function, intentionally left blank
  },
});

interface FeedbackComponentProps {
  identifier: Identifier;
  reaction: ReactionType | null
}
const FeedbackComponent: React.FC<FeedbackComponentProps> = ({ identifier, reaction }) => {
  const dispatch = useDispatch();
  const [selectedReaction, setSelectedReaction] = useState<ReactionType | null>(reaction);
  const selectedReactionRef = useRef(selectedReaction);
  const [inputValue, setInputValue] = useState<string>('');
  const inputValueRef = useRef(inputValue);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  useEffect(() => {
    selectedReactionRef.current = selectedReaction;
  }, [selectedReaction]);

  useEffect(() => {
    setSelectedReaction(reaction);
  }, [reaction]);


  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
  };

  const handleFeedbackSubmit = () => {
    const currentReaction = selectedReactionRef.current;
    const currentInputValue = inputValueRef.current;
    api.destroy();
    if (currentReaction) {
      dispatch(submitUserFeedback(currentReaction, currentInputValue, identifier));
    }
    setInputValue('');
  };

  const openNotificationWithInput = (reactionType: ReactionType) => {
    const thumbsUpTitle = 'Thanks a bunch! Your thumbs-up makes us smile.';
    const thumbsDownTitle = 'Got it, and we\'re on it! Thanks for helping us improve.';

    api.open({
      className: 'feedback-notification',
      message: reactionType === 'like' ? thumbsUpTitle : thumbsDownTitle,
      description: (
        <div className="feedback-notification-input">
          <FeedbackComponentContext.Consumer>
            {(context) => (
              <TextArea
                placeholder="Anything else we should know?"
                value={context.inputValue}
                onChange={context.handleInputChange}
                autoSize={{ minRows: 2, maxRows: 2 }}
              />
            )}
          </FeedbackComponentContext.Consumer>
          <Button type="default" onClick={() => handleFeedbackSubmit()}>Share Additional Feedback</Button>
        </div>
      ),
      duration: 6,
      onClose: () => {
        handleFeedbackSubmit();
      },
      icon: <FontAwesomeIcon icon={reactionType === 'like' ? faThumbsUp : faThumbsDown} />,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (selectedReactionRef) {
        handleFeedbackSubmit();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const recordReaction = (reactionType: ReactionType) => {
    setSelectedReaction(reactionType);
    openNotificationWithInput(reactionType);
    // dispatch(submitUserReaction(reactionType, identifier));
  };

  return (
    <FeedbackComponentContext.Provider value={{ inputValue, handleInputChange }}>
      {contextHolder}
      {' '}
      {/* Include the contextHolder here */}
      <div className="feedback-component">
        <div className="feedback-buttons-container">
          <Button
            icon={<FontAwesomeIcon icon={selectedReaction === 'like' ? faThumbsUp : faThumbsUpThin} />}
            className={classNames({ active: selectedReaction === 'like' })}
            type="text"
            onClick={() => { recordReaction('like'); }}
          />
          <Button
            icon={<FontAwesomeIcon icon={selectedReaction === 'dislike' ? faThumbsDown : faThumbsDownThin} />}
            className={classNames({ active: selectedReaction === 'dislike' })}
            type="text"
            onClick={() => { recordReaction('dislike'); }}
          />
        </div>
      </div>
    </FeedbackComponentContext.Provider>
  );
};

export default FeedbackComponent;
