import * as types from './types';

const defaultState: types.JiraReducer = {
  jiraOauthUrl: '',
  oauthState: '',
  jiraOauthStatus: false,
  oauthStatusResponse: false,
  jiraBoards: [],
  jiraBoard: undefined,
  jiraSprints: [],
  jiraSprint: undefined,
  jiraSprintReport: '',
  jiraDateRangeReport: '',
  jiraInstallId: null,
  dateRange: ['', ''],
  reportId: '',
};



export default function jiraReducer(state = defaultState, action: types.JiraAction): types.JiraReducer {
  switch (action.type) {
    case types.GET_JIRA_OAUTH_URL_SUCCESS:
      return {
        ...state,
        jiraOauthUrl: action.payload.url,
        oauthState: action.payload.state,
      };
    case types.GET_JIRA_OAUTH_FAILURE:
      return {
        ...state,
        jiraOauthUrl: '',
      };
    case types.GET_JIRA_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: action.payload.oauthStatusResponse,
        jiraOauthStatus: action.payload.jiraOauthStatus,
      };
    case types.RESET_JIRA_OAUTH_SUCCESS:
      return {
        ...state,
        oauthStatusResponse: false,
      };
    case types.GET_JIRA_BOARDS:
      return {
        ...state,
        jiraBoards: action.payload.jiraBoards,
      };
    case types.SET_JIRA_BOARD:
      return {
        ...state,
        jiraBoard: action.payload.jiraBoard,
      };
    case types.GET_JIRA_SPRINTS:
      return {
        ...state,
        jiraSprints: action.payload.jiraSprints,
      };
    case types.SET_JIRA_SPRINT_ID:
      return {
        ...state,
        jiraSprint: action.payload.jiraSprintId,
      };
    case types.SET_JIRA_SPRINT_REPORT:
      return {
        ...state,
        jiraSprintReport: action.payload.report,
      };
    case types.SET_JIRA_DATE_RANGE_REPORT:
      return {
        ...state,
        jiraDateRangeReport: action.payload.report,
      };
    case types.SET_JIRA_INSTALL_ID:
      return {
        ...state,
        jiraInstallId: action.payload.jiraInstallId,
      };
    case types.SET_JIRA_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload.dateRange,
      };
    case types.SET_JIRA_REPORT_ID:
      return {
        ...state,
        reportId: action.payload.reportId,
      };
    case types.RESET_JIRA_OAUTH:
      return {
        ...defaultState,
        jiraOauthStatus: state.jiraOauthStatus,
      };
    case types.JIRA_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
