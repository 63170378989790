import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { pipe } from 'fp-ts/function';
import { fold, left } from 'fp-ts/lib/Either';
import { AxiosResponse } from 'axios';
import authAxios from './authAxios';

export const decode = <T>(decoder: t.Decoder<unknown, T>) => {
  const onError = (errs: t.Errors) => {
    throw new Error(PathReporter.report(left(errs)).join('\n\n'));
  };
  const onSuccess = (s: T): T => s;
  return (json: unknown): T => pipe(
    decoder.decode(json),
    fold(onError, onSuccess),
  );
};

export const get = async <ReqT, ResT>(decoder: t.Decoder<unknown, ResT>, uri: string, params?: ReqT | undefined): Promise<ResT> => {
  const promise = params === undefined ? authAxios.get(uri) : authAxios.get(uri, { params });
  return promise.then((res) => decode<ResT>(decoder)(res.data));
};

export const post = async <ReqT, ResT>(decoder: t.Decoder<unknown, ResT>, uri: string, data: ReqT): Promise<ResT> => authAxios.post(uri, data)
  .then((res) => decode<ResT>(decoder)(res.data));

export const put = async <ReqT, ResT>(
  decoder: t.Decoder<unknown, ResT> | null,
  uri: string,
  data: ReqT,
): Promise<ResT | { status: number }> => authAxios.put(uri, data)
  .then((res: AxiosResponse) => {
    if (decoder) {
      return decode<ResT>(decoder)(res.data);
    }
    return { status: res.status };
  });

export const del = async <ReqT, ResT>(decoder: t.Decoder<unknown, ResT>, uri: string, params?: ReqT | undefined): Promise<ResT> => {
  const promise = params === undefined ? authAxios.delete(uri) : authAxios.delete(uri, { params });
  return promise.then((res) => decode<ResT>(decoder)(res.data));
};
