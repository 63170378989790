import * as types from './types';

const defaultState: types.AuthReducerState = {
  pending: false,
  username: undefined,
  email: undefined,
  firstName: '',
  lastName: '',
  flags: undefined,
  userId: -1,
  token: undefined,
  accessToken: undefined,
  tokenExpiration: undefined,
  refreshToken: undefined,
  idToken: undefined,
  errorMessage: undefined,
  googleAuthExists: false,
  registration_step: 1,
  emailVerified: false,
  qrSecret: undefined,
  qrCodeUrl: undefined,
  MFARequired: false,
  cognitoUser: undefined,
  passwordReset: false,
};

export default function authReducer(state = defaultState, action: types.AuthAction): types.AuthReducerState {
  switch (action.type) {
    case types.AUTH_LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case types.AUTH_LOGIN_FAILURE:
      return {
        ...state,
        pending: false,
        errorMessage: action.payload.status,
      };
    case types.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        pending: false,
        email: action.payload.email,
        userId: action.payload.userId,
        errorMessage: undefined,
      };
    case types.REGISTRATION_SUCCESS:
      return {
        ...state,
        username: action.payload.username,
        email: action.payload.email,
        userId: action.payload.userId,
      };
    case types.AUTH_GETUSER_SUCCESS:
      return {
        ...state,
        username: action.payload.username,
        userId: action.payload.id,
        email: action.payload.email,
        flags: action.payload.flags,
        registration_step: action.payload.registration_step,
      };
    case types.AUTH_UPDATE_TOKENS:
      return {
        ...state,
        idToken: action.payload.idToken,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
        tokenExpiration: action.payload.tokenExpiration,
      };
    case types.AUTH_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerified: true,
      };
    case types.AUTH_GENERATE_QR_CODE_SUCCESS:
      return {
        ...state,
        qrCodeUrl: action.payload.qrCodeUrl,
        qrSecret: action.payload.qrSecret,
      };

    case types.AUTH_MFA_REQUIRED:
      return {
        ...state,
        cognitoUser: action.payload.cognitoUser,
        MFARequired: true,
      };
    case types.AUTH_DISABLE_MFA_REQUEST_SUCCESS:
      return {
        ...state,
        MFARequired: false,
        cognitoUser: undefined,
      };
    case types.REGISTRATION_REQUEST:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      };
    case types.REGISTRATION_PASSWORD_RESET_REQUIRED:
      return {
        ...state,
        passwordReset: true,
      };
    case types.REGISTRATION_PASSWORD_RESET_COMPLETE:
      return {
        ...state,
        passwordReset: false,
      };
    case types.AUTH_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
