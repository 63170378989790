/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Editor from 'rich-markdown-editor';

import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/pro-duotone-svg-icons';
import FeedbackComponent from '../../FeedbackComponent/FeedbackComponent';
import mp from '../../../mixpanel';

import './MentionDetails.scss';

import { Mention } from '../../../redux/modules/mentions/schemas';
import { getUserId } from '../../../redux/modules/auth/selectors';
import SlackMessage from '../SlackMessage';
import { sourceLogos } from '../../../utils/logoSources';



type MentionDetailsProps = {
  mention: Mention | undefined
  toggleSidebar: () => void;
};


const MentionDetails: React.FC<MentionDetailsProps> = ({
  mention, toggleSidebar,
}) => {
  const userId = useSelector(getUserId);
  const sidebarContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mention) {
      mp.identify(userId);
      mp.track('Opened Mention Detail', { channel_name: mention.channel_name });
    }
  }, []);

  if (!mention) {
    return <div>No mention selected</div>;
  }

  const handleSidebarClose = () => {
    toggleSidebar();
  };

  const renderChannelName = (mentionItem: Mention) => {
    const { channel_type: channelType, channel_name: channelName } = mentionItem;
    switch (channelType) {
      case 'channel':
      case 'group':
        return `#${channelName}`;
      case 'im':
        return 'a DM';
      case 'mpim':
        return 'a Group DM';
      default:
        return `#${channelName}` || 'Unknown'; // Fallback to channelName or 'Unknown' if neither is provided
    }
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-controls-container">
        <Button onClick={handleSidebarClose} className="control-button" type="text" ghost><FontAwesomeIcon icon={faAnglesRight} /></Button>
        <FeedbackComponent identifier={{ briefingId: mention.id }} reaction={null} />
      </div>
      <div className="sidebar-content" ref={sidebarContentRef}>
        <h2>
          {mention.message.sender_name}
          {' '}
          mentioned you in
          {'  '}
          {renderChannelName(mention)}
        </h2>
        <Button className="open-conversation-btn" onClick={() => window.open(mention.message.permalink, '_blank', 'noopener,noreferrer')}>
          {sourceLogos[mention.app_source]}
          Open in Slack
        </Button>
        <div className="messages-list">
          {mention.prevMessages && mention.prevMessages.slice(0, 2).map((message, index) => (
            <SlackMessage
              key={index}
              name={message.sender_name}
              timestamp={message.timestamp}
              body={message.body}
              icon={message.sender_icon}
            />
          ))}
          <SlackMessage main name={mention.message.sender_name} timestamp={mention.message.timestamp} body={mention.message.body} icon={mention.message.sender_icon} />
        </div>
      </div>
    </div>
  );
};

export default MentionDetails;
