import { Position, Toaster } from '@blueprintjs/core';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP_RIGHT,
});



export const AppToasterLeft = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP_LEFT,
});

export default AppToaster;
