/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';

import { mapSourceToLogo } from '../../utils/logoSources';
import { ConnectedSource } from '../../redux/modules/appIntegrations/schemas';
import { get } from '../../redux/common/rest';
import { AuthUrl, OauthUrl, vOauthUrl } from '../../redux/modules/common/schemas';
import useInterval from '../utils/UseInterval';
import { getConnectedSources } from '../../redux/modules/appIntegrations/selectors';
import { fetchConnectedSources } from '../../redux/modules/appIntegrations/actions';



interface ConnectSourceButtonProps {
    source: ConnectedSource;
  }

const ConnectSourceButton: React.FC<ConnectSourceButtonProps> = ({ source }) => {
  const dispatch = useDispatch();
  const connectedSources = useSelector(getConnectedSources);
  const [authLink, setAuthLink] = useState('');
  const [isIntervalActive, setIsIntervalActive] = useState(false);

  const getOauthStatus = async () => {
    dispatch(fetchConnectedSources());
  };

  const pollOauthResponse = (startPolling: boolean) => {
    setIsIntervalActive(startPolling);
  };

  const validatedStates = connectedSources.map((src) => src.isConnected).join(',');

  useEffect(() => {
    pollOauthResponse(false);
  }, [validatedStates]);

  const handleClick = () => {
    pollOauthResponse(true);
    window.open(authLink, '_blank', 'width=700,height=600');
  };

  useEffect(() => {
    if (!source.isConnected && !source.isComingSoon) {
      const fetchOauthUrl = async () => {
        try {
          const response = await get<AuthUrl, OauthUrl>(vOauthUrl, '/api/v1.0/oauth/install', { app_name: source.name });
          setAuthLink(response.url);
        } catch (error) {
          console.error('Failed to fetch OAuth URL:', error);
        }
      };
      fetchOauthUrl();
    }
  }, [source.isConnected, source.isComingSoon, dispatch]);

  useInterval(getOauthStatus, isIntervalActive ? 2000 : null);

  return (
    <Button key={source.name} className={`topic ${source.isConnected ? 'selected' : ''}`} size="large" disabled={source.isComingSoon} onClick={() => { handleClick(); }}>
      <img className="source-icon" src={mapSourceToLogo(source.name)} alt={`${source.name} Icon`} />
      {source.name}
      {source.isComingSoon && <span className="soon">(Coming Soon)</span>}
    </Button>
  );
};

export default ConnectSourceButton;
