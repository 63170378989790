import * as types from './types';

const defaultState: types.SummaryReducerState = {
  summaries: [],
  summaryPending: false,
};

export default function spaceReducer(state = defaultState, action: types.SummaryAction): types.SummaryReducerState {
  switch (action.type) {
    case types.CREATE_SUMMARY_REQUEST:
      return {
        ...state,
        summaryPending: true,
      };
    case types.CREATE_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryPending: false,
        summaries: [...state.summaries, action.payload.summary],
      };
    case types.CREATE_SUMMARY_FAILURE:
      return {
        ...state,
        summaryPending: false,
      };
    default:
      return state;
  }
}
