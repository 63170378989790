/* eslint-disable react/require-default-props */
import React, { ReactNode, useState } from 'react';

import {
  Form,
  Select,
  Input,
  Radio,
  Divider,
  Button,
} from 'antd';
import type { SelectProps } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDay,
  faCalendarWeek,
  faCalendarDays,
} from '@fortawesome/pro-solid-svg-icons';

import slackLogo from '../../icons/slack_60x60.png';
import heightLogo from '../../icons/connection-height.png';
import jiraLogo from '../../icons/connection-jira.svg';
import githubLogo from '../../icons/connection-github.svg';
//import notionLogo from '../../../icons/connection-notion.svg';

import './CreateReportForm.scss';

interface CreateReportFormProps {
  name?: string;
  meta?: string;
  logoSrc?: string;
  reportType?: string; // The selected report type from the first step
  onSubmit?: (formData: any) => void; // Callback function for form submission
  onBack?: (formData: any) => void; // Callback function for handling back button
  onCancel?: () => void; // Optional callback for handling cancellation
  reportTitle?: string;
  reportEmoji?: string;
  reportPrompt?: string;
  reportFrequency?: string;
}

const { TextArea } = Input;

interface Option {
  value: string | number;
  label: ReactNode;
  children: Option[];
  disableCheckbox?: boolean;
}

const channelsOptions: Option[] = [
  {
    label: (
      <div className="source-dropdown">
        <img src={slackLogo} alt="Slack logo" />
        <div>Zephr Health</div>
      </div>
    ),
    value: 'Zephr Health',
    children: [
      {
        label: '#genearl',
        value: '#general',
        children: [],
      },
      {
        label: '#dev',
        value: '#dev',
        children: [],
      },
      {
        label: '#project-apollo',
        value: '#project-apollo',
        children: [],
      },
      {
        label: '#project-athena',
        value: '#project-athena',
        children: [],
      },
    ],
  },
  {
    label: (
      <div className="source-dropdown">
        <img src={slackLogo} alt="Slack logo" />
        <div>Zephr Support</div>
      </div>
    ),
    value: 'Zephr Support',
    children: [
      {
        label: '#genearl',
        value: '#general',
        children: [],
      },
      {
        label: '#troubleshooting',
        value: '#troubleshooting',
        children: [],
      },
      {
        label: '#qna',
        value: '#qna',
        children: [],
      },
      {
        label: '#feature-ideas',
        value: '#feature-ideas',
        children: [],
      },
    ],
  },
  {
    label: (
      <div className="source-dropdown">
        <img src={jiraLogo} alt="Slack logo" />
        <div>Zephr Dev</div>
      </div>
    ),
    value: 'Zephr Dev',
    children: [
      {
        label: 'zephr',
        value: 'zephr',
        children: [],
      },
    ],
  },
  {
    label: (
      <div className="source-dropdown">
        <img src={githubLogo} alt="Slack logo" />
        <div>Zephr Health</div>
      </div>
    ),
    value: 'Zephr Co',
    children: [
      {
        label: 'zephrFrontend',
        value: 'zephrFrontend',
        children: [],
      },
      {
        label: 'zephrBackend',
        value: 'zephrBackend',
        children: [],
      },
      {
        label: 'zephrAPI',
        value: 'zephrAPI',
        children: [],
      },
    ],
  },
  {
    label: (
      <div className="source-dropdown">
        <img src={heightLogo} alt="Slack logo" />
        <div>Zephr Business</div>
      </div>
    ),
    value: 'Zephr Business',
    children: [
      {
        label: 'Fundraising',
        value: 'Fundraising',
        children: [],
      },
      {
        label: 'Outreach',
        value: 'Outreach',
        children: [],
      },
      {
        label: 'Sales',
        value: 'Sales',
        children: [],
      },
      {
        label: 'Marketing',
        value: 'Marketing',
        children: [],
      },
    ],
  },
];

const days: SelectProps['options'] = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
  {
    value: 'Sunday',
    label: 'Sunday',
  },
];


const times: SelectProps['options'] = [
  {
    value: 8,
    label: '8:00 AM',
  },
  {
    value: 9,
    label: '9:00 AM',
  },
  {
    value: 10,
    label: '10:00 AM',
  },
  {
    value: 11,
    label: '11:00 AM',
  },
  {
    value: 12,
    label: '12:00 PM',
  },
  {
    value: 13,
    label: '1:00 PM',
  },
  {
    value: 14,
    label: '2:00 PM',
  },
  {
    value: 15,
    label: '3:00 PM',
  },
  {
    value: 16,
    label: '4:00 PM',
  },
  {
    value: 17,
    label: '5:00 PM',
  },
  {
    value: 18,
    label: '6:00 PM',
  },
  {
    value: 19,
    label: '7:00 PM',
  },
  {
    value: 20,
    label: '8:00 PM',
  },
  {
    value: 21,
    label: '9:00 PM',
  },
  {
    value: 22,
    label: '10:00 PM',
  },
  {
    value: 23,
    label: '11:00 PM',
  },
  {
    value: 24,
    label: '12:00 AM',
  },
  {
    value: 1,
    label: '1:00 AM',
  },
  {
    value: 2,
    label: '2:00 AM',
  },
  {
    value: 3,
    label: '3:00 AM',
  },
  {
    value: 4,
    label: '4:00 AM',
  },
  {
    value: 5,
    label: '5:00 AM',
  },
  {
    value: 6,
    label: '6:00 AM',
  },
  {
    value: 7,
    label: '7:00 AM',
  }];

const CreateReportForm: React.FC<CreateReportFormProps> = (props: CreateReportFormProps) => {
  const {
    logoSrc,
    name,
    meta,
    reportType,
    onSubmit,
    onBack,
    onCancel,
    reportTitle,
    reportEmoji,
    reportPrompt,
    reportFrequency,
  } = props;

  console.log(
    logoSrc,
    name,
    meta,
    reportType, onSubmit, onBack, onCancel,
    reportTitle, reportEmoji, reportPrompt, reportFrequency,
  );

  const [selectedSource, setSelectedSource] = useState('');
  const [selectedChannels, setSelectedChannels] = useState([] as Option[]);

  //const [channels, setChannels] = useState([]);

  const handleSourceChange = (value: string) => {
    setSelectedSource(value);
    setSelectedChannels([]);
  };

  const handleChannelsChange = (value: Option[]) => {
    setSelectedChannels(value);
  };

  return (
    <div className="create-report-form-container">
      <Form
        className="create-report-form"
        layout="horizontal"
      >
        <Divider className="settings-divider" orientation="left" orientationMargin="0" plain>What is the report about?</Divider>

        <Form.Item style={{ width: '100%' }} label="Report Name">
          <Input placeholder="Report Name" size="middle" value={reportTitle} />
        </Form.Item>

        <Divider className="settings-divider" orientation="left" orientationMargin="0" plain>What should the report cover?</Divider>

        <Form.Item style={{ width: '100%' }} label="Prompt">
          <TextArea
            size="middle"
            placeholder="Prompt"
            autoSize={{ minRows: 4, maxRows: 6 }}
            value={reportPrompt}
          />
        </Form.Item>

        <Divider className="settings-divider" orientation="left" orientationMargin="0" plain>Where should we pull data from?</Divider>
        <div className="form-item-inline">
          <Form.Item label="Sources">
            <Select
              mode="multiple"
              size="middle"
              placeholder="Select a Source"
              options={channelsOptions}
              maxTagCount="responsive"
              onChange={handleSourceChange}
              // defaultValue={['Zephr Health', 'Zephr Support', 'Zephr Dev', 'Zephr Co', 'Zephr Business']}
            />
          </Form.Item>
        </div>

        <Divider className="settings-divider" orientation="left" orientationMargin="0" plain>When do you want it?</Divider>

        <div className="form-item-inline">
          <Form.Item className="form-item-frequency" label="Frequency" name="frq">
            <Radio.Group size="middle">
              <Radio.Button className="settings-selector freq-selector" value="daily">
                <FontAwesomeIcon icon={faCalendarDay} />
                Daily
              </Radio.Button>
              <Radio.Button className="settings-selector freq-selector" value="weekly">
                <FontAwesomeIcon icon={faCalendarWeek} />
                Weekly
              </Radio.Button>
              <Radio.Button className="settings-selector freq-selector" value="monthly">
                <FontAwesomeIcon icon={faCalendarDays} />
                Monthly
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>

        <div style={{ display: 'none' }}>
          <Form.Item className="form-item-select" label="Days">
            <Select
              size="middle"
              mode="multiple"
              placeholder="Select Days"
              options={days}
            />
          </Form.Item>
          <Form.Item className="form-item-select" label="Times">
            <Select
              size="middle"
              mode="multiple"
              placeholder="Select Times"
              options={times}
            />
          </Form.Item>
          <Form.Item label="Channels">
            <Select
              size="middle"
              placeholder="Select Channels"
              maxTagCount="responsive"
              value={selectedChannels}
              onChange={handleChannelsChange}
              mode="multiple"
              options={channelsOptions.find((option) => option.value === selectedSource)?.children || []}
            />
          </Form.Item>
        </div>
      </Form>
      <div className="footer">
        <Button onClick={onBack}>Back</Button>
        <Button onClick={onSubmit}>Create Report</Button>
      </div>
    </div>
  );
};

export default CreateReportForm;
