import React from 'react';
import { useSelector } from 'react-redux';
import MFAForm from './MFAForm';
import LoginForm from './LoginForm';

import { getMFARequired, getPasswordResetRequired } from '../../redux/modules/auth/selectors';
import PasswordReset from '../PasswordReset/PasswordReset';

const LoginPage: React.FC = () => {
  const isMFARequired = useSelector(getMFARequired);
  const isPasswordResetRequired = useSelector(getPasswordResetRequired);

  if (isPasswordResetRequired) {
    return <PasswordReset mode="updatePassword" />;
  }

  if (isMFARequired) {
    return <MFAForm />;
  }

  return <LoginForm />;
};

export default LoginPage;
