import * as t from 'io-ts';

export const vMessage = t.type({
  sender_id: t.string,
  sender_name: t.string,
  sender_icon: t.string,
  body: t.string,
  timestamp: t.string,
  permalink: t.string,
});
export type Message = t.TypeOf<typeof vMessage>;

export const vMention = t.type({
  id: t.string,
  app_source: t.string,
  user_ids: t.array(t.number),
  slack_id: t.string,
  slack_name: t.string,
  sender_id: t.string,
  oauth_id: t.string,
  channel_id: t.string,
  channel_name: t.string,
  channel_type: t.union([t.string, t.undefined]),
  timestamp: t.number,
  message: vMessage,
  prevMessages: t.union([t.array(vMessage), t.undefined, t.null]),
  status: t.string,
  response_message: t.union([vMessage, t.undefined, t.null]),
});
export type Mention = t.TypeOf<typeof vMention>;

export const vMentions = t.array(vMention);
export type Mentions = t.TypeOf<typeof vMentions>;
