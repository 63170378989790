import { StatusMessage } from '../common/types';
import { Workspace } from './schemas';

// actions
export const GET_WORKSPACE_REQUEST = 'workspace/GET_WORKSPACE_REQUEST';
export const GET_WORKSPACE_SUCCESS = 'workspace/GET_WORKSPACE_SUCCESS';
export const GET_WORKSPACE_FAILURE = 'workspace/GET_WORKSPACE_FAILURE';
export const GET_SLACK_OAUTH_REQUEST = 'workspace/GET_SLACK_OAUTH_REQUEST';
export const GET_SLACK_OAUTH_URL_SUCCESS = 'workspace/GET_SLACK_OAUTH_URL_SUCCESS';
export const GET_SLACK_OAUTH_FAILURE = 'workspace/GET_SLACK_OAUTH_FAILURE';
export const GET_SLACK_DATA_REQUEST = 'workspace/GET_SLACK_DATA_REQUEST';
export const GET_SLACK_DATA_SUCCESS = 'workspace/GET_SLACK_DATA_SUCCESS';
export const GET_SLACK_DATA_FAILURE = 'workspace/GET_SLACK_DATA_FAILURE';
export const GET_SLACK_OAUTH_SUCCESS = 'workspace/GET_SLACK_OAUTH_SUCCESS';
export const RESET_SLACK_OAUTH_SUCCESS = 'workspace/RESET_SLACK_OAUTH_SUCCESS';
export const RESET_SLACK_OAUTH = 'workspace/RESET_SLACK_OAUTH';
export const LOGOUT_WORKSPACE = 'workspace/LOGOUT_WORKSPACE';
export const GET_SLACK_CHANNELS = 'workspace/GET_SLACK_CHANNELS';
export const GET_ACTIVITY = 'workspace/GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'workspace/GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_ERROR = 'workspace/GET_ACTIVITY_ERROR';
export const GET_ACTIVITY_NO_CHANGE = 'workspace/GET_ACTIVITY_NO_CHANGE';
export const SET_SLACK_INSTALL_ID = 'workspace/SET_SLACK_INSTALL_ID';

export interface SetSlackInstallId {
  type: typeof SET_SLACK_INSTALL_ID;
  payload: {
    slackInstallId: number
  }
}

export interface GetActivity {
  type: typeof GET_ACTIVITY;
}


export interface GetActivityError {
  type: typeof GET_ACTIVITY_ERROR;
}

export interface GetActivityNoChange {
  type: typeof GET_ACTIVITY_NO_CHANGE
}
export interface GetWorkspaceRequestAction {
  type: typeof GET_WORKSPACE_REQUEST;
}

export interface GetWorkspaceSuccessAction {
  type: typeof GET_WORKSPACE_SUCCESS;
  payload: {
    workspaces: Workspace[];
  };
}

export interface GetWorkspaceFailureAction {
  type: typeof GET_WORKSPACE_FAILURE;
  payload: StatusMessage;
}

export interface GetSlackOauthUrlRequestAction {
  type: typeof GET_SLACK_OAUTH_REQUEST;
}

export interface GetSlackOauthUrlSuccessAction {
  type: typeof GET_SLACK_OAUTH_URL_SUCCESS;
  payload: {
    url: string;
    state: string;
  };
}

export interface GetSlackOauthUrlFailureAction {
  type: typeof GET_SLACK_OAUTH_FAILURE;
  payload: StatusMessage;
}

export interface GetSlackDataRequestAction {
  type: typeof GET_SLACK_DATA_REQUEST;
}

export interface GetSlackDataSuccessAction {
  type: typeof GET_SLACK_DATA_SUCCESS;
  payload: {
    appId: string;
    teamId: string
  };
}

export interface GetSlackDataFailureAction {
  type: typeof GET_SLACK_DATA_FAILURE;
  payload: StatusMessage;
}

export interface getSlackOauthSuccessAction {
  type: typeof GET_SLACK_OAUTH_SUCCESS;
  payload: {
    oauthStatusResponse: boolean;
    slackOauthStatus: boolean;
  }
}

export interface resetSlackOauthSuccessAction {
  type: typeof RESET_SLACK_OAUTH_SUCCESS
}

export interface ResetSlackOauthState {
  type: typeof RESET_SLACK_OAUTH;
}

export interface Logout {
  type: typeof LOGOUT_WORKSPACE;
}

export interface getSlackChannels {
  type: typeof GET_SLACK_CHANNELS;
  payload: {
    slackChannels: {
      id: string;
      title: string;
  }[]
  }
}

export type WorkspaceAction = resetSlackOauthSuccessAction | SetSlackInstallId | GetActivity | getSlackChannels | GetWorkspaceRequestAction | GetWorkspaceFailureAction | GetWorkspaceSuccessAction | getSlackOauthSuccessAction | ResetSlackOauthState | Logout |
  GetSlackOauthUrlFailureAction | GetSlackOauthUrlRequestAction | GetSlackOauthUrlSuccessAction | GetSlackDataRequestAction | GetSlackDataSuccessAction | GetSlackDataFailureAction | GetActivityError | GetActivityNoChange;

// reducers
export interface WorkspaceReducer {
  workspaces: Workspace[];
  slackOauthUrl: string;
  appId: string;
  teamId: string;
  oauthState: string;
  slackOauthStatus: boolean;
  oauthStatusResponse: boolean;
  slackChannels: {id: string, title: string}[];
  activityLoading: boolean;
  slackInstallId?: number | null;
}
