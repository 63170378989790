/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect, useRef } from 'react';

type CallbackFunction = () => void;

const useInterval = (callback: CallbackFunction, delay: number | null, maxExecutions = 150) => {
  const savedCallback = useRef<CallbackFunction>(() => {});
  const intervalId = useRef<number | undefined>();

  const executionCount = useRef<number>(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
      executionCount.current += 1;

      if (executionCount.current === maxExecutions) {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
      }
    }

    if (delay !== null) {
      intervalId.current = window.setInterval(tick, delay);
      return () => {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
      };
    }
  }, [delay, maxExecutions]);
};

export default useInterval;
