import mixpanel from 'mixpanel-browser';

const envCheck = process.env.NODE_ENV === 'production';

if (envCheck) {
  mixpanel.init('296ec72d8e5912ab26ca2bf37b71665b');
}

const mp = {
  identify: (id: any) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    if (envCheck) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

export default mp;
