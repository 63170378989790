import { StatusMessage } from '../common/types';

export const GET_NOTION_OAUTH_REQUEST = 'notion/GET_NOTION_OAUTH_REQUEST';
export const GET_NOTION_OAUTH_URL_SUCCESS = 'notion/GET_NOTION_OAUTH_URL_SUCCESS';
export const GET_NOTION_OAUTH_FAILURE = 'notion/GET_NOTION_OAUTH_FAILURE';
export const GET_NOTION_OAUTH_SUCCESS = 'notion/GET_NOTION_OAUTH_SUCCESS';
export const GET_NOTION_PAGES = 'notion/GET_NOTION_PAGES';
export const RESET_NOTION_OAUTH = 'notion/RESET_NOTION_OAUTH';
export const LOGOUT_NOTION = 'notion/LOGOUT_NOTION';
export interface GetNotionkOauthUrlRequestAction {
    type: typeof GET_NOTION_OAUTH_REQUEST;
  }

export interface GetNotionOauthUrlSuccessAction {
    type: typeof GET_NOTION_OAUTH_URL_SUCCESS;
    payload: {
      url: string;
      state: string;
    };
  }

export interface GetNotionOauthUrlFailureAction {
    type: typeof GET_NOTION_OAUTH_FAILURE;
    payload: StatusMessage;
  }

export interface getNotionOauthSucessAction {
  type: typeof GET_NOTION_OAUTH_SUCCESS;
  payload: {
    oauthStatusResponse: boolean;
    notionOauthStatus: boolean;
  }
}

export interface ResetNotionOauthState {
  type: typeof RESET_NOTION_OAUTH;
}

export interface Logout {
  type: typeof LOGOUT_NOTION;
}


export interface GetNotionPages {
  type: typeof GET_NOTION_PAGES
  payload: {
    notionPages: {
      id: string;
      title: string;
  }[]
  }
}


export type NotionAction = GetNotionPages | ResetNotionOauthState | Logout | getNotionOauthSucessAction | GetNotionkOauthUrlRequestAction | GetNotionOauthUrlSuccessAction | GetNotionOauthUrlFailureAction;

export interface NotionReducer {
    notionOauthUrl: string;
    oauthState: string;
    notionOauthStatus: boolean;
    oauthStatusResponse: boolean;
    notionPages: {id: string, title: string}[];
}
