import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch } from 'antd';
import { getBriefingTopics, getUserTopics } from '../../redux/modules/briefings/selectors';
import { updateUserTopicsAction } from '../../redux/modules/briefings/actions';
import { BriefingTopic } from '../../redux/modules/briefings/schemas';
import mapTopicToEmojiTopic from '../../utils/topicEmojis';


const TopicSelector: React.FC = () => {
  const dispatch = useDispatch();
  const briefingTopics = useSelector(getBriefingTopics);
  const userTopics = useSelector(getUserTopics);
  const [noteVisible, setNoteVisible] = useState(false);

  const isTopicSelected = (topicId: number) => userTopics.some((topic) => topic.id === topicId);

  const handleSwitchChange = (topic: BriefingTopic) => {
    if (isTopicSelected(topic.id)) {
      if (userTopics.length <= 3) {
        setNoteVisible(true);
        setTimeout(() => setNoteVisible(false), 3000);
        return;
      }
    }
    dispatch(updateUserTopicsAction(topic));
  };

  return (
    <div className="settings-section">
      <h2>Topics</h2>
      <p>Select topics you want to get up to speed on in your briefings (select at least three).</p>
      <div className="switch-list">
        {briefingTopics.map((topic) => (
          <div className="switch-item" key={topic.id}>
            <Switch
              checked={isTopicSelected(topic.id)}
              onChange={() => handleSwitchChange(topic)}
            />
            <span className="switch-item-title">{mapTopicToEmojiTopic(topic.name)}</span>
            <span className="switch-item-meta">{topic.description}</span>
          </div>
        ))}
        {noteVisible && <p className="topic-error-note">Must have at least 3 topics selected.</p>}
      </div>
    </div>
  );
};

export default TopicSelector;
