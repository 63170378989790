/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import { mapSourceToLogo } from '../../utils/logoSources';
import { ConnectedSource } from '../../redux/modules/appIntegrations/schemas';
import { get } from '../../redux/common/rest';
import { AuthUrl, OauthUrl, vOauthUrl } from '../../redux/modules/common/schemas';


interface SourceListProps {
    source: ConnectedSource;
  }

const SourceList: React.FC<SourceListProps> = ({ source }) => {
  const [authLink, setAuthLink] = useState('');

  useEffect(() => {
    const fetchOauthUrl = async () => {
      try {
        const response = await get<AuthUrl, OauthUrl>(vOauthUrl, '/api/v1.0/oauth/install', { app_name: source.name });
        setAuthLink(response.url);
      } catch (error) {
        console.error('Failed to fetch OAuth URL:', error);
      }
    };
    fetchOauthUrl();
  }, []);


  const handleClick = () => {
    window.open(authLink, '_blank', 'width=700,height=600');
  };

  return (
    <div key={source.name} className="settings-source-item">
      <img className="source-icon" src={mapSourceToLogo(source.name)} alt={`${source.name} Icon`} />
      <div className="item-title">{`${source.name} is connected! ✨`}</div>
      <div>{source.meta}</div>
      <Button type="link" className="no-padding-button-link" onClick={() => { handleClick(); }}>Reconnect</Button>
    </div>
  );
};

export default SourceList;
