import { StatusMessage } from '../common/types';


export const GET_JIRA_OAUTH_REQUEST = 'jira/GET_JIRA_OAUTH_REQUEST';
export const GET_JIRA_OAUTH_URL_SUCCESS = 'jira/GET_JIRA_OAUTH_URL_SUCCESS';
export const GET_JIRA_OAUTH_FAILURE = 'jira/GET_JIRA_OAUTH_FAILURE';
export const GET_JIRA_OAUTH_SUCCESS = 'jira/GET_JIRA_OAUTH_SUCCESS';
export const RESET_JIRA_OAUTH_SUCCESS = 'jira/RESET_JIRA_OAUTH_SUCCESS';
export const RESET_JIRA_OAUTH = 'jira/RESET_JIRA_OAUTH';
export const GET_JIRA_BOARDS = 'jira/GET_JIRA_BOARDS';
export const SET_JIRA_BOARD = 'jira/SET_JIRA_BOARD';
export const GET_JIRA_SPRINTS = 'jira/GET_JIRA_SPRINTS';
export const SET_JIRA_SPRINT_ID = 'jira/SET_JIRA_SPRINT_ID';
export const SET_JIRA_SPRINT_REPORT = 'jira/SET_JIRA_SPRINT_REPORT';
export const SET_JIRA_INSTALL_ID = 'jira/SET_JIRA_INSTALL_ID';
export const JIRA_LOGOUT = 'jira/LOGOUT';
export const SET_JIRA_DATE_RANGE_REPORT = 'jira/SET_JIRA_DATE_RANGE_REPORT';
export const SET_JIRA_DATE_RANGE = 'jira/SET_JIRA_DATE_RANGE';
export const SET_JIRA_REPORT_ID = 'jira/SET_JIRA_REPORT_ID';


export interface SetJiraReportId {
  type: typeof SET_JIRA_REPORT_ID;
  payload: {
    reportId: string
  }
}
export interface SetDateRange {
  type: typeof SET_JIRA_DATE_RANGE;
  payload: {
    dateRange: [string, string]
  }
}

export interface SetJiraDateRangeReport {
  type: typeof SET_JIRA_DATE_RANGE_REPORT;
  payload: {
    report: string
  }
}
export interface SetJiraInstallId {
  type: typeof SET_JIRA_INSTALL_ID;
  payload: {
    jiraInstallId: number
  }
}

export interface SetJiraSprintReport {
  type: typeof SET_JIRA_SPRINT_REPORT;
  payload: {
    report: string
  }
}
export interface SetJiraSprint {
  type: typeof SET_JIRA_SPRINT_ID;
  payload: {
    jiraSprintId: number
  }
}
export interface SetJiraBoard {
  type: typeof SET_JIRA_BOARD;
  payload: {
    jiraBoard: {id: number, name: string}
  }
}
export interface GetJiraSprints {
  type: typeof GET_JIRA_SPRINTS;
  payload: {
    jiraSprints: {name: string, id: number}[]
  }
}
export interface GetJiraBoards {
  type: typeof GET_JIRA_BOARDS;
  payload: {
    jiraBoards: {name: string, id: number}[]
  }
}
export interface GetJiraOauthUrlRequestAction {
  type: typeof GET_JIRA_OAUTH_REQUEST;
}

export interface GetJiraOauthUrlSuccessAction {
  type: typeof GET_JIRA_OAUTH_URL_SUCCESS;
  payload: {
    url: string;
    state: string;
  };
}

export interface GetJiraOauthUrlFailureAction {
  type: typeof GET_JIRA_OAUTH_FAILURE;
  payload: StatusMessage;
}


export interface getJiraOauthSuccessAction {
  type: typeof GET_JIRA_OAUTH_SUCCESS;
  payload: {
    oauthStatusResponse: boolean;
    jiraOauthStatus: boolean;
  }
}

export interface ResetJiraOauthSuccessAction {
  type: typeof RESET_JIRA_OAUTH_SUCCESS;
}

export interface ResetJiraOauthState {
  type: typeof RESET_JIRA_OAUTH;
}

export interface JiraLogout {
  type: typeof JIRA_LOGOUT
}

export type JiraAction = ResetJiraOauthSuccessAction | SetJiraReportId | SetDateRange | SetJiraDateRangeReport | JiraLogout | SetJiraInstallId | SetJiraSprintReport | SetJiraSprint | SetJiraBoard | GetJiraSprints | GetJiraBoards | ResetJiraOauthState | GetJiraOauthUrlRequestAction | GetJiraOauthUrlSuccessAction | GetJiraOauthUrlFailureAction | getJiraOauthSuccessAction

export interface JiraReducer {
  jiraOauthUrl: string;
  oauthState: string;
  jiraOauthStatus: boolean;
  oauthStatusResponse: boolean;
  jiraBoards: {name: string, id: number}[];
  jiraBoard?: {id: number, name: string};
  jiraSprints: {name: string, id: number}[];
  jiraSprint?: number;
  reportId: string;
  jiraSprintReport: string;
  jiraDateRangeReport: string;
  jiraInstallId?: number | null;
  dateRange: [string, string]
}
