import { ConnectedSources } from './schemas';

export const GET_CONNECTED_SOURCES = 'appInt/GET_CONNECTED_SOURCES';
export const GET_CONNECTED_SOURCES_SUCCESS = 'appInt/GET_CONNECTED_SOURCES_SUCCESS';
export const GET_CONNECTED_SOURCES_FAILURE = 'appInt/GET_CONNECTED_SOURCES_FAILURE';
export const TRIGGER_OAUTH_REFRESH = 'appInt/TRIGGER_OAUTH_REFRESH';
export interface AppIntGetConnectedSourcesSuccess {
  type: typeof GET_CONNECTED_SOURCES_SUCCESS;
  payload: ConnectedSources
}

export interface TriggerOauthRefreshAction {
  type: typeof TRIGGER_OAUTH_REFRESH;
}

export type AppIntegrationsAction = AppIntGetConnectedSourcesSuccess | TriggerOauthRefreshAction

export interface AppIntegrationsReducer {
  sources: ConnectedSources
  refresh: boolean
}
