import * as t from 'io-ts';

export const vSubmitted = t.interface({
  submitted: t.boolean,
});

export type Submitted = t.TypeOf<typeof vSubmitted>;


export const vAuthUrl = t.interface({
  app_name: t.string,
});

export type AuthUrl = t.TypeOf<typeof vAuthUrl>;

export const vOauthUrl = t.interface({
  url: t.string,
  state: t.string,
});
export type OauthUrl = t.TypeOf<typeof vOauthUrl>;

export const vOauthApps = t.interface({
  oauth_success: t.boolean,
});

export type OauthApps = t.TypeOf<typeof vOauthApps>;

export const vApiResponse = t.interface({
  detail: t.string,
});

export type ApiResponse = t.TypeOf<typeof vApiResponse>;

export const vIdTitle = t.interface({
  id: t.string,
  title: t.string,
});

export type IdTitle = t.TypeOf<typeof vIdTitle>;

export const vIdTitlesResponse = t.readonlyArray(vIdTitle);
export type IdTitlesResponse = t.TypeOf<typeof vIdTitlesResponse>;

export const vSchemaUser = t.interface({
  id: t.number,
  flags: t.union([t.string, t.undefined]),
});


export const vWorkspace = t.interface({
  workspace_id: t.string,
  name: t.string,
  users: t.readonlyArray(
    t.interface({
      username: t.string,
      email: t.string,
      id: t.number,
      flags: t.union([t.null, t.number, t.string]),
    }),
  ),
  primary_slack_install_id: t.union([t.null, t.number]),
  primary_notion_install_id: t.union([t.null, t.number]),
  is_owner: t.boolean,
});


export const vWorkspaceResponse = t.readonlyArray(vWorkspace);
export type WorkspaceResponse = t.TypeOf<typeof vWorkspaceResponse>;

export const vNotionDatabase = t.interface({
  id: t.string,
  database_id: t.string,
});

export type NotionDatabse = t.TypeOf<typeof vNotionDatabase>;
