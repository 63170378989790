import * as t from 'io-ts';

export interface AuthRequest {
  email: string;
  password?: string;
}


export const vUserAuthDetails = t.interface({
  userId: t.number,
  firstName: t.string,
  lastName: t.string,
});

export type UserAuthDetails = t.TypeOf<typeof vUserAuthDetails>;

export const vUserModel = t.interface({
  id: t.number,
  email: t.string,
  username: t.string,
  flags: t.union([t.string, t.null]),
  registration_step: t.union([t.number, t.null, t.undefined]),
});

export type UserModel = t.TypeOf<typeof vUserModel>;



export const vUsers = t.array(vUserModel);

export type Users = t.TypeOf<typeof vUsers>;

export interface CognitoUser {
  [key: string]: any
}
