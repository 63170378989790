import { StatusMessage } from '../common/types';
import { Space } from './schemas';

export type { Space, SpaceUser } from './schemas';

export const SET_CURRENT_SPACE = 'space/SET_CURRENT_SPACE';

export const GET_USER_SPACES_REQUEST = 'space/GET_USER_SPACES_REQUEST';
export const GET_USER_SPACES_SUCCESS = 'space/GET_USER_SPACES_SUCCESS';
export const GET_USER_SPACES_FAILURE = 'space/GET_USER_SPACES_FAILURE';

export const CREATE_SPACE_REQUEST = 'space/CREATE_SPACE_REQUEST';
export const CREATE_SPACE_SUCCESS = 'space/CREATE_SPACE_SUCCESS';
export const CREATE_SPACE_FAILURE = 'space/CREATE_SPACE_FAILURE';

export const ADD_USER_REQUEST = 'space/ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'space/ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'space/ADD_USER_FAILURE';


export interface AddUserRequest {
    email: string;
}

export interface CreateSpaceRequest {
    title: string;
    description: string;
}

export interface SpaceReducerState {
    spaces: Space[],
    currentSpaceId: string;
}

export interface SetCurrentSpaceAction {
    type: typeof SET_CURRENT_SPACE;
    payload: {
        spaceId: string;
    }
}

export interface GetUserSpacesSuccessAction {
    type: typeof GET_USER_SPACES_SUCCESS;
    payload: {
        spaces: Space[];
    }
}

export interface GetUserSpacesFailureAction {
    type: typeof GET_USER_SPACES_FAILURE;
    payload: StatusMessage;
}

export interface CreateSpaceSuccessAction {
    type: typeof CREATE_SPACE_SUCCESS;
    payload: {
        space: Space;
    }
}

export interface CreateSpaceFailureAction {
    type: typeof CREATE_SPACE_FAILURE;
    payload: StatusMessage;
}

export interface AddUserSuccessAction {
    type: typeof ADD_USER_SUCCESS;
    payload: {
        space: Space;
    }
}

export interface AddUserFailureAction {
    type: typeof ADD_USER_FAILURE;
    payload: StatusMessage;
}

export type SpaceAction = GetUserSpacesFailureAction | GetUserSpacesSuccessAction | CreateSpaceFailureAction |
    CreateSpaceSuccessAction | SetCurrentSpaceAction | AddUserSuccessAction | AddUserFailureAction;
