import { Mentions } from './schemas';

export const GET_MENTIONS_LOADING = 'mentions/GET_MENTIONS_LOADING';
export const GET_MENTIONS_SUCCESS = 'mentions/GET_MENTIONS_SUCCESS';
export const GET_MENTIONS_FAILURE = 'mentions/GET_MENTIONS_FAILURE';


export interface GetMentionsLoadingAction {
    type: typeof GET_MENTIONS_LOADING;
    payload: {
        loading: boolean
    }
}

export interface GetMentionsSuccessAction {
    type: typeof GET_MENTIONS_SUCCESS;
    payload: {
        mentions: Mentions
        loading: boolean
    }
}

export interface GetMentionsFailureAction {
    type: typeof GET_MENTIONS_FAILURE;
    payload: {
        loading: boolean
    }
}


export type MentionsAction = GetMentionsLoadingAction | GetMentionsSuccessAction | GetMentionsFailureAction

export interface MentionsReducer {
    mentions: Mentions;
    loading: boolean;
}
