export const defaultPrompt = {
  source: 'Runway',
  start: '2024-02-01 00:0',
  prompts_name: 'Prompt 1',
  custom_task_prompt: 'Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Analyze the provided task activity data on the most critical updates of the day. Report around the following topics relevant to the role specified.  AReference issues by task name and include hyperlinks, and always provide context and detailed explanations. Only report on the following: general updates, challenges, things that need my review, wins to celebrate. Do not add any disclaimers about subjectivity, it is already implied.  Return markdown and reference tasks by Task Names.',
  custom_slack_prompt: "Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Base your report on on today's slack data.Report around the following topics relevant to the role specified.  Provide abundant detailed contextual information. Only report on the following: general updates, challenges, things that need my review, wins to announce. Do not add any disclaimers about subjectivity, it is already implied.",
  custom_multi_source_prompt: "Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Provided are briefings from two sources of data.  Refer to both sets of data and generate a single report, only presenting information appropriate for the user's role. Do not generalize, be specific and provide quotes from sources where appropriate.  Reference issues by task name and include hyperlinks, and always provide context and detailed explanations. Report only on the following topics: Only report on the following: general updates, challenges, things that need my review, wins to celebrate.",
};


export const userProfiles = {
  'Nadine Monsour': {
    role: 'Head of Product',
    interests: 'Strategic insights, Product vision alignment, Broad project milestones, not daily task specifics.',
  },
  'Siqi Chen': {
    role: 'CEO',
    interests: 'Overall company performance, financial health, strategic growth opportunities.',
  },
  'Annie Wu': {
    role: 'Chief of Staff',
    interests: 'Insights on organizational health, leadership team effectiveness, critical issue resolution.',
  },
  'Wells Riley': {
    role: 'Head of Design',
    interests: 'Design philosophy alignment, user experience innovation, product design strategy.',
  },
  'Mike K': {
    role: 'CEO',
    interests: 'Overall company performance, financial health, strategic growth opportunities.',
  },

};


export const default_doc = {
  _id: {
    $oid: '65d52c4f34431c9f5fa68ca8',
  },
  multi_source_briefing: '',
  multi_source_prompt: "Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Avoiding granular details or operational minutiae. Provided are briefings from two sources of data.  Refer to both sets of data and generate a single report, only presenting information appropriate for the user's role. Do not generalize, be specific and provide quotes from sources where appropriate.  Reference issues by task name and include hyperlinks, and always provide context and detailed explanations. Report only on the following topics: Only report on the following: general updates, challenges, things that need my review, wins to celebrate.",
  task_daily_briefing: '',
  task_prompt: 'Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Avoiding granular details or operational minutiae.. Analyze the provided task activity data on the most critical updates of the day. Report around the following topics relevant to the role specified.  AReference issues by task name and include hyperlinks, and always provide context and detailed explanations. Only report on the following: general updates, challenges, things that need my review, wins to celebrate. Do not add any disclaimers about subjectivity, it is already implied.  Return markdown and reference tasks by Task Names.',
  slack_daily_briefing: '',
  slack_prompt: "Your job is to create a Presidential Daily Briefing for Siqi Chen, the CEO of the company, who is interested in Overall company performance, financial health, and strategic growth opportunities. Avoiding granular details or operational minutiae. Base your report on on today's slack data.Report around the following topics relevant to the role specified.  Provide abundant detailed contextual information. Only report on the following: general updates, challenges, things that need my review, wins to announce. Do not add any disclaimers about subjectivity, it is already implied.",
  activity_type: 'Daily_Briefing',
  source: 'Runway',
  user_id: 1,
  timestamp: '2024-02-01 00:0',
  report_id: 'xCsV-zem0jtxlNGGDoTFp',
  prompts_name: 'Default CEO Prompt',
};
