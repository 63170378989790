/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  //Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faInboxIn, faPlus, faSun as faSunSolid, faCalendarWeek as faCalendarWeekSolid, faFileUser as faFileUserSolid,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faInbox, faSun, faCalendarWeek, faFileUser,
} from '@fortawesome/pro-duotone-svg-icons';


import './InboxView.scss';
import '../DemoPages.scss';
import { getBriefings } from '../../redux/modules/briefings/selectors';
import { Briefing } from '../../redux/modules/briefings/schemas';
import { markAsRead } from '../../redux/modules/briefings/actions';
import { getConnectedSources } from '../../redux/modules/appIntegrations/selectors';
import { ConnectedSource } from '../../redux/modules/appIntegrations/schemas';
import { sourceLogos } from '../../utils/logoSources';

interface Message {
  icon: string; // URL to the icon
  header: string;
  body: string[]; // Array of paragraphs
}

interface Thread {
  id: string;
  title: string;
  messages: Message[];
}

interface InboxViewProps {
  addNewThread: (newThread: Thread) => void;
}

const InboxView: React.FC<InboxViewProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const briefings = useSelector(getBriefings);
  const sources = useSelector(getConnectedSources);
  const [selectedTag, setSelectedTag] = useState('');

  const getIcon = (type: string) => {
    switch (type) {
      case 'DailyBriefing':
        return <FontAwesomeIcon icon={faSun} className="item-icon orange" />;
      case 'WeekInReview':
        return <FontAwesomeIcon icon={faCalendarWeek} className="item-icon red" />;
      case 'MonthlyTeamRetro':
        return <FontAwesomeIcon icon={faFileUser} className="item-icon blue" />;
      default:
        return <FontAwesomeIcon icon={faSun} className="item-icon orange" />;
    }
  };

  // Function to parse and format the date
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short', day: 'numeric', month: 'short' };
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return {
      weekDay: formattedDate.split(',')[0],
      monthDay: date.getDate(),
    };
  };


  // Group data by month
  const groupByMonth = (data: any[]) => data.reduce((acc, cur) => {
    const monthYear = new Date(cur.briefingDateTime).toLocaleString('default', { month: 'long', year: 'numeric' });
    if (!acc[monthYear]) {
      acc[monthYear] = [];
    }
    acc[monthYear].push(cur);
    return acc;
  }, {});

  const groupedData = useMemo(() => {
    const sortedData = briefings.sort((a, b) => new Date(b.briefingDateTime).getTime() - new Date(a.briefingDateTime).getTime());

    return groupByMonth(sortedData);
  }, [briefings]);

  // State for filtered data
  const [filteredData, setFilteredData] = useState(groupedData);

  useEffect(() => {
    setFilteredData(groupedData);
  }, [briefings]);

  // Function to handle tag selection with toggle functionality
  const handleTagSelect = (tag: string) => {
    if (selectedTag === tag) {
      // If the selected tag is clicked again, unselect it and show all data
      setSelectedTag('');
      setFilteredData(groupedData); // Reset to show all data
    } else {
      setSelectedTag(tag);
      // Filter data based on the newly selected tag
      const filtered: { [key: string]: any[] } = Object.keys(groupedData).reduce((acc, monthYear) => {
        const filteredBriefings = groupedData[monthYear].filter((briefing: Briefing) => (tag === 'Unread' ? briefing.isUnread : briefing.briefingType === tag));
        if (filteredBriefings.length > 0) {
          acc[monthYear] = filteredBriefings;
        }
        return acc;
      }, {} as { [key: string]: any[] }); // Add index signature to the type declaration

      setFilteredData(filtered);
    }
  };

  const handleClick = () => {
    history.push({
      pathname: '/settings',
    });
  };

  const handleBriefingClick = (e: React.MouseEvent<HTMLDivElement>, briefing: Briefing) => {
    e.preventDefault();

    if (briefing.isUnread) {
      dispatch(markAsRead(briefing.briefingId));
    }

    history.push({
      pathname: `/briefing/${briefing.briefingId}`,
    });
  };

  return (
    <div className="grid-container">
      <header className="grid-header">
        <div className="header-content-wrapper">
          <h1>
            <FontAwesomeIcon icon={faInbox} className="blue" />
            Archive
          </h1>
          <div className="tags">
            <Button className={`tag ${selectedTag === 'Unread' ? 'selected' : ''}`} icon={<FontAwesomeIcon icon={faInboxIn} />} onClick={() => handleTagSelect('Unread')}>Unread</Button>
            <Button className={`tag ${selectedTag === 'DailyBriefing' ? 'selected' : ''}`} icon={<FontAwesomeIcon icon={faSunSolid} className="orange" />} onClick={() => handleTagSelect('DailyBriefing')}>Daily Briefing</Button>
            <Button disabled className={`tag ${selectedTag === 'WeekInReview' ? 'selected' : ''}`} icon={<FontAwesomeIcon icon={faCalendarWeekSolid} className="red" />} onClick={() => handleTagSelect('WeekInReview')}>Weekly Review</Button>
            <Button disabled className={`tag ${selectedTag === 'MonthlyTeamRetro' ? 'selected' : ''}`} icon={<FontAwesomeIcon icon={faFileUserSolid} className="blue" />} onClick={() => handleTagSelect('MonthlyTeamRetro')}>Monthly Teams Retro</Button>
          </div>
          <div className="actions">
            <Button type="text" onClick={() => { handleClick(); }} icon={<FontAwesomeIcon icon={faPlus} />} />
            <div className="sources">
              {
                sources.map((source: ConnectedSource) => {
                  if (!source.isComingSoon && source.isConnected) {
                    return sourceLogos[source.name];
                  }
                  return null;
                })
              }
            </div>
          </div>
        </div>
      </header>
      <main className="grid-main">
        <div className="report-list-content">
          <div className="list">
            {Object.keys(filteredData).flatMap((monthYear) => [
              <h2 key={`header-${monthYear}`} className="report-list-header">{monthYear}</h2>,
              filteredData[monthYear].map((briefing: Briefing) => {
                // Here we call formatDate for each briefing item
                const { weekDay, monthDay } = formatDate(briefing.briefingDateTime);
                return (
                  <div key={`briefing-${briefing.briefingId}`} className={`list-item-day ${briefing.isUnread ? 'read-status-unread' : 'read-status-read'}`}>
                    <div className="read-status">
                      <div className="read-status-dot" />
                    </div>
                    {getIcon(briefing.briefingType)}
                    <div className="day-badge">
                      <div className="week-day">{weekDay}</div>
                      <div className="month-day">{monthDay}</div>
                    </div>
                    <div
                      className="day-item"
                      onClick={(e) => handleBriefingClick(e, briefing)}
                    >
                      <h2 className="list-item-title">
                        {briefing.briefingTitle}
                      </h2>
                      <p className="list-item-preview">
                        {briefing.briefingSummary}
                      </p>
                    </div>
                  </div>
                );
              }),
            ])}
          </div>
        </div>
        <aside />
      </main>
    </div>
  );
};

export default InboxView;
