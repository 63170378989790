import * as types from './types';

const defaultState: types.BriefingsReducer = {
  briefings: [],
  priorityTags: [],
  topics: [],
  userTopics: [],
  loading: true,
};


export default function githubReducer(state = defaultState, action: types.BriefingsAction): types.BriefingsReducer {
  switch (action.type) {
    case types.GET_BRIEFINGS_SUCCESS:
      return {
        ...state,
        briefings: action.payload.briefings,
        loading: action.payload.loading,
      };

    case types.GET_BRIEFINGS_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case types.GET_BRIEFINGS_FAILURE:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case types.MARK_AS_READ:
      return {
        ...state,
        briefings: state.briefings.map((briefing) => (briefing.briefingId === action.payload ? { ...briefing, isUnread: false } : briefing)),
      };

    case types.UPDATE_USER_TOPICS: {
      const topic = action.payload;
      console.log(topic);
      const exists = state.userTopics.some((t) => t.id === topic.id);

      return {
        ...state,
        userTopics: exists ?
          state.userTopics.filter((t) => t.id !== topic.id)
          : [...state.userTopics, topic],
      };
    }
    case types.UPDATE_PRIORITY_TAGS: {
      const tagIndex = state.priorityTags.findIndex((tag) => tag.id === action.payload.priorityTag.id);

      let updatedPriorityTags = [];

      if (tagIndex > -1) {
        updatedPriorityTags = [
          ...state.priorityTags.slice(0, tagIndex),
          ...state.priorityTags.slice(tagIndex + 1),
        ];
      } else {
        updatedPriorityTags = [...state.priorityTags, action.payload.priorityTag];
      }

      return {
        ...state,
        priorityTags: updatedPriorityTags,
      };
    }

    case types.GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        priorityTags: action.payload.priorityTags,
        topics: action.payload.topics,
        userTopics: action.payload.userTopics,
      };


    default:
      return state;
  }
}
