import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import PasswordChecklist from 'react-password-checklist';
import mp from '../../../mixpanel';
import { actions } from '../../../redux/modules/registration';


import logo from '../../../icons/maestro-logo.png';

import './RegisterForm.scss';

const blockedDomains = [
  'gmail.com',
  'yahoo.com',
  'yahoo.co.uk',
  'hotmail.com',
  'outlook.com',
  'live.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'zoho.com',
  'protonmail.com',
  'gmx.com',
  'yandex.com',
  'msn.com',
  'comcast.net',
  'sbcglobal.net',
  'att.net',
  'verizon.net',
  'me.com',
  'mac.com',
  'yahoo.co.in',
  'hotmail.co.uk',
  'live.co.uk',
  'yandex.ru',
  'web.de',
  'libero.it',
  'orange.fr',
  'qq.com',
  '163.com',
  '126.com',
  'sina.com',
  't-online.de',
  'rambler.ru',
  'wp.pl',
  'onet.pl',
];

interface FormValues {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
}

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [userPassword, setPassword] = useState<string>('');
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(false);


  const onFinish = (values: FormValues) => {
    const {
      password, email, firstName, lastName,
    } = values.user;
    dispatch(actions.register(email, password, firstName, lastName));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', errorInfo);
  };

  const validateEmailDomain = (_: any, value: string) => {
    if (value) {
      const emailDomain = value.toLowerCase().split('@')[1];
      if (blockedDomains.includes(emailDomain)) {
        mp.identify(value);
        mp.track('Registered Frontend', { value });
        return Promise.reject(new Error('Please use your work email. Personal accounts are not yet supported.'));
      }
    }
    return Promise.resolve();
  };

  return (
    <div className="registration-form-content-wrapper">
      <img src={logo} alt="maestro ai logo" className="maestro-logo" />
      <Form
        name="registration form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        className="registration-form"
      >
        <Form.Item
          name={['user', 'firstName']}
          label="First name"
          rules={[
            { required: true, message: 'First name is required', validateTrigger: 'onSubmit' },
          ]}
        >
          <Input
            className="registration-form-input"
            placeholder="First name"
          />
        </Form.Item>
        <Form.Item
          name={['user', 'lastName']}
          label="Last name"
          rules={[
            { required: true, message: 'Last name is required', validateTrigger: 'onSubmit' },
          ]}
        >
          <Input
            className="registration-form-input"
            placeholder="Last name"
          />
        </Form.Item>
        <Form.Item
          name={['user', 'email']}
          label="Email"
          rules={[
            { type: 'email', message: 'Not a valid email address', validateTrigger: 'onSubmit' },
            { required: true, message: 'Email address is required', validateTrigger: 'onSubmit' },
            { validator: validateEmailDomain },
          ]}
        >
          <Input
            className="registration-form-input"
            placeholder="name@work-email.com"
          />
        </Form.Item>
        <Form.Item
          name={['user', 'password']}
          label="Password "
          rules={[
            { required: true, message: 'Please enter a password!', validateTrigger: 'onSubmit' },
            { min: 2, message: 'Password must be at least 8 characters long!', validateTrigger: 'onSubmit' },
            {
              message: 'Password must contain at least one letter and one number!',
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Input.Password
            className="registration-form-input registration-form-password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        {userPassword && (
        <PasswordChecklist
          className="password-checklist"
          rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
          minLength={8}
          value={userPassword}
          onChange={(isValid) => setPasswordIsValid(isValid)}
        />
        )}

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('You must agree to the terms in order to create an account'))),
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Checkbox className="signup-legal-checkbox">
            {' '}
            I agree to the
            {' '}
            <b>
              <a className="legal-link" href="https://www.getmaestro.ai/terms-of-service" target="_blank" rel="noopener noreferrer">Terms</a>
            </b>
            {' '}
            and
            {' '}
            <b>
              <a className="legal-link" href="https://www.getmaestro.ai/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </b>
          </Checkbox>

        </Form.Item>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit" disabled={!passwordIsValid}>
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      <div className="registration-login-container">
        <span className="registration-login">
          Already have an account?
          {' '}
          <Link to="/login">
            <b>Log In</b>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default RegisterForm;
