import * as t from 'io-ts';

export interface CreateAccountRequest {
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
  }

export const vCreateAccountResponse = t.interface({
  username: t.string,
  email: t.string,
  id: t.number,
});

export type CreateAccountResponse = t.TypeOf<typeof vCreateAccountResponse>;
