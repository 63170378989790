import * as t from 'io-ts';

// Briefing Type
export const vBriefingTopic = t.type({
  id: t.number,
  name: t.string,
  description: t.string,
  report_type: t.union([t.literal('Daily'), t.literal('Monthly')]),
  is_active: t.boolean,
});
export type BriefingTopic = t.TypeOf<typeof vBriefingTopic>;

export const vBriefingTopics = t.array(vBriefingTopic);
export type BriefingTopics = BriefingTopic[];


export const vBriefingType = t.union([
  t.literal('DailyBriefing'),
  t.literal('WeekInReview'),
  t.literal('MonthlyTeamRetro'),
]);
export type BriefingType = t.TypeOf<typeof vBriefingType>;



// Briefing Item - This can just be turned into t.string
export const vSource = t.union([
  t.literal('Slack'),
  t.literal('GitHub'),
  t.literal('Google'),
  t.literal('Google Drive'),
  t.literal('Notion'),
  t.literal('Zoom'),
  t.literal('Jira'),
  t.literal('Height'),
  t.literal('LinkedIn'),
  t.literal('Figma'),
  t.literal('Other'),
  t.string,
]);

export const vBriefingItemLink = t.type({
  href: t.string,
  text: t.string,
  source: vSource,
  channelName: t.union([t.string, t.undefined]),
});

export type BriefingItemLink = t.TypeOf<typeof vBriefingItemLink>;


export const vBriefingItemTag = t.type({
  id: t.number,
  tag_name: t.string,
});

export type BriefingItemTag = t.TypeOf<typeof vBriefingItemTag>;

export type BriefingItemTags = BriefingItemTag[];

export const vBriefingItemTags = t.array(vBriefingItemTag);

export const vBriefingItem = t.type({
  id: t.string,
  topic: t.string,
  title: t.string,
  summary: t.string,
  body: t.string,
  tags: t.array(vBriefingItemTag),
  links: t.array(vBriefingItemLink),
});

export type BriefingItem = t.TypeOf<typeof vBriefingItem>;

const vSourceData = t.type({
  message: t.union([t.string, t.undefined]),
  user_name: t.union([t.string, t.undefined]),
});

const vTaskContext = t.type({
  task_update: t.string,
  list_name: t.string,
  task_description: t.string,
  permalink: t.string,
  channel_name: t.string,
  task_name: t.union([t.string, t.undefined]),
  source_data: t.union([t.array(vSourceData), t.undefined]),
});

const vChatContext = t.type({
  channel_name: t.string,
  permalink: t.string,
  messages: t.array(t.union([t.string, t.null])),
  source_data: t.union([t.array(vSourceData), t.undefined]),
});

const vTableContext = t.type({
  source: t.string,
  context: t.union([t.array(vChatContext), t.array(vTaskContext)]),
});

export type TableContext = t.TypeOf<typeof vTableContext>

const vTableResponses = t.type({
  source: t.string,
  table: t.string,
});

// Briefing
export const vBriefing = t.type({
  briefingId: t.string,
  briefingType: vBriefingType,
  briefingDateTime: t.string,
  briefingTitle: t.string,
  briefingSummary: t.string,
  isUnread: t.boolean,
  briefingItems: t.array(vBriefingItem),
  briefingTokens: t.union([t.number, t.undefined]),
  briefingSources: t.union([t.array(t.string), t.undefined]),
  tableResponses: t.union([t.array(vTableResponses), t.undefined]),
  briefingContext: t.union([t.array(vTableContext), t.undefined]),
  timeSaved: t.union([t.string, t.undefined]),
  end_unix: t.union([t.number, t.undefined]),
});

export type Briefing = t.TypeOf<typeof vBriefing>;
export type Briefings = Briefing[];
export const vBriefings = t.array(vBriefing);

export const vUserSettings = t.type({
  priorityTags: vBriefingItemTags,
  topics: vBriefingTopics,
  userTopics: vBriefingTopics,
});

export type UserSettings = t.TypeOf<typeof vUserSettings>;


export const vBriefingRef = t.type({
  briefingId: t.string,
  briefingDateTime: t.string,
});
export const vBriefingRefs = t.array(vBriefingRef);

export type BriefingRef = t.TypeOf<typeof vBriefingRef>;
export type BriefingRefs = BriefingRef[]
