import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Amplify } from 'aws-amplify';

import store, { persistor } from './redux/store';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const Root = () => {
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/api/v1.0/awsConfig');
        const awsConfig = await response.json();
        Amplify.configure(awsConfig);
        setConfigLoaded(true);
      } catch (error) {
        console.error('Error fetching AWS config:', error);
      }
    };

    fetchConfig();
  }, []);

  if (!configLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
