import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { selectors } from './redux/modules/auth';

const locationHelper = locationHelperBuilder({});

//when user is authenticated, they are redirected to /signup
export const userIsAuthenticatedRedir = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: selectors.getIsUserLoggedIn,
  wrapperDisplayName: 'UserIsAuthenticated',
});


export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: selectors.getIsUserLoggedOut,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const adminRedir = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: selectors.getIsUserLoggedInAsAdmin,
  wrapperDisplayName: 'userIsLoggedInAsAdmin',
});

export const registrationRedir = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: selectors.getIsUserRegistrationComplete,
  wrapperDisplayName: 'userCompletedRegistration',
});

export const userIsRegistered = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: selectors.getIsUserIsRegistered,
  wrapperDisplayName: 'UserIsRegistered',
});
