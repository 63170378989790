import { StatusMessage } from '../common/types';

export const GET_HEIGHT_OAUTH_REQUEST = 'height/GET_HEIGHT_OAUTH_REQUEST';
export const GET_HEIGHT_OAUTH_URL_SUCCESS = 'height/GET_HEIGHT_OAUTH_URL_SUCCESS';
export const GET_HEIGHT_OAUTH_FAILURE = 'height/GET_HEIGHT_OAUTH_FAILURE';
export const GET_HEIGHT_OAUTH_SUCCESS = 'height/GET_HEIGHT_OAUTH_SUCCESS';
export const RESET_HEIGHT_OAUTH_SUCCESS = 'height/RESET_HEIGHT_OAUTH_SUCCESS';
export const RESET_HEIGHT_OAUTH = 'height/RESET_HEIGHT_OAUTH';
export const HEIGHT_LOGOUT = 'height/LOGOUT';

export interface GetHeightOauthUrlRequestAction {
    type: typeof GET_HEIGHT_OAUTH_REQUEST;
  }

export interface GetHeightOauthUrlSuccessAction {
    type: typeof GET_HEIGHT_OAUTH_URL_SUCCESS;
    payload: {
      url: string;
      state: string;
    };
  }

export interface GetHeightOauthUrlFailureAction {
    type: typeof GET_HEIGHT_OAUTH_FAILURE;
    payload: StatusMessage;
  }

export interface GetHeightOauthSuccessAction {
    type: typeof GET_HEIGHT_OAUTH_SUCCESS;
    payload: {
        oauthStatusResponse: boolean;
        heightOauthStatus: boolean;
    }
}

export interface ResetHeightOathSuccessAction {
  type: typeof RESET_HEIGHT_OAUTH_SUCCESS;
}

export interface ResetHeightOauthState {
  type: typeof RESET_HEIGHT_OAUTH;
}

export interface HeightLogout {
  type: typeof HEIGHT_LOGOUT
}

export type HeightAction = ResetHeightOathSuccessAction | HeightLogout | ResetHeightOauthState | GetHeightOauthSuccessAction | GetHeightOauthUrlRequestAction | GetHeightOauthUrlSuccessAction | GetHeightOauthUrlFailureAction

export interface HeightReducer {
    heightOauthUrl: string;
    oauthState: string;
    heightOauthStatus: boolean;
    oauthStatusResponse: boolean;
}
