// actions
export const SETUP_SET_USER_TOPICS = 'registration/SETUP_SET_USER_TOPICS';
export const SETUP_SET_USER_TOPICS_SUCCESS = 'registration/SETUP_SET_USER_TOPICS_SUCCESS';
export const SETUP_SET_USER_TOPICS_FAILURE = 'registration/SETUP_SET_USER_TOPICS_FAILURE';
export const SETUP_UPDATE_SETUP_STEP = 'registration/SETUP_UPDATE_SETUP_STEP';
export const SETUP_UPDATE_SETUP_STEP_SUCCESS = 'registration/SETUP_UPDATE_SETUP_STEP_SUCCESS';
export const SETUP_UPDATE_SETUP_STEP_FAILURE = 'registration/SETUP_UPDATE_SETUP_STEP_FAILURE';
export const SETUP_SET_USER_ROLE = 'registration/SETUP_SET_USER_ROLE';
export const SETUP_SET_USER_ROLE_SUCCESS = 'registration/SETUP_SET_USER_ROLE_SUCCESS';
export const SETUP_SET_USER_ROLE_FAILURE = 'registration/SETUP_SET_USER_ROLE_FAILURE';

