/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMemoPad, faPlus, faAngleDown,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faStar, faPeopleArrows, faBarsFilter,
} from '@fortawesome/pro-solid-svg-icons';
import {
  Button, Dropdown, Space, Badge, Modal, Tag,
} from 'antd';

import CreateReportForm from '../CreateReportForm/CreateReportForm';
import SelectReportType from '../SelectReportType/SelectReportType';

import slackLogo from '../../icons/connection-slack.svg';
import jiraLogo from '../../icons/connection-jira.svg';
import notionLogo from '../../icons/connection-notion.svg';
import githubLogo from '../../icons/connection-github.svg';
import zoomLogo from '../../icons/connection-zoom.svg';
//import heightLogo from '../../../icons/connection-height.png';

import './ReportsPage.scss';
import '../DemoPages.scss';

const items = [
  {
    key: '1',
    label: '1st item',
  },
  {
    key: '2',
    label: '2nd item',
  },
  {
    key: '3',
    label: '3rd item',
  },
];

type ReportFormData = {
  title: string,
  emoji: string,
  prompt: string,
  frequency: string,
};


const reportTypeData: ReportFormData[] = [
  {
    title: 'Daily Briefing',
    emoji: '🌅',
    prompt: 'Generate a daily briefing report summarizing key updates, messages, and action items from communication platforms, project management tools, and document sharing applications. Highlight urgent matters, upcoming deadlines, and any significant changes in project statuses that are critical for the start of the business day. Include a brief overview of new documents or discussions that require immediate attention.',
    frequency: 'Daily',
  },
  {
    title: 'Bug Progress Report',
    emoji: '🪲',
    prompt: 'Create a comprehensive bug progress report using data from the past week. Analyze bug tickets from project management and tracking tools, categorizing them by status: new, in progress, resolved, or closed. Summarize the efforts taken to address these bugs, including any debugging processes, code reviews, or testing phases completed. Highlight key challenges faced, solutions implemented, and any outstanding issues that require further attention.',
    frequency: 'Weekly',
  },
  {
    title: 'Release Notes',
    emoji: '🚀',
    prompt: 'Compile release notes detailing the new features, improvements, and bug fixes shipped by the development team this week. Extract information from version control systems, project management tools, and team communication channels to outline the contributions made to each part of the product. Emphasize significant enhancements that add value to the user experience and acknowledge the team members who played pivotal roles in these developments.',
    frequency: 'Weekly',
  },
  {
    title: 'Weekly Status Report',
    emoji: '✅',
    prompt: 'Generate a weekly status report summarizing the team\'s achievements, pending tasks, and upcoming objectives. Review project management tools and communication platforms to evaluate progress against planned milestones. Document completed projects, ongoing initiatives, and any roadblocks encountered. Provide an outlook for the next week, including priority tasks, deadlines, and any anticipated challenges that may impact project timelines.',
    frequency: 'Weekly',
  },
  {
    title: 'Engineering Efficiency',
    emoji: '🏗️',
    prompt: 'Analyze data related to code commits, pull requests, code review times, and incident reports to evaluate the engineering team\'s efficiency over the past month. Identify trends in productivity, such as average time to resolve issues, frequency of code deployments, and the volume of work completed. Discuss areas of strength and opportunities for improvement, suggesting actionable steps to enhance workflow processes, reduce bottlenecks, and improve overall team performance.',
    frequency: 'Weekly',
  },
];

const ReportsPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1); // Step 1: SelectReportType, Step 2: CreateReportForm
  const [selectedReportType, setSelectedReportType] = useState('');

  const showModal = () => {
    setIsModalOpen(true);
    setCurrentStep(1); // Reset to first step whenever modal is opened
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleReportTypeSelection = (type: string) => {
    setSelectedReportType(type);
    setCurrentStep(2); // Move to the next step
  };

  const handleSubmit = () => {
    setIsModalOpen(false);
  };

  const handleBack = () => {
    setCurrentStep(1);
    setSelectedReportType('');
  };

  // Render component based on current step
  const renderModalContent = () => {
    switch (currentStep) {
      case 1:
        return <SelectReportType onSelectionChange={handleReportTypeSelection} />;
      case 2:
        const reportData = reportTypeData.find((data) => data.title === selectedReportType);
        if (reportData) {
          return (
            <CreateReportForm
              onSubmit={handleSubmit}
              onBack={handleBack}
              reportType={selectedReportType}
              reportTitle={reportData.title}
              reportEmoji={reportData.emoji}
              reportFrequency={reportData.frequency}
              reportPrompt={reportData.prompt}
            />
          );
        }
        return <CreateReportForm onSubmit={handleSubmit} onBack={handleBack} />;
      default:
        return null;
    }
  };

  // const dispatch = useDispatch();
  // const pages = useSelector(selectors.getPages);

  // const [dialogOpen, setDialogOpen] = useState(false);
  // useEffect(() => {
  //   dispatch(actions.getUserPages());
  // }, []);


  // const onSetDialogOpen = useCallback((isOpen: boolean) => {
  //   setDialogOpen(isOpen);
  // }, [setDialogOpen]);

  // const clickCreateCallback = () => {
  //   onSetDialogOpen(true);
  // };

  // const createCallback = (title: string, desc: string, query: string) => {
  //   dispatch(actions.createPage(title, desc, query, undefined, true));
  // };

  /*
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.getmaestro.ai/">
          Ask AI
        </a>
      ),
      icon: <FontAwesomeIcon icon={faMessageBot} />,
    },
    {
      key: '2',
      label: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a href="#" onClick={() => showModal()}>
          Generate a Ticket
        </a>
      ),
      icon: <FontAwesomeIcon icon={faCircleCheck} />,
    },
    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.getmaestro.ai/">
          Generate Documentation
        </a>
      ),
      icon: <FontAwesomeIcon icon={faMemo} />,
    },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.getmaestro.ai/">
          Generate a Post-mortem
        </a>
      ),
      icon: <FontAwesomeIcon icon={faFlag} />,
    },
  ];
  */

  console.log('ActivityPage.tsx');

  return (
    <div className="page-container">
      <div className="page">
        <div className="maestro-page-header maestro-reports-demo-header">
          <div className="wrapper">
            <h1>
              <FontAwesomeIcon className="blue" icon={faMemoPad} />
              Reports
            </h1>
            <div className="tags">
              <Button className="tag" icon={<FontAwesomeIcon icon={faStar} />}>Starred</Button>
              <Button className="tag" icon={<FontAwesomeIcon icon={faPeopleArrows} />}>Shared with Me</Button>
              <Dropdown className="tag" menu={{ items }}>
                <Button icon={<FontAwesomeIcon icon={faBarsFilter} />}>
                  <Space>
                    Most Recent
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Space>
                </Button>
              </Dropdown>
            </div>
            <div className="actions">
              <Button onClick={showModal} type="primary" size="large" icon={<FontAwesomeIcon icon={faPlus} />}>
                Add a Report
              </Button>
            </div>
          </div>
          {/*
          <div className="page-header">
            <h1>
              <FontAwesomeIcon className="blue" icon={faInbox} />
              Inbox
            </h1>
          <h1>
            <FontAwesomeIcon className="blue" icon={faMemoPad} />
            Reports
          </h1>
          <div className="header-action">
            <Button type="primary" size="large" icon={<FontAwesomeIcon icon={faPlus} />}>
              Add a Report
            </Button>
          </div>
          */}
        </div>
        <div className="page-content maestro-page-content">

          <div className="grid">
            <ul>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">🌅</span>
                        <span className="item-title">Daily Briefing</span>
                        <Badge color="#3273dc" count={5} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Medusa Failure: Major Outage
                        </h3>
                        <p>
                          Yesterday, there was a major system outage. Carlos noticed the issue first, and he quickly notified the team and began gathering gathering logs data and metrics to understand the scope and potential cause of the problem.
                        </p>
                      </div>
                      <div className="item-tag-list">
                        <Tag className="tag">Urgent</Tag>
                        <Tag className="tag">Outage</Tag>
                      </div>
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={zoomLogo} alt="zoom" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated 3 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">💻</span>
                        <span className="item-title">Customer Experience</span>
                        <Badge color="#3273dc" count={3} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Mobile App Feedback
                        </h3>
                        <p>
                          The latest version of the app has received mixed reviews. Although users are praising the sleek design, some are experiencing crashes on older Android devices. Michael collated user feedback and possible solutions.
                        </p>
                      </div>
                      <div className="item-tag-list" />
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={notionLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated yesterday</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">👥</span>
                        <span className="item-title">Release Notes</span>
                        <Badge color="#3273dc" count={1} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          New UX Shipped
                        </h3>
                        <p>
                          The new user interface has been shipped to all users. The team is tracking any bugs or issues that may have been introduced. Our planned major infrastructure improvements did not ship and are still in code review.
                        </p>
                      </div>
                      <div className="item-tag-list">
                        <Tag className="tag">Needs Review</Tag>
                      </div>
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={githubLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated Friday</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">📅</span>
                        <span className="item-title">Weekly Progress</span>
                        <Badge count={1} color="#3273dc" />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Ramping up for GA
                        </h3>
                        <p>
                          The team is on track to deliver all Q4 milestones. The new project management tool has been a hit with the team, and we are seeing a 30% increase in productivity. The team is also excited about the new features.
                        </p>
                      </div>
                      <div className="item-tag-list" />
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={zoomLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated Fridy</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">🎯</span>
                        <span className="item-title">Recruiting</span>
                        <Badge count={0} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Recruiter Proposal Updates
                        </h3>
                        <p>
                          Two recruiting firms have sent proposals to manager our Q4 hiring. Michael has vetted both and is waiting for you to decide on which firm to proceed with, or if we should continue our in-house recruitment.
                        </p>
                      </div>
                      <div className="item-tag-list">
                        <Tag className="tag">Awaiting Decision</Tag>
                      </div>
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={zoomLogo} alt="jira" />
                          <img src={notionLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated Tuesday</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">📈</span>
                        <span className="item-title">Monthly Review</span>
                        <Badge count={0} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          December on Fire
                        </h3>
                        <p>
                          The team has delivered 90% of the features planned for the month. The new feature has been a hit with users, and we are seeing a 20% increase in user engagement. The team is also excited about the new features.
                        </p>
                      </div>
                      <div className="item-tag-list" />
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={zoomLogo} alt="zoom" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated 2 weeks ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">👨‍💻</span>
                        <span className="item-title">Engineering</span>
                        <Badge count={0} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Data Integrity Concerns
                        </h3>
                        <p>
                          Benjamin found discrepancies in the data being fed into Athena from third-party integrations. It seems some data might be getting duplicated or lost in transit. He&lsquo;s working with Sophia to trace the data flow and pinpoint the bottleneck.
                        </p>
                      </div>
                      <div className="item-tag-list">
                        <Tag className="tag">Risk</Tag>
                      </div>
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={githubLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated 2 weeks ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link className="gallery-link" to="/reports/report">
                  <div className="item">
                    <div className="thumbnail">
                      <h1>
                        <span className="item-icon">🌕</span>
                        <span className="item-title">Project Apollo</span>
                        <Badge count={0} />
                      </h1>
                      <div className="mini-feed">
                        <h3 className="mini-feed-title">
                          Team Growth
                        </h3>
                        <p>
                          Two new engineers, Mia and Jake, have joined our team this week. They come with a wealth of experience in cloud services, and have already started contributing to Project Pegasus. Their onboarding status and tasks can be tracked in Notion.
                        </p>
                      </div>
                      <div className="item-tag-list" />
                      <div className="footer">
                        <div className="sources">
                          <img src={slackLogo} alt="slack" />
                          <img src={jiraLogo} alt="jira" />
                          <img src={notionLogo} alt="notion" />
                        </div>
                        <div className="update-date">
                          <span className="date">Updated 3 weeks ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        className="report-modal"
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        title={currentStep === 1 ? 'Select Report Type' : 'Create Report'}
        okText="Save"
        width={750}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default ReportsPage;
