import { AutomationsArray } from './schemas';

export const CREATE_AUTOMATION = 'automations/CREATE_AUTOMATION';
export const CREATE_AUTOMATION_SUCCESS = 'automations/CREATE_AUTOMATION_SUCCESS';
export const CREATE_AUTOMATION_ERROR = 'automations/CREATE_AUTOMATION_ERROR';
export const GET_AUTOMATION = 'automations/GET_AUTOMATION';
export const GET_AUTOMATION_SUCCESS = 'automations/GET_AUTOMATION_SUCCESS';
export const GET_AUTOMATION_ERROR = 'automations/GET_AUTOMATION_ERROR';
export const AUTOMATIONS_LOGOUT = 'automations/AUTOMATIONS_LOGOUT';
export const DELETE_AUTOMATION = 'automations/DELETE_AUTOMATION';
export const DELETE_AUTOMATION_SUCCESS = 'automations/DELETE_AUTOMATION_SUCCESS';
export const DELETE_AUTOMATION_ERROR = 'automations/DELETE_AUTOMATION_ERROR';


export interface DeleteAutomation {
  type: typeof DELETE_AUTOMATION
}


export interface DeleteAutomationSuccess {
  type: typeof DELETE_AUTOMATION_SUCCESS
}

export interface DeleteAutomationError {
  type: typeof DELETE_AUTOMATION_ERROR
}
export interface GetAutomation {
  type: typeof GET_AUTOMATION
}

export interface GetAutomationSuccess {
  type: typeof GET_AUTOMATION_SUCCESS
  payload: AutomationsArray
}

export interface GetAutomationError {
  type: typeof GET_AUTOMATION_ERROR
}

export interface CreateAutomation {
  type: typeof CREATE_AUTOMATION;
}

export interface CreateAutomationError {
  type: typeof CREATE_AUTOMATION_ERROR;
}


export interface CreateAutomationSuccess {
  type: typeof CREATE_AUTOMATION_SUCCESS;
}

export interface AutomationsLogout {
  type: typeof AUTOMATIONS_LOGOUT
}

export type AutomationsAction = CreateAutomation | GetAutomation | GetAutomationSuccess | GetAutomationError | AutomationsLogout | CreateAutomationError | CreateAutomationSuccess | DeleteAutomationSuccess | DeleteAutomation | DeleteAutomationError

export interface AutomationsReducer {
  automations: AutomationsArray;
}
