import React, { useEffect } from 'react';
import './App.css';
import { Hub, Auth } from 'aws-amplify';

import { Switch, Route, useHistory } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import { ConfigProvider } from 'antd';
// import FullStory from 'react-fullstory';

// import Main from './components/Main/Main';
import { useDispatch, useSelector } from 'react-redux';
import LoginPage from './components/LoginPage/LoginPage';
import SignUp from './components/SignUp/SignUp';
import Main from './components/Main/Main';
import { getBriefings, getUserSettings } from './redux/modules/briefings/actions';
import { fetchConnectedSources } from './redux/modules/appIntegrations/actions';
import { history } from './redux/store';
import {
  userIsAuthenticatedRedir, userIsNotAuthenticatedRedir, adminRedir, registrationRedir,
  userIsRegistered,
} from './auth';
import OauthSuccessPage from './components/SignUp/RegistrationSteps/OauthSuccessPage/OauthSuccessPage';
import SignUpSteps from './components/SignUpSteps/SignUpSteps';
import { getAccessTokenExpiration, getIsUserLoggedIn } from './redux/modules/auth/selectors';
import { authEvent, logout, updateCognitoTokens } from './redux/modules/auth/actions';
import VerifyEmailForm from './components/SignUp/VerifyEmailForm/VerifyEmailForm';
import PasswordReset from './components/PasswordReset/PasswordReset';
import { getMentions } from './redux/modules/mentions/actions';

const App: React.FC = () => {
  const userLoggedIn = useSelector(getIsUserLoggedIn);
  const accessTokenExpiration = useSelector(getAccessTokenExpiration);
  const dispatch = useDispatch();
  const routerHistory = useHistory();

  useEffect(() => {
    if (userLoggedIn) {
      dispatch(getBriefings());
      dispatch(getUserSettings());
      dispatch(getMentions());
      dispatch(fetchConnectedSources());
    } else {
      dispatch(logout());
    }
  }, [userLoggedIn]);

  useEffect(() => {
    async function validateAndRefreshToken() {
      const currentTime = Math.floor(Date.now() / 1000);
      if (accessTokenExpiration) {
        const tokenExpired = currentTime > accessTokenExpiration;
        if (tokenExpired) {
          try {
            const session = await Auth.currentSession();
            dispatch(updateCognitoTokens(session));
          } catch (error) {
            console.error('Error refreshing token:', error);
            dispatch(logout());
          }
        }
      }
    }
    validateAndRefreshToken();
  }, []);

  const listenToAutoSignInEvent = (onAuthEvent: (user: any) => void) => {
    Hub.listen('auth', ({ payload }) => {
      onAuthEvent(payload);
    });
  };

  useEffect(() => {
    const onAuthEvent = (payload: any) => {
      dispatch(authEvent(payload));
    };

    listenToAutoSignInEvent(onAuthEvent);

    return () => {
      Hub.remove('auth', onAuthEvent);
    };
  }, [routerHistory]);


  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4845c9',
          fontFamily: '"Inter", sans-serif',
        },
      }}
    >
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/steps" component={registrationRedir(SignUpSteps)} />
          <Route path="/signup" component={SignUp} />
          <Route path="/login" component={userIsNotAuthenticatedRedir(LoginPage)} />
          <Route path="/verifyEmail" component={userIsRegistered(VerifyEmailForm)} />
          <Route path="/oauthsuccess/:app?" component={OauthSuccessPage} />
          <Route
            exact
            strict
            path="/forgotPassword/:code?"
            component={userIsNotAuthenticatedRedir(PasswordReset)}
          />
          <Route
            path="/"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/settings"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/home"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/jiraReports"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/promptSandbox"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/chat"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/maestroExplainer"
            component={userIsAuthenticatedRedir(Main)}
          />
          <Route
            path="/admin"
            component={adminRedir(Main)}
          />
          <Route
            path="/briefingViewer"
            component={adminRedir(Main)}
          />
        </Switch>
      </ConnectedRouter>
    </ConfigProvider>
  );
};

export default App;
