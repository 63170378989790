/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { } from 'react';
import { Card } from 'antd';

import { TableContext } from '../../redux/modules/briefings/schemas';

interface AdminContextProps {
    context: TableContext
}

const AdminContextView: React.FC<AdminContextProps> = ({ context }) => (
  <div style={{ margin: '20px' }}>
    {context.source === 'Slack' && context.context.map((item) => {
      if ('messages' in item) {
        return (
          <div key={item.channel_name} style={{ marginBottom: '10px' }}>
            {item.messages.map((message, index) => (
              <Card
                title={`Message from ${item.channel_name}`}
                bordered
                style={{ width: '90%', marginBottom: '20px' }}
                key={`message_${index}`}
              >
                <p style={{ backgroundColor: '#f8f8f8', padding: '10px' }}>
                  {' '}
                  <strong>Message:</strong>
                  {' '}
                  {message}
                </p>
                {item.source_data && item.source_data.length > 0 && (
                  <div style={{ marginTop: '10px' }}>
                    <strong>Source Data</strong>
                    {item.source_data.map((data, dataIndex) => (
                      <div
                        key={`data_${dataIndex}`}
                        style={{ padding: '10px', borderRadius: '5px', marginTop: '5px' }}
                      >
                        {' '}
                        <p>
                          <strong>Message:</strong>
                          {' '}
                          {data.message}
                        </p>
                        <p>
                          <strong>User:</strong>
                          {data.user_name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            ))}
          </div>
        );
      }
    })}
    {context.source !== 'Slack' &&
      context.context.map((item) => {
        if ('list_name' in item) {
          return (
            <Card key={item.permalink} style={{ marginBottom: '20px' }}>
              <div>
                <strong>Permalink:</strong>
                {' '}
                <a href={item.permalink} target="_blank" rel="noopener noreferrer">
                  {item.permalink}
                </a>
              </div>
              <div>
                <strong>Task Name:</strong>
                {' '}
                {item.task_name}
              </div>
              <div>
                <strong>Task Description:</strong>
                {' '}
                {item.task_description}
              </div>
              <div>
                <strong>Update:</strong>
                {' '}
                {item.task_update}
              </div>
              {item.source_data && item.source_data.length > 0 && (
              <p>
                <strong>Source Data:</strong>
                {' '}
                {item.source_data.map((message) => (
                  <div key={message.message}>{message.message}</div>
                ))}
              </p>
              )}
            </Card>
          );
        }
      })}
  </div>
);



export default AdminContextView;
