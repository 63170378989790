import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BriefingSelector from './AdminBriefingSelector';
import AdminBriefings from './AdminBriefings';
import { Briefing } from '../../redux/modules/briefings/schemas';
import { getUserFlag } from '../../redux/modules/auth/selectors';


const AdminBriefingViewer = () => {
  const [selectedBriefing, setSelectedBriefing] = useState<Briefing | null>(null);
  const history = useHistory();
  const userFlag = useSelector(getUserFlag);
  useEffect(() => {
    if (!userFlag || !userFlag.includes('admin')) {
      history.push('/');
    }
  }, []);

  return (
    <div>
      <BriefingSelector setSelectedBriefing={setSelectedBriefing} />
      <AdminBriefings selectedBriefing={selectedBriefing} />
    </div>
  );
};


export default AdminBriefingViewer;
