/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Typography, Button } from 'antd';
import PasswordFields from '../PasswordFields';
import { getUserEmail, getUserFirstName, getUserLastName } from '../../../redux/modules/auth/selectors';
import { register } from '../../../redux/modules/registration/actions';

import './UpdatePassword.scss';

const { Paragraph } = Typography;

// This component is ONLY used for JIT migration case where password does not meet Cognito requirements
// Potentially can be used for logged in password change
const UpdatePassword: React.FC = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [passwordError, setPasswordError] = useState<string>('');
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const email = useSelector(getUserEmail);
  const [isPasswordValid, setPasswordIsValid] = useState<boolean>(false);

  const handlePasswordSubmit = async (values: { newPassword: string; confirmPassword: string }) => {
    const { newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    if (email && newPassword) dispatch(register(email, newPassword, firstName, lastName, true));
  };

  return (
    <Form form={form} onFinish={handlePasswordSubmit}>
      <div className="password-reset-header">
        <h1>Please Update Your Password</h1>
      </div>
      <Paragraph className="update-password-page-title">
        We’ve updated our password policy and your current password
        {' '}
        <br />
        does not meet our security standards.
      </Paragraph>
      <PasswordFields setPasswordIsValid={setPasswordIsValid} />
      {passwordError && (
      <Paragraph type="danger">
        {passwordError}
      </Paragraph>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit" className="update-password-button" disabled={!isPasswordValid}>
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdatePassword;
