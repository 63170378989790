import {
  BriefingItemTag, BriefingItemTags, BriefingTopic, BriefingTopics, Briefings,
} from './schemas';

export const GET_BRIEFINGS_LOADING = 'briefings/GET_BRIEFINGS_LOADING';
export const GET_BRIEFINGS_SUCCESS = 'briefings/GET_BRIEFINGS_SUCCESS';
export const GET_BRIEFINGS_FAILURE = 'briefings/GET_BRIEFINGS_FAILURE';
export const GET_USER_SETTINGS = 'briefings/GET_USER_SETTINGS';
export const GET_USER_SETTINGS_SUCCESS = 'briefings/GET_USER_SETTINGS_SUCCESS';
export const GET_USER_SETTINGS_FAILURE = 'briefings/GET_USER_SETTINGS_FAILURE';
export const UPDATE_PRIORITY_TAGS = 'briefings/UPDATE_PRIORITY_TAGS';
export const UPDATE_PRIORITY_TAGS_SUCCESS = 'briefings/UPDATE_PRIORITY_TAGS_SUCCESS';
export const UPDATE_PRIORITY_TAGS_FAILURE = 'briefings/UPDATE_PRIORITY_TAGS_FAILURE';
export const UPDATE_USER_TOPICS = 'briefings/UPDATE_USER_TOPICS';
export const UPDATE_USER_TOPICS_SUCCESS = 'briefings/UPDATE_USER_TOPICS_SUCCESS';
export const UPDATE_USER_TOPICS_FAILURE = 'briefings/UPDATE_USER_TOPICS_FAILURE';
export const MARK_AS_READ = 'briefings/MARK_AS_READ';
export const MARK_AS_READ_SUCCESS = 'briefings/MARK_AS_READ_SUCCESS';
export const MARK_AS_READ_FAILURE = 'briefings/MARK_AS_READ_FAILURE';
export const CREATE_BRIEFING_SCHEDULE = 'briefing/CREATE_BRIEFING_SCHEDULE';
export const CREATE_BRIEFING_SCHEDULE_SUCCESS = 'briefing/CREATE_BRIEFING_SCHEDULE_SUCCESS';
export const CREATE_BRIEFING_SCHEDULE_FAILURE = 'briefing/CREATE_BRIEFING_SCHEDULE_FAILURE';

export interface UpdateUserTopicsAction {
    type: typeof UPDATE_USER_TOPICS
    payload: BriefingTopic
}

export interface UpdatePriorityTagsAction {
    type: typeof UPDATE_PRIORITY_TAGS
    payload: {
        priorityTag: BriefingItemTag
    }
}

export interface GetUserSettingsSuccessAction {
    type: typeof GET_USER_SETTINGS_SUCCESS
    payload: {
        priorityTags: BriefingItemTags,
        topics: BriefingTopics,
        userTopics: BriefingTopics,
    }
}


export interface MarkAsReadAction {
    type: typeof MARK_AS_READ;
    payload: string;
}
export interface GetBriefingsSuccessAction {
    type: typeof GET_BRIEFINGS_SUCCESS;
    payload: {
        briefings: Briefings
        loading: boolean
    }
}

export interface GetBriefingsLoadingAction {
    type: typeof GET_BRIEFINGS_LOADING;
    payload: {
        loading: boolean
    }
}

export interface GetBriefingsFailureAction {
    type: typeof GET_BRIEFINGS_FAILURE;
    payload: {
        loading: boolean
    }
}

export type BriefingsAction = GetBriefingsSuccessAction | MarkAsReadAction | GetUserSettingsSuccessAction | UpdatePriorityTagsAction | UpdateUserTopicsAction | GetBriefingsFailureAction | GetBriefingsLoadingAction
export interface BriefingsReducer {
    briefings: Briefings;
    priorityTags: BriefingItemTags;
    topics: BriefingTopics;
    userTopics: BriefingTopics;
    loading: boolean;
}
