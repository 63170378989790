import * as types from './types';

const defaultState: types.AutomationsReducer = {
  automations: [],
};

export default function automations(state = defaultState, action: types.AutomationsAction): types.AutomationsReducer {
  switch (action.type) {
    case types.GET_AUTOMATION_SUCCESS:
      return {
        automations: action.payload,
      };
    case types.AUTOMATIONS_LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
