import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-duotone-svg-icons';

import slackLogo from '../icons/connection-slack.svg';
import jiraLogo from '../icons/connection-jira.svg';
import notionLogo from '../icons/connection-notion.svg';
import githubLogo from '../icons/connection-github.svg';
import heightLogo from '../icons/connection-height.png';
import zoomLogo from '../icons/connection-zoom.svg';
import googleLogo from '../icons/connection-google.svg';
import linearLogo from '../icons/connection_linear.svg';
import gDriveLogo from '../icons/connection_gdrive.svg';
import gMeetLogo from '../icons/connection_gmeet.svg';
import linkedInLogo from '../icons/connection-linkedin.svg';
import figmaLogo from '../icons/connection-figma.svg';
import atlassianLogo from '../icons/connection-atlassian.svg';
import amplitudeLogo from '../icons/connection-amplitude.svg';


export const sourceLogos = {
  Slack: <img src={slackLogo} alt="Icon" />,
  GitHub: <img src={githubLogo} alt="Icon" />,
  Notion: <img src={notionLogo} alt="Icon" />,
  Jira: <img src={jiraLogo} alt="Icon" />,
  Height: <img src={heightLogo} alt="Icon" />,
  Zoom: <img src={zoomLogo} alt="Zoom" />,
  Google: <img src={googleLogo} alt="Zoom" />,
  Linear: <img src={linearLogo} alt="Drive" />,
  'Google Drive': <img src={gDriveLogo} alt="Google Drive" />,
  Other: <FontAwesomeIcon icon={faLink} />,
  Meet: <img src={gMeetLogo} alt="Drive" />,
  LinkedIn: <img src={linkedInLogo} alt="LinkedIn" />,
  Figma: <img src={figmaLogo} alt="Figma" />,
  Atlassian: <img src={atlassianLogo} alt="Atlassian" />,
  Amplitude: <img src={amplitudeLogo} alt="Amplitude" />,
};


export const mapSourceToLogo = (source: string) => {
  switch (source) {
    case 'Slack':
      return slackLogo;
    case 'Height':
      return heightLogo;
    case 'Jira':
      return jiraLogo;
    case 'GitHub':
      return githubLogo;
    case 'Zoom':
      return zoomLogo;
    case 'Meet':
      return gMeetLogo;
    case 'Linear':
      return linearLogo;
    case 'Google Drive':
      return gDriveLogo;
    case 'Notion':
      return notionLogo;
    case 'Atlassian':
      return atlassianLogo;
    case 'Amplitude':
      return amplitudeLogo;
    default:
      return '';
  }
};
