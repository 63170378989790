import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Input, Button, List,
} from 'antd';
import { getToken } from '../../redux/modules/auth/selectors';

import './Chat.scss';
import QAMessage from './QAMessage';

interface ChatMessage {
  userChat: string;
  response?: string;
  type?: string;
}

const Chat: React.FC = () => {
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const newestMessageRef = useRef<HTMLDivElement>(null);

  const token = useSelector(getToken);

  const initializeWebSocket = useCallback((onInitializeSend?: () => void) => {
    if (!token) {
      console.log('User is not authenticated for WebSocket connection');
      return;
    }

    const { hostname } = window.location;
    const protocol = hostname.includes('localhost') ? 'ws' : 'wss';

    const ws = new WebSocket(`${protocol}://${window.location.hostname}/api/v1.0/ws/chat`);

    ws.onopen = () => {
      console.log('WebSocket Connected');
      ws.send(JSON.stringify({ type: 'auth', token }));
      if (onInitializeSend) {
        onInitializeSend();
      }
    };

    ws.onmessage = (event) => {
      const serverResponse: { content: string; type?: string } = JSON.parse(event.data);
      if (serverResponse.type === 'auth_error') {
        console.log('Authentication error:', serverResponse);
        ws.close();
      } else {
        setMessages((prevMessages) => {
          setIsLoadingResponse(false);
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage && !lastMessage.response) {
            return [...prevMessages.slice(0, -1), { ...lastMessage, response: serverResponse.content }];
          }
          return [...prevMessages, { userChat: serverResponse.content }];
        });
      }
    };

    ws.onclose = () => {
      console.log('WebSocket Disconnected');
      setWebSocket(null);
    };

    setWebSocket(ws);
  }, [token]);

  useEffect(() => {
    initializeWebSocket();
    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
  }, [token, initializeWebSocket]);

  const sendMessage = () => {
    const sendInputValue = () => {
      if (inputValue.trim() !== '') {
        setIsLoadingResponse(true);
        webSocket?.send(inputValue);
        setMessages([...messages, { userChat: inputValue }]);
        setInputValue('');
      }
    };

    if (!webSocket || webSocket.readyState === WebSocket.CLOSED) {
      initializeWebSocket(sendInputValue);
    } else {
      sendInputValue();
    }
  };

  useEffect(() => {
    if (newestMessageRef.current) {
      newestMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <List
        className="chat-messages"
        dataSource={messages}
        renderItem={(message, index) => (
          <List.Item>
            <QAMessage message={message} ref={index === messages.length - 1 ? newestMessageRef : null} isLoadingResponse={index === messages.length - 1 && isLoadingResponse} />
          </List.Item>
        )}
        locale={{ emptyText: 'Ask Maestro a question' }}
      />
      <div className="chat-input">
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={sendMessage}
          placeholder="Type a message..."
          disabled={!webSocket}
        />
        <Button type="primary" onClick={sendMessage}>Send</Button>
      </div>
    </div>
  );
};

export default Chat;
