/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Editor from 'rich-markdown-editor';

import { Tag, Button } from 'antd'; // Assuming you're using Ant Design as in your initial example
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/pro-duotone-svg-icons';
import FeedbackComponent from '../FeedbackComponent/FeedbackComponent';
import { getPriorityTags } from '../../redux/modules/briefings/selectors';
import mp from '../../mixpanel';

import './BriefingDetails.scss';


import { BriefingItemLink, BriefingItemTag } from '../../redux/modules/briefings/schemas';
import { sourceLogos } from '../../utils/logoSources';
import { getUserId } from '../../redux/modules/auth/selectors';

type BriefingDetailsProps = {
  title: string;
  body: string;
  briefingId: string;
  briefingItemId: string;
  tags: Array<{ tag_name: string }>; // Assuming each tag has an ID and name
  links: BriefingItemLink[];
  toggleSidebar: () => void;
};

type ChatMessage = {
  author: 'Emily' | 'Maestro';
  content: string;
  avatar: string;
};


const BriefingDetails: React.FC<BriefingDetailsProps> = ({
  title, body, briefingId, briefingItemId, tags, links, toggleSidebar,
}) => {
  const priorityTags = useSelector(getPriorityTags);
  const userId = useSelector(getUserId);
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const sidebarContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    mp.identify(userId);
    mp.track('Opened Briefing Detail', { title });
  }, []);

  const handleSidebarClose = () => {
    setChatMessages([]);
    toggleSidebar();
  };

  const isPriorityTag = (name: string): boolean => priorityTags.some((tag: BriefingItemTag) => tag.tag_name === name);

  return (
    <div className="sidebar-container">
      <div className="sidebar-controls-container">
        <Button onClick={handleSidebarClose} className="control-button" type="text" ghost><FontAwesomeIcon icon={faAnglesRight} /></Button>
        <FeedbackComponent identifier={{ briefingId, briefingItemId }} reaction={null} />
      </div>
      <div className="sidebar-content" ref={sidebarContentRef}>
        <h2>{title}</h2>
        <Editor
          value={body}
          readOnly
          className="editor"
        />

        <div className="item-tag-list">
          {tags.slice().sort((a, b) => (isPriorityTag(b.tag_name) ? 1 : 0) - (isPriorityTag(a.tag_name) ? 1 : 0)).map((tag) => (
            <Tag key={tag.tag_name} className="tag">
              {isPriorityTag(tag.tag_name) && <span className="important-tag" />}
              {tag.tag_name}
            </Tag>
          ))}
        </div>
        {links.length > 0 && (<h3>Links</h3>)}
        <div className="sidebar-sources">
          {links.reduce((accumulator, link) => {
            if (!accumulator.set.has(link.source)) {
              accumulator.set.add(link.source);
              accumulator.uniqueLinks.push(link);
            }
            return accumulator;
          }, { set: new Set(), uniqueLinks: [] as BriefingItemLink[] }).uniqueLinks.map((link) => (
            <a href={link.href} className="item-link" target="_blank" rel="noreferrer">
              {sourceLogos[link.source]}
              <span className="channel-name">
                {link.source === 'Slack' ? '#' : ''}
                {link.channelName}
                -
              </span>
              <span className="link-text">{link.text}</span>
            </a>
          ))}
        </div>

        {chatMessages.length > 0 && (<h3>Chat</h3>)}
        <div className="sidebar-section">
          <div className="messages-list">
            {chatMessages.map((message, index) => (
              <div key={index} className="message-container">
                <div className="message-icon">
                  <img src={message.avatar} alt="icon" />
                </div>
                <div className="message-content">
                  <div className="message-header">
                    {message.author}
                  </div>
                  <div className="message">
                    <p>{message.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BriefingDetails;
