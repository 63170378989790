import * as types from './types';

const defaultState: types.AdminReducerState = {
  teams: [],
  logs: [],
};

export default function adminReducer(state = defaultState, action: types.AdminAction): types.AdminReducerState {
  switch (action.type) {
    case types.ADMIN_GET_TEAMS:
      return {
        ...state,
        teams: action.payload.teams,
      };
    case types.ADMIN_MSG_LOG:
      return {
        ...state,
        logs: [action.payload.log, ...state.logs],
      };
    default:
      return state;
  }
}
