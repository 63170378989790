import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button, Form, Input,
} from 'antd';

import { actions } from '../../redux/modules/auth';

import logo from '../../icons/maestro-logo.png';

import './LoginPage.scss';
import { getCognitoUser } from '../../redux/modules/auth/selectors';


const MFAForm: React.FC = () => {
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const cognitoUser = useSelector(getCognitoUser);


  const onSubmit = () => {
    if (cognitoUser) {
      dispatch(actions.validateAuthenticatorCode(code, cognitoUser));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleGoBackToLogin = () => {
    dispatch(actions.logout());
  };

  return (
    <div className="preauth-page-container">
      <div className="preuath-form-container">
        <div className="registration-form-content-wrapper">
          <img src={logo} alt="maestro ai logo" className="maestro-logo" />
          <Form
            name="authenticator code form"
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
            className="registration-form"
          >
            <Form.Item
              name="code"
              label="Authenticator Code"
              rules={[
                { required: true, message: 'Authenticator code is required', validateTrigger: 'onSubmit' },
              ]}
            >
              <Input
                className="registration-form-input"
                placeholder="Enter your authenticator code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              <Button type="primary" htmlType="submit">
                Validate Code
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="login-options">
          <div className="registration-login">
            Go back to
            {' '}
            <Link onClick={() => handleGoBackToLogin()} to="/login">
              <b>Log In</b>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MFAForm;
